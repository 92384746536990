// Define a function to check if the user is authenticated
const isAuthenticated = () => {
  // Check if the user authentication information is stored in local storage
  const signInResponseString = localStorage.getItem("signInResponse");

  // Parse the stored response string back to an object
  const signInResponse = JSON.parse(signInResponseString);

  // Check if the parsed response contains the necessary information
  if (signInResponse && signInResponse.access_token.length > 0) {
    return true;
  } else return false;
};

const getSignInResponse = () => {
  if (isAuthenticated()) {
    const signInResponseString = localStorage.getItem("signInResponse");
    return JSON.parse(signInResponseString);
  }
  return null;
};

export { isAuthenticated, getSignInResponse };
