import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../slices/signUp";
import $ from "jquery";
import Swal from 'sweetalert2';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loading from "../include/Loading";

function SignUp() {
  const dispatch = useDispatch();

  const signUpInfo = useSelector((state) => state.signUp.signUpInfo);
  // console.log("🚀 ~ file: SignUp.js:12 ~ SignUp ~ signUpInfo:", signUpInfo);

  const userNameSignin = useSelector((state) => state.signIn.signUpUserName);

  useEffect(() => {
    if (signUpInfo && signUpInfo.length > 0) {
      $("#CreateAccount").removeClass("open-it");
      $("#OtpVerify").addClass("open-it");
      $("body").addClass("hidden-scroll");
      setNewUser({
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        mobileNo: "",
        password: "",
        confirmPassword: "",
        referralCode: "",
        image: "",
        terms: false,
        image_url: "../../img/dp.jpg",
        mobilePrefixCode: "",
      });
    }
  }, [signUpInfo]);

  

  // const [isSignUpSuccessful, setIsSignUpSuccessful] = useState(false);
  // const [userError, setUserError] = useState(false);

  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    mobileNo: "",
    password: "",
    confirmPassword: "",
    referralCode: "",
    image: "",
    terms: false,
    image_url: "../../img/dp.jpg",
    mobilePrefixCode: "",
  });


  useEffect(() => {
    if(userNameSignin){
      if(isNumeric(userNameSignin)){
        // setNewUser({mobilePrefixCode : "+267"+userNameSignin}) 
        setNewUser({mobilePrefixCode : "267", mobileNo : userNameSignin}); 
      } else {
        setNewUser({email : userNameSignin}) 
      }
    }
  },[userNameSignin])

  const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }


  // console.log("🚀 ~ file: SignUp.js:13 ~ SignUp ~ newUser:", newUser);

  const handleRegister = async (event) => {
    if (!isFormFilled()) {
      Swal.fire({  
        title: 'Alert',  
        text: 'Please fill in all required fields.',
        icon: 'error'
      }); 
      // alert("Please fill in all required fields.");
      return;
    }
    // event.preventDefault();
    // if (!isEmailConfirmed() || !isPasswordConfirmed()) {
    //   Swal.fire({  
    //     title: 'Alert',  
    //     text: 'Email or Password confirmation mismatch.',
    //     icon: 'error'
    //   }); 
    //   // alert("Email or Password confirmation mismatch.");
    //   return;
    // }

    // Handle registration logic here
    // console.log("User registered:", newUser);
    await dispatch(signUp(newUser));

    // if (signUpInfo) {
    //   console.log(
    //     "🚀 ~ file: SignUp.js:51 ~ handleRegister ~ signUpInfo:",
    //     signUpInfo
    //   );
    //   setStep("verify-otp");
    // }
    // setIsSignUpSuccessful(true);
    
  };

  const isEmailConfirmed = () => {
    return newUser.email === newUser.confirmEmail;
  };

  const isPasswordConfirmed = () => {
    return newUser.password === newUser.confirmPassword;
  };

  const uploadImage = (e) => {
    e.preventDefault();
    let image_url = URL.createObjectURL(e.target.files[0]);
    // this.setState({ image: e.target.files[0], image_url: image_url });
    setNewUser((prevState) => ({
      ...prevState,
      image: e.target.files[0],
      image_url: image_url,
    }));
  };

  const infoMessage = () => {
    Swal.fire({  
      title: 'Info',  
      text: "If you've got a referral code from a friend or one of our awesome SELLanything ambassadors, pop it in here when you register. If not, don't worry, you can just skip it and continue registration. Let's get this SELL party started!",
     // text: 'If you\'ve got a referral code from a friend or one of our awesome SWOP mbassadors, pop it in here when you register. If not, don\'t worry, you can just skip it and continue registration. Let\'s get this SWOP party started!',
      icon: 'warning',
      // type: 'warning',
    }); 
  }

  const isFormFilled = () => {
    // Check if all required fields are filled
    return (
      newUser.firstName &&
      // newUser.lastName &&
      newUser.email &&
      // newUser.confirmEmail &&
      newUser.mobileNo
      // newUser.password &&
      // newUser.confirmPassword
      // Add more required fields as needed
    );
  };

  // useEffect(() => {
  //   if (signUpInfo && signUpInfo.length > 0) {
  //     // Reset the form after handling the logic
  //     setNewUser({
  //       firstName: "",
  //       lastName: "",
  //       email: "",
  //       confirmEmail: "",
  //       mobileNo: "",
  //       password: "",
  //       confirmPassword: "",
  //       referralCode: "",
  //       image: "",
  //       terms: false,
  //       image_url: "../../img/dp.jpg",
  //       mobilePrefixCode: "",
  //     });
  //   }
  // }, [signUpInfo]);
  const isLoading = useSelector((state) => state.signUp.isLoading);

  return (
    <div
      className="comman-model comman-side-popup lg-side-popup create-account-model"
      id="CreateAccount"
    >
      <Loading loading={isLoading} />

      <div className="comman-popup-inner">
        <div className="top-model-header">
          <div className="pop-top-title">
            <h2>Create Account  </h2>
          </div> 
          <a href="javascript:void(0);" className="close_panel">
            <i>
              <img src="../../img/close.png" alt="" />
            </i>
          </a>
        </div>
        
        <div className="popup-model-body">
          <form>
            {/* <div className="dp-img">
              <img src={newUser.image_url} alt="original" />
              <div className="img-icon">
                <input type="file" accept="image/*" onChange={uploadImage} />
                <img src="../img/camera.png" alt="pen" />
              </div>
            </div> */}

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Full Name *</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Enter Full Name"
                    value={newUser.firstName}
                    onChange={(e) =>
                      setNewUser((prevState) => ({
                        ...prevState,
                        firstName: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    value={newUser.lastName}
                    onChange={(e) =>
                      setNewUser((prevState) => ({
                        ...prevState,
                        lastName: e.target.value,
                      }))
                    }
                  />
                </div>
              </div> */}

<div className="col-lg-6">
                <div className="form-group">
                  <label>Mobile no. *</label>

                  {/* <PhoneInput
                    country={"bw"}
                    value={newUser.mobilePrefixCode + newUser.mobileNo}
                    onChange={(value, data) => {
                      const dialCodeLength = data.dialCode.length;
                      const enteredNumber = value.slice(dialCodeLength);

                      setNewUser((prevState) => ({
                        ...prevState,
                        mobileNo: enteredNumber, // Update mobile number only after slicing dial code
                        mobilePrefixCode: data.dialCode, // Always update the prefix code based on the selected country
                      }));
                    }}
                    placeholder="+267"
                    inputClass={"form-control"}
                    inputStyle={{ padding: "0px 50px" }}
                  /> */}

                  <PhoneInput
                  country={"bw"}
                  value={
                    String(newUser.mobilePrefixCode || "+267") + String(newUser.mobileNo || "")
                  }
                  onChange={(value, data) => {
                    const dialCodeLength = data?.dialCode?.length || 0;
                    const enteredNumber = typeof value === "string" ? value.slice(dialCodeLength) : "";

                    setNewUser((prevState) => ({
                      ...prevState,
                      mobileNo: enteredNumber,
                      mobilePrefixCode: data?.dialCode || "+267",
                    }));
                  }}
                  placeholder="+267"
                  inputClass={"form-control"}
                  inputStyle={{ padding: "0px 50px" }}
                  />


                  {/* <PhoneInput
                    country={"bw"}
                    value={newUser.mobilePrefixCode}
                    onChange={(value, data) => {
                      setNewUser(
                        //   {
                        //     mobile:(value.slice(data.dialCode.length)),
                        //     mobile_prefix_code:data.dialCode
                        // }
                        (prevState) => ({
                          ...prevState,
                          mobileNo: value.slice(data.dialCode.length),
                          mobilePrefixCode: data.dialCode,
                        })
                      );
                    }}
                    placeholder="+267"
                    inputClass={"form-control"}
                    inputStyle={{ padding: "0px 50px" }}
                  /> */}
                  {/* <div className="mobile-input d-flex">
                    <div className="flag-box">
                      {" "}
                      <img
                        className="flagpt"
                        src="../../img/flag.png"
                        alt=""
                      />{" "}
                      <i className="icon-01 arrownext"></i>{" "}
                    </div>
                    <input
                      required
                      type="number"
                      className="form-control"
                      placeholder="Enter Your Mobile Number"
                      value={newUser.mobileNo}
                      onChange={(e) =>
                        setNewUser((prevState) => ({
                          ...prevState,
                          mobileNo: e.target.value,
                        }))
                      }
                    />
                  </div> */}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Email Address *</label>
                  <input
                    required
                    type="email"
                    className="form-control"
                    placeholder="Enter Your Email"
                    value={newUser.email}
                    onChange={(e) =>
                      setNewUser((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="form-group">
                  <label>Confirm Email Address *</label>
                  <input
                    required
                    type="email"
                    className="form-control"
                    placeholder="Enter Your Email"
                    value={newUser.confirmEmail}
                    onChange={(e) =>
                      setNewUser((prevState) => ({
                        ...prevState,
                        confirmEmail: e.target.value,
                      }))
                    }
                  />
                </div>
              </div> */}
              
              {/* <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Password *<span>(Show)</span>{" "}
                  </label>
                  <input
                    required
                    type="password"
                    className="form-control"
                    placeholder="Enter Password"
                    value={newUser.password}
                    onChange={(e) =>
                      setNewUser((prevState) => ({
                        ...prevState,
                        password: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Confirm Password *<span>(Show)</span>{" "}
                  </label>
                  <input
                    required
                    type="password"
                    className="form-control"
                    placeholder="Enter Confirm Password"
                    value={newUser.confirmPassword}
                    onChange={(e) =>
                      setNewUser((prevState) => ({
                        ...prevState,
                        confirmPassword: e.target.value,
                      }))
                    }
                  />
                </div>
              </div> */}
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Add Referral Code Info?</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Referral Code"
                    value={newUser.referralCode}
                    onChange={(e) =>
                      setNewUser((prevState) => ({
                        ...prevState,
                        referralCode: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div className="switch-btns-row d-flex align-items-center justify-content-between">


            <div className="accountbaradd">
              <p>
                More info on referral code {" "}
                <a href="#" onClick={() => infoMessage()}>
                  <i className="incoico">
                              <img src="../img/info.svg" alt="" height="18px"/>
                            </i>
                </a>
              </p>
            </div>

              {/* <div className="custom-control new-custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="switch1"
                  value={newUser.terms}
                  onChange={(e) =>
                    setNewUser((prevState) => ({
                      ...prevState,
                      terms: e.target.checked,
                    }))
                  }
                />
                <label className="custom-control-label" for="switch1"></label>
                <span>By signing You Accept out</span>{" "}
                <a>Terms &amp; Condition &amp; privocy</a>
              </div> */}

              {/* Forgot ? */}
              {/* <a
                // href="#"
                className="open-popup"
                data-popup-id="OtpVerify"
                id="forgotpasswordIdbtn"
              > */}
              <button
                className="btn btn-primary ml-auto"
                // type="submit"
                type="button"
                onClick={() => handleRegister()}
              >
                Register
              </button>
              {/* </a> */}

              {/* {isSignUpSuccessful ? (
                <a
                  className="forgot_pass open-popup"
                  data-popup-id="OtpVerify"
                  id="forgotpasswordIdbtn"
                >
                  <button className="btn btn-primary ml-auto" type="button">
                    Verify OTP
                  </button>
                </a>
              ) : (
                <button
                  className="btn btn-primary ml-auto"
                  type="submit"
                  // onClick={() => handleRegister()}
                >
                  Register
                </button>
              )} */}
              
            </div>
           
            <div className="alreadybar accountbaradd">
              {/* <p>
                Already have Account ?{" "}
                <a
                  href="javascript:void(0);"
                  className="open-popup"
                  data-popup-id="LogIn"
                >
                  Login
                </a>
              </p>
              <p className="orbar">
               <span>Or{" "}</span>
                
              </p> */}
              <p>
              Are you a business ?{" "}
                <a
                  href="/admin/business/login"
                  target="_blank"
                >
                  Click Here
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="shadowpt clearfix"></div>
    </div>
  );
}

export default SignUp;
