import React, { useState, useEffect } from "react";

const Terms = () => {

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	});

  return (
    <>
      <main className="sa-main-wrap sa-trems-pages-wrap">
            <div className="container">
            <div className="breadcrumpart">
               <ul>
                  <li>
                     <a href="#">Home</a>
                  </li>
                  <li>
				  Terms and Conditions
                  </li>
               </ul>
            </div>
         </div>
         <div className="container">
           <section className="sa-trems-content">
            <p><strong><span dir="LTR"><strong>Terms and Conditions</strong></span></strong></p>

<p><span dir="LTR">These terms and conditions are the contract between you and www.Swopanything.com (&ldquo;Swopanything&rdquo;) provided to you by Oneminute Technologies Pty Ltd ] (&ldquo;us&rdquo;, &ldquo;we&rdquo;, etc). By visiting or using Our Website and Mobile application, you agree to be bound by them.</span></p>

<p><span dir="LTR">I/ We are Oneminute Technologies Pty Ltd, a company registered in South Africa, number 2019 / 294454 / 07</span></p>

<p><span dir="LTR">Our address is </span></p>

<p><span dir="LTR">THE PIAZZA ON CHURCH SQUARE</span></p>

<p><span dir="LTR">UNIT 102 32 PARLIAMENT STREET</span></p>

<p><span dir="LTR">CAPE TOWN</span></p>

<p><span dir="LTR">WESTERN CAPE 8001.</span></p>

<p><span dir="LTR">You are (User) : Anyone who uses Our Website and &nbsp;Mobile Application App.</span></p>

<p><span dir="LTR">Please read this agreement carefully and save it. If you do not agree with it, you should leave Our Website and &nbsp;Mobile Application immediately.</span></p>

<p><strong><span dir="LTR"><strong>These are the agreed terms</strong></span></strong></p>

<h1><a name="_eadgtmwr2axt"></a><strong><span dir="LTR"><strong>1.</strong></span></strong>&nbsp;<strong><span dir="LTR"><strong>Definitions</strong></span></strong></h1>

<table cellspacing="0" style={{width:'419.2500pt'}} border={'1px solid'}>
	<tbody>
		<tr>
			<td style={{height:'89.5000pt', verticalAlign:'top', width:'142.5000pt'}}>
			<p><span dir="LTR">&ldquo;Content&rdquo;</span></p>
			</td>
			<td style={{height:'89.5000pt', verticalAlign:'top', width:'276.7500pt'}}>
			<p><span dir="LTR">means the textual, visual or aural content that is encountered as part of your experience on Our Website and &nbsp;Mobile Application . It may include, among other things: text, images, sounds, videos and animations. It includes content Posted by you.</span></p>
			</td>
		</tr>
		<tr>
			<td style={{height:'143.5000pt', verticalAlign:'top', width:'142.5000pt'}}>
			<p><span dir="LTR">&quot;Intellectual Property&quot;</span></p>
			</td>
			<td style={{height:'143.5000pt', verticalAlign:'top', width:'276.7500pt'}}>
			<p><span dir="LTR">means intellectual property owned by us, of every sort, whether or not registered or registrable in any country, including intellectual property of all kinds coming into existence after today; and including, among others, patents, trademarks, unregistered marks, designs, copyrights, software, domain names, discoveries, creations and inventions, together with all rights which are derived from those rights.</span></p>
			</td>
		</tr>
		<tr>
			<td style={{height:'103.0000pt', verticalAlign:'top', width:'142.5000pt'}}>
			<p><span dir="LTR">&ldquo;Our Website&rdquo;</span></p>
			</td>
			<td style={{height:'103.0000pt', verticalAlign:'top', width:'276.7500pt'}}>
			<p><span dir="LTR">means any website and Mobile Application designed for electronic access by mobile or fixed devices which is owned or operated by us or any member of the Oneminute Technologies Pty Ltd</span><span dir="LTR">.</span><span dir="LTR">It includes all web and pages controlled by us.</span></p>
			</td>
		</tr>
		<tr>
			<td style={{height:'49.0000pt', verticalAlign:'top', width:'142.5000pt'}}>
			<p><span dir="LTR">&quot;Post&quot;</span></p>
			</td>
			<td style={{height:'49.0000pt', verticalAlign:'top', width:'276.7500pt'}}>
			<p><span dir="LTR">means place on or into Our Website and Mobile Application, any Content or material of any sort by any means.</span></p>
			</td>
		</tr>
		<tr>
			<td style={{height:'49.0000pt', verticalAlign:'top', width:'142.5000pt'}}>
			<p><span dir="LTR">&ldquo;Services&rdquo;</span></p>
			</td>
			<td style={{height:'49.0000pt', verticalAlign:'top', width:'276.7500pt'}}>
			<p><span dir="LTR">means all of the services available from Our Website and Mobile Application.</span></p>
			</td>
		</tr>
	</tbody>
</table>

<h1><a name="_vipxg4b47eb4"></a><strong><span dir="LTR"><strong>2.</strong></span></strong>&nbsp;<strong><span dir="LTR"><strong>Interpretation</strong></span></strong></h1>

<p><span dir="LTR">In this agreement unless the context otherwise requires:</span></p>

<p><span dir="LTR">2.1.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">a reference to a person includes a human individual, a corporate entity and any organisation which is managed or controlled as a unit.</span></p>

<p><span dir="LTR">2.2.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">in the context of permission, &ldquo;may not&rdquo; in connection with an action of yours, means &ldquo;must not&rdquo;.</span></p>

<p><span dir="LTR">2.3.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">any agreement by either party not to do or omit to do something includes an obligation not to allow some other person to do or omit to do that same thing.</span></p>

<p><span dir="LTR">2.4.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">this agreement is made only in the English language. If there is any conflict in meaning between the English language version of this agreement and any version or translation of this agreement in any other language, the English language version shall prevail.</span></p>

<h1><a name="_5nklly47b8qz"></a><strong><span dir="LTR"><strong>3.</strong></span></strong>&nbsp;<strong><span dir="LTR"><strong>Basis of Contract</strong></span></strong></h1>

<p><span dir="LTR">3.1.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">We do not offer the Services in all countries. We may refuse to provide the Services if you live in a country we do not serve.</span></p>

<p><span dir="LTR">3.2.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">In entering into this contract you have not relied on any representation or information from any source except the definition and explanation of the Services given on Our Website and &nbsp;Mobile Application .</span></p>

<p><span dir="LTR">3.3.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">Subject to these terms and conditions, we agree to provide to you some or all of the Services described on Our Website and &nbsp;Mobile Application .</span></p>

<p><span dir="LTR">3.4.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">So far as we allow use of our Intellectual Property, we grant a licence to you, limited to the terms set out in this agreement.</span></p>

<p><span dir="LTR">3.5.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">We may change this agreement and / or the way we provide the Services, at any time. If we do:</span></p>

<p><span dir="LTR">3.5.1</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">the change will take effect when we Post it on Our Website and &nbsp;Mobile Application .</span></p>

<p><span dir="LTR">3.5.2</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">you agree to be bound by any changes. If you do not agree to be bound by them, you should not use Our Website and &nbsp;Mobile Application &nbsp;or the Services.</span></p>

<h1><a name="_wz00mexht4rq"></a><strong><span dir="LTR"><strong>Your account and personal information</strong></span></strong></h1>

<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">When you visit Our Website and &nbsp;Mobile Application , you accept responsibility for any action done by any person using your name, account or password. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner.</span></p>

<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">You agree that you have provided accurate, up to date, and complete information about yourself. We are not responsible for any error made as a result of such information being inaccurate.</span></p>

<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">You agree to notify us of any changes in your information immediately it occurs. If you do not do so, we may terminate your account.</span></p>

<h1><a name="_lejdkrki4fq6"></a><span dir="LTR"><strong style={{fontWeight:'bold'}}>4.</strong>&nbsp;</span><strong><span dir="LTR"><strong>How we handle your Content</strong></span></strong></h1>

<p><span dir="LTR">4.1.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">Our privacy policy is strong and precise. It complies fully with the current privacy law which is at </span><a href="https://swopanything.com/ts-cs/"><span dir="LTR">https://swopanything.com/ts-cs/</span></a></p>

<p><span dir="LTR">4.2.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">If you Post Content to any public area of Our Website and &nbsp;Mobile Application &nbsp;it becomes available in the public domain. We have no control over who sees it or what anyone does with it.</span></p>

<p><span dir="LTR">4.3.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">Even if access to your data is behind a user registration, it remains effectively in the public domain because someone has only to register and log in, to access it. You should therefore avoid Posting unnecessary confidential information.</span></p>

<p><span dir="LTR">4.4.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">We need the freedom to be able to publicise our Services and your own use of them. You therefore now irrevocably grant to us the right and licence to edit, copy, publish, distribute, translate and otherwise use any Content that you place on Our Website and &nbsp;Mobile Application , in public domain and in any medium. You represent and warrant that you are authorised to grant all such rights.</span></p>

<p><span dir="LTR">4.5.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">We will use that licence only for commercial purposes of the business of Our Website and &nbsp;Mobile Application &nbsp;and will stop using it after a commercially reasonable period of time.</span></p>

<p><span dir="LTR">4.6.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">You agree to waive your right to claim authorship and your right to object to any distortion, mutilation or other modification of your work as provided in the Copyright Act 1978.</span></p>

<p><span dir="LTR">4.7.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">You now irrevocably authorise us to publish feedback, comments and ratings about your activity through Our Website and &nbsp;Mobile Application , even though it may be defamatory or critical</span></p>

<p><span dir="LTR">4.8.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">Posting Content does not change your ownership of the copyright in it. We have no claim over it and we will not protect your rights for you.</span></p>

<p><span dir="LTR">4.9.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">You understand that you are personally responsible for your breach of intellectual property rights, defamation, or any law, which may occur as a result of any Content having been Posted by you.</span></p>

<p><span dir="LTR">4.10.</span>&nbsp; <span dir="LTR">You accept all risk and responsibility for determining whether any Content is in the public domain and not confidential.</span></p>

<p><span dir="LTR">4.11.</span>&nbsp; <span dir="LTR">Please notify us of any security breach or unauthorised use of your account.</span></p>

<p><span dir="LTR">4.12.</span>&nbsp; <span dir="LTR">We do not solicit ideas or text for improvement of our Service, but if you do send to us material of any sort, you are deemed to have granted to us a licence to use it in the terms set out at in the sub paragraphs above]</span><span dir="LTR">.</span></p>

<h1><a name="_wyubrtt87ens"></a><span dir="LTR"><strong style={{fontWeight:'bold'}}>5.</strong>&nbsp;</span><strong><span dir="LTR"><strong>Restrictions on what you may Post to Our Website and &nbsp;Mobile Application </strong></span></strong></h1>

<p><span dir="LTR">We invite you to Post Content to Our Website and Mobile Application &nbsp;in several ways and for different purposes. We have to regulate your use of Our Website and Mobile Application to protect our business and our staff, to protect other users of Our Website and Mobile Application and to comply with the law. These provisions apply to all users of Our Website and Mobile Application .</span></p>

<p><span dir="LTR">We do not undertake to moderate or check every item Posted, but we do protect our business vigorously. If we believe Content Posted breaches the law, we shall co-operate fully with the law enforcement authorities in whatever ways we can.</span></p>

<p><span dir="LTR">You agree that you will not use or allow anyone else to use Our Website and Mobile Application to Post, upload Content or undertake any activity which is or may:</span></p>

<p><span dir="LTR">5.1.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">be unlawful, or tend to incite another person to commit a crime;</span></p>

<p><span dir="LTR">5.2.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">consist in commercial audio, video or music files;</span></p>

<p><span dir="LTR">5.3.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">be obscene, offensive, threatening, violent, malicious or defamatory;</span></p>

<p><span dir="LTR">5.4.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">be sexually explicit or pornographic;</span></p>

<p><span dir="LTR">5.5.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">be likely to deceive any person or be used to impersonate any person, or to misrepresent your identity, age or affiliation with any person;</span></p>

<p><span dir="LTR">5.6.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">give the impression that it emanates from us or that you are connected with us or that we have endorsed you or your business;</span></p>

<p><span dir="LTR">5.7.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">post Content on behalf of some other person, or impersonate another person;</span></p>

<p><span dir="LTR">5.8.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">request or collect passwords or other personal information from another user without his permission, nor Post any unnecessary personal information about yourself;</span></p>

<p><span dir="LTR">5.9.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">be used to sell any goods or services or for any other commercial use not intended by us, for yourself or for any other person. Examples are: sending private messages with a commercial purpose, or collecting information with the intention of passing it to a third party for his commercial use;</span></p>

<p><span dir="LTR">5.10.</span>&nbsp; <span dir="LTR">facilitate the provision of unauthorised copies of another person&#39;s copyright work;</span></p>

<p><span dir="LTR">5.11.</span>&nbsp; <span dir="LTR">link to any of the material specified in this paragraph;</span></p>

<p><span dir="LTR">5.12.</span>&nbsp; <span dir="LTR">send age-inappropriate communications or Content to anyone under the age of 18.</span></p>

<h1><a name="_bttl7b7blz36"></a><span dir="LTR"><strong style={{fontWeight:'bold'}}>6.</strong>&nbsp;</span><strong><span dir="LTR"><strong>Your Posting: restricted content</strong></span></strong></h1>

<p><span dir="LTR">In connection with the restrictions set out below, we may refuse or edit or remove a Posting which does not comply with these terms.</span></p>

<p><span dir="LTR">In addition to the restrictions set out above, a Posting must not contain:</span></p>

<p><span dir="LTR">6.1.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">hyperlinks, other than those specifically authorised by us;</span></p>

<p><span dir="LTR">6.2.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">keywords or words repeated, which are irrelevant to the Content Posted.</span></p>

<p><span dir="LTR">6.3.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">the name, logo or trademark of any organisation other than that of you or your client.</span></p>

<p><span dir="LTR">6.4.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">inaccurate, false, or misleading information.</span></p>

<h1><a name="_ssgfo313rkqg"></a><span dir="LTR"><strong style={{fontWeight:'bold'}}>7.</strong>&nbsp;</span><strong><span dir="LTR"><strong>Removal of offensive Content</strong></span></strong></h1>

<p><span dir="LTR">7.1.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">For the avoidance of doubt, this paragraph is addressed to any person who comes on Our Website and Mobile Application for any purpose.</span></p>

<p><span dir="LTR">7.2.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">We are under no obligation to monitor or record the activity of any user of Our Website and &nbsp;Mobile Application &nbsp;for any purpose, nor do we assume any responsibility to monitor or police Internet-related activities. However, we may do so without notice to you and without giving you a reason.</span></p>

<p><span dir="LTR">7.3.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">If you are offended by any Content, the following procedure applies:</span></p>

<p><span dir="LTR">7.3.1</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">your claim or complaint must be submitted to us in the form available on Our Website and &nbsp;Mobile Application , or contain the same information as that requested in our form. It must be sent to us by post or email.</span></p>

<p><span dir="LTR">7.3.2</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">we shall remove the offending Content as soon as we are reasonably able;</span></p>

<p><span dir="LTR">7.3.3</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">after we receive notice of a claim or complaint, we shall investigate so far as we alone decide;</span></p>

<p><span dir="LTR">7.3.4</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">we may re-instate the Content about which you have complained or not.</span></p>

<p><span dir="LTR">7.4.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">In respect of any complaint made by you or any person on your behalf, whether using our form of complaint or not, you now irrevocably grant to us a licence to publish the complaint and all ensuing correspondence and communication, without limit.</span></p>

<p><span dir="LTR">7.5.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">You now agree that if any complaint is made by you frivolously or vexatiously you will repay us the cost of our investigation including legal fees, if any.</span></p>

<h1><a name="_gor3nxzcvflr"></a><span dir="LTR"><strong style={{fontWeight:'bold'}}>8.</strong>&nbsp;</span><strong><span dir="LTR"><strong>Security of Our Website and Mobile Application</strong></span></strong>&nbsp;</h1>

<p><span dir="LTR">If you violate Our Website and Mobile Application , we shall take legal action against you.</span></p>

<p><span dir="LTR">You now agree that you will not, and will not allow any other person to:</span></p>

<p><span dir="LTR">8.1.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">modify, copy, or cause damage or unintended effect to any portion of Our Website and Mobile Application , or any software used within it.</span></p>

<p><span dir="LTR">8.2.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">link to Our Website and Mobile Application &nbsp;in any way that would cause the appearance or presentation of Our Website and Mobile Application &nbsp;to be different from what would be seen by a user who accessed Our Website and &nbsp;Mobile Application by typing the URL into a standard browser;</span></p>

<p><span dir="LTR">8.3.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">download any part of Our Website and Mobile Application , without our express written consent;</span></p>

<p><span dir="LTR">8.4.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">collect or use any information obtained from or about Our Website and Mobile Application or the Content except as intended by this agreement;</span></p>

<p><span dir="LTR">8.5.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">aggregate, copy or duplicate in any manner any of the Content or information available from Our Website and Mobile Application , other than as permitted by this agreement or as is reasonably necessary for your use of the Services;</span></p>

<p><span dir="LTR">8.6.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">share with a third party any login credentials to Our Website and Mobile Application &nbsp;;</span></p>

<p><span dir="LTR">8.7.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">Despite the above terms, we now grant a licence to you to:</span></p>

<p><span dir="LTR">8.7.1</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">create a hyperlink to Our Website and Mobile Application &nbsp;for the purpose of promoting an interest common to both of us. You can do this without specific permission. This licence is conditional upon your not portraying us or any product or service in a false, misleading, derogatory, or otherwise offensive manner. You may not use any logo or other proprietary graphic or trademark of ours as part of the link without our express written consent.</span></p>

<p><span dir="LTR">8.7.2</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">you may copy the text of any page for your personal use in connection with the purpose of Our Website and Mobile Application or a Service we provide.</span></p>

<h1><a name="_gzs2ddctb7xd"></a><span dir="LTR"><strong style={{fontWeight:'bold'}}>9.</strong>&nbsp;</span><strong><span dir="LTR"><strong>Storage of data</strong></span></strong></h1>

<p><span dir="LTR">9.1.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">We may, from time to time, set a limit on the number of messages you may send, store, or receive through the Services. We may delete messages in excess of that limit. We shall give you notice of any change to your limit, except in an emergency.</span></p>

<p><span dir="LTR">9.2.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">We assume no responsibility for the deletion or failure to store or deliver email or other messages.</span></p>

<p><span dir="LTR">9.3.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">You accept that we cannot be liable to you for any such deletion or failure to deliver to you.</span></p>

<p><span dir="LTR">9.4.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">We maintain reasonable procedures for general backup of data for our own purposes but we give no warranty that your data will be saved or backed up in any particular circumstances unless we have made specific contractual arrangements with you in writing.</span></p>

<h1><a name="_umb0ufrmme0m"></a><strong><span dir="LTR"><strong>Interruption to Services</strong></span></strong></h1>

<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">If it is necessary for us to interrupt the Services, we will give you reasonable notice where this is possible and when we think the down time is such as to justify telling you.</span></p>

<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">You acknowledge that the Services may also be interrupted for many reasons beyond our control.</span></p>

<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">You agree that we are not liable to you for any loss, foreseeable or not, arising from any interruption to the Services.</span></p>

<h1><a name="_30wfsjl8sha4"></a><span dir="LTR"><strong style={{fontWeight:'bold'}}>10.</strong>&nbsp;</span><strong><span dir="LTR"><strong>Termination</strong></span></strong></h1>

<p><span dir="LTR">10.1.</span>&nbsp; <span dir="LTR">We may terminate this agreement at any time, for any reason, with immediate effect by sending you notice to that effect by post or email.</span></p>

<p><span dir="LTR">10.2.</span>&nbsp; <span dir="LTR">Termination by either party shall have the following effects:</span></p>

<p><span dir="LTR">10.2.1</span>&nbsp; <span dir="LTR">your right to use the Services immediately ceases;</span></p>

<p><span dir="LTR">10.2.2</span>&nbsp; <span dir="LTR">we are under no obligation to forward any unread or unsent messages to you or any third party.</span></p>

<h1><a name="_95wpbni03kag"></a><span dir="LTR"><strong style={{fontWeight:'bold'}}>11.</strong>&nbsp;</span><strong><span dir="LTR"><strong>Intellectual Property</strong></span></strong></h1>

<p><span dir="LTR">You agree that at all times you will:</span></p>

<p><span dir="LTR">11.1.</span>&nbsp; <span dir="LTR">not do anything which does or might reduce the value of our Intellectual Property or challenge our ownership of it.</span></p>

<p><span dir="LTR">11.2.</span>&nbsp; <span dir="LTR">notify us of any suspected infringement of the Intellectual Property;</span></p>

<p><span dir="LTR">11.3.</span>&nbsp; <span dir="LTR">so far as concerns software provided or made accessible by us to you, you will not:</span></p>

<p><span dir="LTR">11.3.1</span>&nbsp; <span dir="LTR">copy, or make any change to any part of its code;</span></p>

<p><span dir="LTR">11.3.2</span>&nbsp; <span dir="LTR">use it in any way not anticipated by this agreement;</span></p>

<p><span dir="LTR">11.3.3</span>&nbsp; <span dir="LTR">give access to it to any other person than you, the licensee in this agreement;</span></p>

<p><span dir="LTR">11.3.4</span>&nbsp; <span dir="LTR">in any way provide any information about it to any other person or generally.</span></p>

<p><span dir="LTR">11.4.</span>&nbsp; <span dir="LTR">not use the Intellectual Property except directly as intended by this agreement or in our interest.</span></p>

<h1><a name="_qo32m6t96oxh"></a><span dir="LTR"><strong style={{fontWeight:'bold'}}>12.</strong>&nbsp;</span><strong><span dir="LTR"><strong>Disclaimers and limitation of liability</strong></span></strong></h1>

<p><span dir="LTR">12.1.</span>&nbsp; <span dir="LTR">The law differs from one country to another. This paragraph applies so far as the applicable law allows.</span></p>

<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">All implied conditions, warranties and terms are excluded from this agreement</span></p>

<p><span dir="LTR">12.2.</span>&nbsp; <span dir="LTR">Our Website and Mobile Application &nbsp;includes Content Posted by third parties. We are not responsible for any such Posting. If you come across any Content which offends against this document, please contact us via the &ldquo;Contact us&rdquo; page on Our Website].</span></p>

<p><span dir="LTR">12.3.</span>&nbsp; <span dir="LTR">You are advised that Content may include technical inaccuracies or typographical errors. This is inevitable in any large website and Mobile Application . We would be grateful if you bring to our immediate attention, any that you find.</span></p>

<p><span dir="LTR">12.4.</span>&nbsp; <span dir="LTR">Our Website and &nbsp;Mobile Application &nbsp;contains links to other Internet websites. We have neither power nor control over any such website. You acknowledge and agree that we shall not be liable in any way for the content of any such linked website, nor for any loss or damage arising from your use of any such website or from your buying services or goods via such a website.</span></p>

<p><span dir="LTR">12.5.</span>&nbsp; <span dir="LTR">The Swopanything.com Website and Mobile Application and Oneminute Technologies Pty Ltd Services are provided &ldquo;as is&rdquo;. We make no representation or warranty that Services will be:</span></p>

<p><span dir="LTR">12.5.1</span>&nbsp; <span dir="LTR">useful to you;</span></p>

<p><span dir="LTR">12.5.2</span>&nbsp; <span dir="LTR">of satisfactory quality;</span></p>

<p><span dir="LTR">12.5.3</span>&nbsp; <span dir="LTR">fit for a particular purpose;</span></p>

<p><span dir="LTR">12.5.4</span>&nbsp; <span dir="LTR">available or accessible, without interruption, or without error.</span></p>

<p><span dir="LTR">12.6.</span>&nbsp; <span dir="LTR">We claim no expert knowledge in any subject. We disclaim any obligation or liability to you arising directly or indirectly from information you take from Our Website and &nbsp;Mobile Application .</span></p>

<p><span dir="LTR">12.7.</span>&nbsp; <span dir="LTR">We accept no responsibility for:</span></p>

<p><span dir="LTR">12.7.1</span>&nbsp; <span dir="LTR">privacy of any transmission;</span></p>

<p><span dir="LTR">12.7.2</span>&nbsp; <span dir="LTR">third party advertisements which are posted on Our Website and Mobile Application or through the Services;</span></p>

<p><span dir="LTR">12.7.3</span>&nbsp; <span dir="LTR">the conduct, whether online or offline, of any user of Our Website and Mobile Application or the Services;</span></p>

<p><span dir="LTR">12.7.4</span>&nbsp; <span dir="LTR">failure or malfunction of computer hardware or software or technical equipment or system connected directly or indirectly to your use of the Services.</span></p>

<p><span dir="LTR">12.7.5</span>&nbsp; <span dir="LTR">loss or damage resulting from your attendance at an event organised through Our Website and Mobile Application or the Services;</span></p>

<p><span dir="LTR">12.8.</span>&nbsp; <span dir="LTR">You agree that in any circumstances when we may become liable to you, the limit of our liability is the amount you have paid us in the immediately preceding [12] month period for the Services concerned.</span></p>

<p>&nbsp;</p>

<p><span dir="LTR">12.9.</span>&nbsp; <span dir="LTR">Except in the case of death or personal injury, our total liability under this agreement, however it arises, shall not exceed the sum of R50,000.</span></p>

<p><span dir="LTR">12.10.</span>&nbsp;<span dir="LTR">We shall not be liable to you for any loss or expense which is:</span></p>

<p><span dir="LTR">12.10.1</span>&nbsp;&nbsp;<span dir="LTR">indirect or consequential loss; or</span></p>

<p><span dir="LTR">12.10.2</span>&nbsp;&nbsp;<span dir="LTR">economic loss or other loss of turnover, profits, business or goodwill even if such loss was reasonably foreseeable or we knew you might incur it.</span></p>

<p><span dir="LTR">12.11.</span>&nbsp;<span dir="LTR">This paragraph (and any other paragraph which excludes or restricts our liability) applies to our directors, officers, employees, subcontractors, agents and affiliated companies as well as to us.</span></p>

<h1><a name="_px3eqdws1mzj"></a><span dir="LTR"><strong style={{fontWeight:'bold'}}>13.</strong>&nbsp;</span><strong><span dir="LTR"><strong>You indemnify us</strong></span></strong></h1>

<p><span dir="LTR">You agree to indemnify us against all costs, claims and expense arising directly or indirectly from:</span></p>

<p><span dir="LTR">13.1.</span>&nbsp; <span dir="LTR">your failure to comply with the law of any country;</span></p>

<p><span dir="LTR">13.2.</span>&nbsp; <span dir="LTR">your breach of this agreement;</span></p>

<p><span dir="LTR">13.3.</span>&nbsp; <span dir="LTR">any act, neglect or default by any agent, employee, licensee or customer of yours;</span></p>

<p><span dir="LTR">13.4.</span>&nbsp; <span dir="LTR">&nbsp;a contractual claim arising from your use of the Services;</span></p>

<p><span dir="LTR">13.5.</span>&nbsp; <span dir="LTR">a breach of the intellectual property rights of any person;</span></p>

<p><span dir="LTR">13.6.</span>&nbsp; <span dir="LTR">for the purpose of this paragraph you agree that the cost of our management and technical time is properly recoverable and can reasonably be valued at R1000 per hour without further proof.</span></p>

<h1><a name="_8c6l5jyyunui"></a><span dir="LTR"><strong style={{fontWeight:'bold'}}>14.</strong>&nbsp;</span><strong><span dir="LTR"><strong>Miscellaneous matters</strong></span></strong></h1>

<p><span dir="LTR">14.1.</span>&nbsp; <span dir="LTR">You undertake to provide to us your current land address, e-mail address and telephone numbers as often as they are changed together with all information that we may require to enable us to fulfil our obligations under this contract.</span></p>

<p><span dir="LTR">14.2.</span>&nbsp; <span dir="LTR">If any term or provision of this agreement is at any time held by any jurisdiction to be void, invalid or unenforceable, then it shall be treated as changed or reduced, only to the extent minimally necessary to bring it within the laws of that jurisdiction and to prevent it from being void and it shall be binding in that changed or reduced form. Subject to that, each provision shall be interpreted as severable and shall not in any way affect any other of these terms.</span></p>

<p><span dir="LTR">14.3.</span>&nbsp; <span dir="LTR">The rights and obligations of the parties set out in this agreement shall pass to any permitted successor in title.</span></p>

<p><span dir="LTR">14.4.</span>&nbsp; <span dir="LTR">If you are in breach of any term of this agreement, we may:</span></p>

<p><span dir="LTR">14.4.1</span>&nbsp; <span dir="LTR">terminate your account and refuse access to Our Website and Mobile Application;</span></p>

<p><span dir="LTR">14.4.2</span>&nbsp; <span dir="LTR">remove or edit Content, or cancel any order at our discretion;</span></p>

<p><span dir="LTR">14.4.3</span>&nbsp; <span dir="LTR">issue a claim in any court.</span></p>

<p><span dir="LTR">14.5.</span>&nbsp; <span dir="LTR">Any obligation in this agreement intended to continue to have effect after termination or completion shall so continue.</span></p>

<p><span dir="LTR">14.6.</span>&nbsp; <span dir="LTR">No failure or delay by any party to exercise any right, power or remedy will operate as a waiver of it nor indicate any intention to reduce that or any other right in the future.</span></p>

<p><span dir="LTR">14.7.</span>&nbsp; <span dir="LTR">Any communication to be served on either party by the other shall be delivered by hand or sent by fastmail service or recorded delivery or by e-mail.</span></p>

<table cellspacing="0" style={{width:'384.7500pt'}}>
	<tbody>
		<tr>
			<td style={{height:'35.5000pt', verticalAlign:'top', width:'384.7500pt'}}>
			<p><span dir="LTR">It shall be deemed to have been delivered:</span></p>
			</td>
		</tr>
		<tr>
			<td style={{height:'29.5000pt', verticalAlign:'top', width:'384.7500pt'}}>
			<p><span dir="LTR">if delivered by hand: on the day of delivery;</span></p>
			</td>
		</tr>
		<tr>
			<td style={{height:'43.0000pt', verticalAlign:'top', width:'384.7500pt'}}>
			<p><span dir="LTR">if sent by post to the correct address: within 72 hours of posting;</span></p>
			</td>
		</tr>
		<tr>
			<td style={{height:'83.5000pt', verticalAlign:'top', width:'384.7500pt'}}>
			<p>&nbsp;</p>
			</td>
		</tr>
	</tbody>
</table>

<p><span dir="LTR">14.8.</span>&nbsp; <span dir="LTR">In the event of a dispute between the parties to this agreement, then they undertake to attempt to settle the dispute by engaging in good faith with the other in a process of mediation before commencing arbitration or litigation.</span></p>

<p><span dir="LTR">14.9.</span>&nbsp; <span dir="LTR">So far as the law permits, and unless otherwise stated, this agreement does not give any right to any third party.</span></p>

<p><span dir="LTR">14.10.</span>&nbsp;<span dir="LTR">In the event of any conflict between any term of this agreement and the provisions of the memorandum of incorporation of a limited company or any comparable document intended to regulate any other corporate or collective body, then the terms of this agreement shall prevail.</span></p>

<p><span dir="LTR">14.11.</span>&nbsp;<span dir="LTR">The validity, construction and performance of this agreement shall be governed by the laws of the Republic of South Africa</span>&nbsp;<span dir="LTR">and you agree that any dispute arising from it shall be litigated only in that country.</span></p>

</section>
           </div>
         </main>
    </>
  );
};

export default Terms;
