import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProfile, followaction } from "../../slices/userprofileSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../include/Loading";
import { API_BASE_URL } from "../../utils/config";
import Following from "../popups/Following";
import { useNavigate } from "react-router-dom";
import SignIn from "../SignIn/SignIn";
import SignUp from "../SignIn/SignUp";
import Swal from 'sweetalert2';
const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  
  
  useEffect(() => {
    
    dispatch(getProfile(slug));
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
   
  }, [dispatch, slug]);
  const [result, setResult] = useState(null);
  const [followVal, setFollowVal] = useState("Following");
  const profileInfo = useSelector((state) => state.profile.profileInfo);
  //   console.log("🚀 ~ file: Profile.js:14 ~ Profile ~ profileInfo:", profileInfo);
  
  const isLoading = useSelector((state) => state.profile.isLoading);

  const [active, setActive] = useState({ Active: [] });
  const globalLocation = useSelector((state) => state.location.globalLocation);
 

 useEffect(() => {
  if (profileInfo && profileInfo.listing) {
    const { listing } = profileInfo;
    
    if (listing) {
      // Extract active, pending, paused, and swopped items from the API response
      const { Active } = listing;

      setActive({ Active: Active || [] });
    }
  }
}, [profileInfo]);



 



const follow = async (e, action, following_id) => {
  console.log('Data to dispatch:', { action, following_id });
  try {
    
    const result = await dispatch(followaction({
      action: action,
      following_id: following_id,
    }));
    setResult(result);
    console.log('API Response:', result);

    // Check if the API call was successful
    if (result.payload && result.payload.status === 200) { 
         // Show success message
         Swal.fire(result.payload.message);
    } else {
      Swal.fire(result.payload.message);
     
    }
  } catch (error) {
    // Handle any errors
    console.log('Error adding item to wishlist:', error);
    alert('An error occurred while adding item to wishlist.');
  }
};


useEffect(() => {
  if (result && result.payload && result.payload.status === 200) {
    dispatch(getProfile(slug));
  }
}, [result]);

  const viewDetail = (e, user_slug,user_type) => {
    e.preventDefault();
    if(user_type == 'Business'){
      navigate("/detail/" + user_slug+'/automobile');
    } else {
      navigate("/detail/" + user_slug+'/product');
    }
   
  };

  return (
    <main className="sa-main-wrap sa-inner-pages-wrap">
      <Loading loading={isLoading} />

      <section className="sa-profile-wrap">
        <div className="container">
          <div className="row">
          <div className="col-lg-3">
        <div className="sa-list-address-wrap">
        <div className="sa-media-profile">
            <div className="profiledp">
              {profileInfo &&
              profileInfo.image &&
              profileInfo.image.length > 0 ? (
                <img
                  src={`${API_BASE_URL}${profileInfo.image}`}
                  alt="original"
                />
              ) : (
                <img src="../img/dp.jpg" alt="original" />
              )}
            </div>
            <div className="sa--media-body">
              <h2>
                {profileInfo
                  ? `${profileInfo.first_name} ${profileInfo.last_name}`
                  : "First Name"}
              </h2>
              <div className="rating_profile">
              <p>
  <span>
    <i className="fa fa-star" /> {/* Star icon */}
    {profileInfo && profileInfo.reviews_rating && profileInfo.reviews_rating.avg_rating} {/* Average rating */}
  </span>
  {profileInfo && profileInfo.reviews_rating && profileInfo.reviews_rating.total_reviews !== 0 ? (
    // Check if total_reviews is not 0
    <span>
      {profileInfo.reviews_rating.total_reviews} Reviews {/* Total reviews */}
    </span>
  ) : (
    '' // Empty string if total_reviews is 0
  )}
</p>

            </div>
              <p className="sa-locwrap">
                <i className="icon-07"></i> {globalLocation && globalLocation.city}
              </p>
              <div className="sa-socile">
                <ul>
                  <li className="twtter">
                    <a
                      href={
                        profileInfo && profileInfo.facebook
                          ? profileInfo.facebook
                          : "https://www.facebook.com/"
                      }
                      target="_blank"
                    >
                      <img src="../img/facebook1.svg" alt="" />{" "}
                    </a>
                  </li>
                  <li className="instagram">
                    <a
                      href={
                        profileInfo && profileInfo.instagram
                          ? profileInfo.instagram
                          : "https://www.instagram.com/"
                      }
                      target="_blank"
                    >
                      <img src="../img/insta1.svg" alt="" />{" "}
                    </a>
                  </li>
                  <li className="linkedin">
                    <a
                      href={
                        profileInfo && profileInfo.linkedin
                          ? profileInfo.linkedin
                          : "https://www.linkedin.com/"
                      }
                      target="_blank"
                    >
                      <img src="../img/in1.svg" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
              {localStorage.getItem("signInResponse") != null ? localStorage.getItem('user_id') != profileInfo.id ?
              <div className="profile-btns">
              { profileInfo.is_following ? 
              <a href="#" onClick={(e) => follow(e, 'unfollow', profileInfo.id)} className="btn btn-primary">UnFollow</a>
              :
              <a href="#" onClick={(e) => follow(e, 'follow', profileInfo.id)} className="btn btn-primary">Follow</a>
              }
            </div>
            :'':
            <div className="profile-btns">
               <a href="#"   data-popup-id="LogIn" className="btn btn-primary open-popup">Follow</a>
              </div>
            }
            </div>
          </div>
          <div className="sa-follow-row">
            <div
              className="sa-folowers-col active open-popup"
              data-popup-id="following"
              onClick={() => setFollowVal("Followers")}
            >
              <div className="sa-follow-no">
                {profileInfo &&
                profileInfo.followers &&
                profileInfo.followers.length > 0
                  ? profileInfo.followers.length
                  : "0"}
              </div>
              <div className="sa-follow-text">Followers</div>
            </div>

            <div
              className="sa-folowers-col open-popup"
              data-popup-id="following"
              onClick={() => setFollowVal("Following")}
            >
              <div className="sa-follow-no">
                {profileInfo &&
                profileInfo.following &&
                profileInfo.following.length > 0
                  ? profileInfo.following.length
                  : "0"}
              </div>
              <div className="sa-follow-text">Following</div>
            </div>
          </div>
            {/* <Profilemenu profileInfo={profileInfo} /> */}
            </div>
            </div>
            <div className="col-lg-9">
              <div className="sa-inner-right-wrap">
                <div className="sa-top-heading">
                  <h2>All Listing</h2>
                  <div className="sa-heading-tab">
                 
                  </div>
                </div>
                {Array.isArray(active.Active) && active.Active.length === 0 ? (
                  <div className="sa-inner-body">
                    <div className="sa-no-recoard">
                      <div className="sa-no-recoard__imgbox">
                        <img src="img/recordnot.png" alt="" />
                      </div>
                      <div className="sa-no-recoard__data">
                        <h2>Record not found</h2>
                        <p>We didn't find the record you're looking for!</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="sa-inner-body sa-list-all-cards">
                    <div className="row">
                      {active.Active.map((item, index) => (
                        <div className="col-lg-4" key={item.posted_product_id}>
                          <div className="sa-list-box">
                           <a href="#" 
                               onClick={(e) =>
                                viewDetail(
                                  e,
                                  item.slug,item.user_type
                                )}
                           >
                            <div className="sa-list-box__image">
                              <img src={item.image} alt="Swop" />
                            </div>
                            <div className="sa-list-box__content">
                              <div className="sa-list-box__content__title">
                                {item.title}
                              </div>
                            </div>
                            </a>
                          </div>
                        </div>
                      ))}

                      {/* <div className="col-lg-4">
                      <div className="sa-list-box">
                        <div className="sa-list-box__top sa-lst-clk dropdown-parents">
                          <i className="icon-20"></i>
                          <div className="dropdown-sm-menu">
                            <ul>
                              <li>
                                <a href="javascript:void(0);">
                                  <span>Pause</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="open-popup"
                                  data-popup-id="AddProduct"
                                >
                                  <span>Edit SWOP</span>
                                </a>
                              </li>
                              <li className="sa-delete">
                                <a href="#">
                                  <span>Delete</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="sa-list-box__image">
                          <img src="../img/list01.png" alt="Swop" />
                        </div>
                        <div className="sa-list-box__content">
                          <div className="sa-list-box__content__title">
                            Xiaomi-Mi-Mix-Black 8GB-128GB
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <SignIn />
      <SignUp />
      <Following profileInfo={profileInfo} popupType={followVal}  follow={follow}/>
     
    </main>
  );
};

export default UserProfile;
