import React, { useState, useEffect } from "react";
import Cards from "../cards/Cards";
import Select from "react-select";
import "./List.css";
import { useDispatch, useSelector } from "react-redux";
import { getVehicalsList,getBrandbymodel,getCategoryBanner } from "../../slices/listSlice";
import ReactPaginate from "react-paginate";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import MapWithInfo from "./MapWithInfo";
import Loading from "../include/Loading";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../utils/config";
import SubCategorylist from "../sliders/SubCategorylist";
import { useNavigate } from "react-router-dom";
import { getCategories, getSeach,getSeachlist } from "../../slices/headerSlice";



const SearchList = () => {
  //const { category_slug } = useParams();
  const {  keyword } = useParams();
  // console.log("🚀 ~ file: List.js:16 ~ List ~ slug:", slug);

   const dispatch = useDispatch();
   const navigate = useNavigate();
 

  const [pageItems, setPageItems] = useState([]);


  const searchdata = useSelector((state) => state.header.search);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [perpage, setperpage] = useState(null);
  const [totalproduct, settotalproduct] = useState(null);
  

  useEffect(() => {
    // console.log("🚀 ~ file: ProductDetail.js:8 ~ ProductDetail ~ slug:", slug);
    dispatch(
      getSeachlist({keyword,
        currentPage: 1}
      )
    );// Dispatch your API call action here using slug
   

  }, [dispatch, keyword]);
  

  useEffect(() => {
    if (
      // productsList &&
      // productsList.products &&
      // productsList.products.list.length > 0
      searchdata 
    
    ) {

      settotalproduct(searchdata.totalProducts);
      setperpage(searchdata.perPage);


    }

  }, [searchdata]);
  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;
   
    dispatch(
      getSeachlist({keyword,
        currentPage: currentPage}
      )
    );
  };

  const isLoading = useSelector((state) => state.list.isLoading);


  return (
    <>
      <main className="sa-main-wrap">
        <Loading loading={isLoading} />
     
        <div className="sa-search-list-outer sa-comman-section">
          <div className="container">
      
 
            <section className="sa-comman-section">
              <div className="sa-heading-section d-flex-wrap">

                  <div className="d-flex d-flex-wrap">

                <h2 className="sa-title">
                Search List with {keyword}
             </h2> 
                <div className="subdescription">
                  {searchdata &&
                   searchdata.totalProducts}{" "}
                  result found
                </div>

            </div>

            

              </div>
              <div className="sa-fpc-wrap">
                <div
                  id="swopcafetown"
                  className="sa-full-list-outer"
                  style={{ display: isChecked ? "none" : "flex" }}
                >
                  <div className="default-carousel-simple d-flex-wrap ">
                    {searchdata &&
                    searchdata.data &&
                    searchdata.data.map((card, index) => (
                        <React.Fragment key={index}>
                        <Cards
                      key={index}
                      image={card.image}
                      title={card.title}
                      price={card.price}
                      cardtype={card.type}
                     kmcontent={card.city}
                     kms_driven={card.kms_driven}
                     fuel_type={card.fuel_type}
                     transmission_type={card.year}
                      slug={card.slug}
                      type={card.type}
                      type_product={card.type_product}
                    />
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              
              </div>
            </section>
            {perpage < totalproduct ?
            <ReactPaginate
              previousLabel={<i className="icon-02"></i>}
              nextLabel={<i className="icon-12"></i>}
              breakLabel={"..."}
              pageCount={
                searchdata &&
                searchdata.totalPages
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"sa-pagination"}
              // pageClassName={"page-item"}
              // pageLinkClassName={"page-link"}
              // previousClassName={"icon-02"}
              // previousLinkClassName={"page-link"}
              // nextClassName={"page-item"}
              // nextLinkClassName={"page-link"}
              // breakClassName={"page-item"}
              // breakLinkClassName={"page-link"}
              // activeClassName={"active-page"}
              activeLinkClassName={"active"}
            /> :''}
            {/* ReactPaginate */}

            {/* <div className="sa-pagination">
              <a href="">
                <i className="icon-02"></i>
              </a>
              <a href="" className="active">
                1
              </a>
              <a href="">2</a>
              <a href="">3</a>
              <a href="">. . .</a>
              <a href="">10</a>
              <a href="">
                <i className="icon-12"></i>
              </a>
            </div> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default SearchList;
