import React, { useEffect,useState } from "react";
import Profilemenu from "./Profilemenu";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../slices/profileSlice";
import { API_BASE_URL } from "../../utils/config";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
const Referral = () => {

  const dispatch = useDispatch();
      useEffect(() => {
        dispatch(getProfile());
      }, [])

  const signInResponseString = localStorage.getItem("signInResponse");
  const signInResponse = JSON.parse(signInResponseString);
  const profileInfo = useSelector((state) => state.profile.profileInfo);
  const [shareitem, setShareitem] = useState({
   user_referral_code : signInResponse && signInResponse.referral_code ? signInResponse.referral_code :'',
    share_title: "Use my code "+(signInResponse && signInResponse.referral_code)+" and download the Swopanything App and stand a chance to win amazing prizes. \nFree, Fun & Exciting!🤩  \nStart Swopping😇 \n\n* Mobile Web App: "+API_BASE_URL+"  \n* GooglePlay: https://play.google.com/store/apps/details?id=com.swop.main \n* iStore: https://apps.apple.com/in/app/swop-anything/id1545894104 \n* Huawei: https://appgallery.huawei.com/#/app/C104438879 \n \n",
    share_url: API_BASE_URL 
});
  
  return (
    <main className="sa-main-wrap sa-inner-pages-wrap">
    <section className="sa-profile-wrap">
     <div className="container">
     <div className="row">
        <Profilemenu profileInfo={profileInfo} />
        <div className="col-lg-9">
                  <div className="sa-inner-right-wrap">
                     <div className="sa-top-heading">
                     <h2>Referral</h2>
                      
                  </div>
                  <div className="sa-inner-body sa-refferal-cards">
                      
                      <div className="refferalimg">
                        <img src="../img/referalimg.png" alt=""/>
                      </div>
                      <div className="refferal-content">
                        <h2 className="title">Refer a Friend</h2>
                        <p>
                        Share your referral code with friends or family When they use it to register you get an extra chance to win exciting prizes.
                        </p>
                        <div className="referralcode">
                        <h3 className="ref_code"  >Your Referral Code<span>{signInResponse && signInResponse.referral_code}</span></h3>
                     
                       
                     </div>
                     <a href="javaScript:void(0);" className="btn btn-primary open-popup" data-popup-id="referralopen"> Share </a>
                     </div>
                      
                  </div>
                 
                  </div>
               </div>
     </div>
  </div>
  <div className="comman-model comman-side-popup choose-any-model" id="referralopen">
       <div className="comman-popup-inner">
       <div className="top-model-header">
          <div className="pop-top-title">
             <h2>Share</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel"><i><img src="../img/close.png" alt=""/></i></a>
       </div>
      
       <div className="popup-model-body"> 
       <div className="form-group">
        <label>Share this Referral Code</label>
        <div className="default-modal-body">
                        <FacebookShareButton 
                            url={shareitem.share_url}
                            quote={shareitem.share_title}
                            className={''}>
                            <FacebookIcon size={50} />
                        </FacebookShareButton>
                        

                        <WhatsappShareButton 
                            url={shareitem.share_url}
                            title={shareitem.share_title}
                            className={''}>
                            <WhatsappIcon size={50} />
                        </WhatsappShareButton>

                        <TwitterShareButton 
                            url={shareitem.share_url}
                            title={shareitem.share_title}
                            className={''}>
                            <TwitterIcon size={50} />
                        </TwitterShareButton>

                        <LinkedinShareButton 
                            url={shareitem.share_url}
                            summary={shareitem.share_title}
                            className={''}>
                            <LinkedinIcon size={50} />
                        </LinkedinShareButton>
                    </div>
          </div>
        
          
           
       </div>
      
    </div>
        <div className="shadowpt" ></div>
      </div>
    </section>
  </main>
  );
};

export default Referral;
