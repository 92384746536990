import React, { useEffect } from "react";
import Profilemenu from "./Profilemenu";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCompletedSwop } from "../../slices/swopSlice";
import Loading from "../include/Loading";
import { getProfile } from "../../slices/profileSlice";

const CompletedSwop = () => {

      const dispatch = useDispatch();
      useEffect(() => {
        dispatch(getCompletedSwop());
        dispatch(getProfile());
      }, [])

      const completedSwopDetails = useSelector((state)=>state.swop.completedSwopDetails);
      const isLoading = useSelector((state) => state.swop.isLoading);
      const profileInfo = useSelector((state) => state.profile.profileInfo);
  
  return (
    <main className="sa-main-wrap sa-inner-pages-wrap">
      <Loading loading={isLoading} />
    <section className="sa-profile-wrap">
     <div className="container">
     <div className="row">
     <Profilemenu profileInfo={profileInfo} />
        <div className="col-lg-9">
           <div className="sa-inner-right-wrap">
              <div className="sa-top-heading">
              <h2>Swops</h2>
              <div className="sa-heading-tab">
              <ul>
                    <li>
                    <Link to={'/swops'}>Pending</Link>
                    </li>
                    <li className="active"> 
                        <Link to={'/completed-swop'}>Completed</Link>
                    </li>
                 </ul>
              </div>
           </div>
           <div className="sa-inner-body sa-list-all-cards">
              <div className="sa-card-wrap">
              <div className="row">

              {completedSwopDetails && completedSwopDetails.completed_swop_list && 
                      completedSwopDetails.completed_swop_list.map((item, index) => (
                 <div className="col-lg-4" key={index}>
                    <div className="sa-card-main card-bg-foot">
                       <div className="flex-head">
                            
                             <span>{item.date}</span>
                       </div>
                       <div className="flex-container">
                             <div className="flex-img set-left">
                             <Link to={item.from_item.posted_product_slug != 1 ? "/detail/"+item.from_item.posted_product_slug+'/product' : "#"}>
                             <img src={item.from_item.image} />
                                   </Link>
                                   <p>{item.from_item.title}</p>
                             </div>
                             <div className="compare_icon"> 
                                         <img src="../img/compare_icon.png" alt=""/> 
                             </div>
                             <div className="flex-img set-right">
                             <Link to={item.to_item.posted_product_slug != 1 ? "/detail/"+item.to_item.posted_product_slug+'/product' : "#"}>
                             <img src={item.to_item.image} />
                                   </Link>
                                   <p>{item.to_item.title}</p>
                             </div>
                       </div>
                       {item.rating && (
                        <div className="flex-bottom d-flex justify-content-center" data={parseInt(item.rating)}>
                          <ul className="sa-rating_star">
                             <li className={parseInt(item.rating) >=1 ? "active" : ""}><span><i class="icon-15"></i></span></li>
                             <li className={parseInt(item.rating) >=2 ? "active" : ""}><span><i class="icon-15"></i></span></li>
                             <li className={parseInt(item.rating) >=3 ? "active" : ""}><span><i class="icon-15"></i></span></li>
                             <li className={parseInt(item.rating) >=4 ? "active" : ""}><span><i class="icon-15"></i></span></li>
                             <li className={parseInt(item.rating) >='5' ? "active" : ""}><span><i class="icon-15"></i></span></li>
                           </ul>
                       </div>
                       )}
                       
                 </div>
                 </div>

                  ))}

              </div>
           </div>
           </div>
          
           </div>
        </div>
     </div>
  </div>
    </section>
  </main>
  );
};

export default CompletedSwop;
