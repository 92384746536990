import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import { getSignInResponse } from "../utils/authUtil";
import Swal from "sweetalert2";

export const mobileVerify = createAsyncThunk(
  "updateMob/mobileVerify",
  async (postData) => {
    let userData = getSignInResponse();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      email: postData.email,
      mobile: postData.mobNo,
      mobile_prefix_code: postData.prefixCode,
    };
    const { data } = await axios.post(
      API_URL + `user/mobile-verification`,
      body,
      config
    );
    return data;
  }
);
export const UpdateVerifyOtp = createAsyncThunk(
  "updateMob/UpdateVerifyOtp",
  async (postData) => {
    let userData = getSignInResponse();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      username: postData.userName,
      otp: postData.otp,
      mobile: postData.mobNo,
      mobile_prefix_code: postData.prefixCode,
    };
    const { data } = await axios.post(
      API_URL + `user/verify-otp`,
      body,
      config
    );
    return data;
  }
);

const updateMobSlice = createSlice({
  name: "updateMob",
  initialState: {
    isLoading: false,
    isError: false,
    verificationData: {},
    verifyOtpData: {},
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(mobileVerify.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(mobileVerify.fulfilled, (state, action) => {
      state.isLoading = false;
      state.verificationData = action.payload.data;
      state.isError = false;
    });
    builder.addCase(mobileVerify.rejected, (state) => {
      state.isLoading = false;
      state.verificationData = {};
      state.isError = true;
    });
    builder.addCase(UpdateVerifyOtp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UpdateVerifyOtp.fulfilled, (state, action) => {
      state.isLoading = false;

      action.payload.status === 200
        ? (state.verifyOtpData = action.payload.data)
        : Swal.fire({
            title: "Alert",
            text: action.payload.message,
            icon: "error",
          });

      state.isError = false;
    });
    builder.addCase(UpdateVerifyOtp.rejected, (state) => {
      state.isLoading = false;
      state.verifyOtpData = {};
      state.isError = true;
    });
  },
});

export default updateMobSlice.reducer;
