import React, { useEffect, useState } from "react";
import Profilemenu from "./Profilemenu";
import { getProfile, updateProductStatus,followaction } from "../../slices/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../include/Loading";
import Swal from "sweetalert2";
import AddProductPage from "../products/AddProduct";
const Profile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const profileInfo = useSelector((state) => state.profile.profileInfo);
  //   console.log("🚀 ~ file: Profile.js:14 ~ Profile ~ profileInfo:", profileInfo);

  const isLoading = useSelector((state) => state.profile.isLoading);

  const [active, setActive] = useState([]);
  const [pending, setPending] = useState([]);
  const [paused, setPaused] = useState([]);
  const [swopped, setSwopped] = useState([]);
  const [productslug, setProductslug] = useState('');
  const [result, setResult] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Active");
  const [popupProps, setPopupProps] = useState({}); 
  useEffect(() => {
    if (profileInfo && profileInfo.listing) {
      const { listing } = profileInfo;
      if (listing) {
        // Extract active, pending, paused, and swopped items from the API response
        const { Active, Pending, Paused, Swopped } = listing;

        // Update the respective state variables
        setActive(Active || []);
        setPending(Pending || []);
        setPaused(Paused || []);
        setSwopped(Swopped || []);
      }
    }
  }, [profileInfo]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  // Filter items based on the selected tab
  const filteredItems =
    selectedTab === "Active"
      ? active
      : selectedTab === "Pending"
      ? pending
      : selectedTab === "Paused"
      ? paused
      : swopped;

  const productActionMsg = useSelector(
    (state) => state.profile.productActionMsg
  );

  const handleMenuItemClick = async (event, item) => {
    event.preventDefault();
  
    let result;
  
    if (item.is_active === 1) {
      result = await dispatch(
        updateProductStatus({
          productId: item.posted_product_id,
          status: "inactive",
        })
      );
    } else if (item.is_active === 0) {
      result = await dispatch(
        updateProductStatus({
          productId: item.posted_product_id,
          status: "active",
        })
      );
    }
  
    // Store the response in the result variable
    setResult(result);
  
    // Now result holds the response from the API call
    console.log("API response:", result);
  
    // You can further process the result here if needed
  };
  

  const handleDeleteItem = async (event, item) => {
    event.preventDefault();
  
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleteResult = await dispatch(
          updateProductStatus({
            productId: item.posted_product_id,
            status: "delete",
          })
        );
  
        // Set the result state
        setResult(deleteResult);
  
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };
  

  // useEffect(() => {
  //   console.log('eeeeeeeee')
  //   if (productActionMsg && productActionMsg === "Success") {
  //     dispatch(getProfile());
  //   }
  // }, [productActionMsg]);

 const editPost = (e,item) => {
    e.preventDefault();
    setPopupProps(item);
    setProductslug('yes')
    //clearPostProductStorage();
   
    
}

const follow = async (e, action, following_id) => {
  console.log('Data to dispatch:', { action, following_id });
  try {
    
    const result = await dispatch(followaction({
      action: action,
      following_id: following_id,
    }));
    setResult(result);
    console.log('API Response:', result);

    // Check if the API call was successful
    if (result.payload && result.payload.status === 200) { 
         // Show success message
         Swal.fire(result.payload.message);
    } else {
      Swal.fire(result.payload.message);
     
    }
  } catch (error) {
    // Handle any errors
    console.log('Error adding item to wishlist:', error);
    alert('An error occurred while adding item to wishlist.');
  }
};

useEffect(() => {
  
  if (result && result.payload && result.payload.status === 200) {
    dispatch(getProfile());
  }
}, [result]);
  return (
    <main className="sa-main-wrap sa-inner-pages-wrap">
      <Loading loading={isLoading} />

      <section className="sa-profile-wrap">
        <div className="container">
          <div className="row">
            <Profilemenu profileInfo={profileInfo} follow={follow}/>
            <div className="col-lg-9">
              <div className="sa-inner-right-wrap">
                <div className="sa-top-heading">
                  <h2>All Listing</h2>
                  <div className="sa-heading-tab">
                    <ul>
                      <li
                        className={selectedTab === "Active" ? "active" : ""}
                        onClick={() => handleTabClick("Active")}
                      >
                        <a>Active</a>
                      </li>
                      <li
                        className={selectedTab === "Pending" ? "active" : ""}
                        onClick={() => handleTabClick("Pending")}
                      >
                        <a>Pending</a>
                      </li>
                      <li
                        className={selectedTab === "Paused" ? "active" : ""}
                        onClick={() => handleTabClick("Paused")}
                      >
                        <a>Paused</a>
                      </li>
                      <li
                        className={selectedTab === "Swopped" ? "active" : ""}
                        onClick={() => handleTabClick("Swopped")}
                      >
                        <a>Swopped</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {filteredItems && filteredItems.length === 0 ? (
                  <div className="sa-inner-body">
                    <div className="sa-no-recoard">
                      <div className="sa-no-recoard__imgbox">
                        <img src="img/recordnot.png" alt="" />
                      </div>
                      <div className="sa-no-recoard__data">
                        <h2>Record not found</h2>
                        <p>We didn't find the record you're looking for!</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="sa-inner-body sa-list-all-cards">
                    <div className="row">
                      {filteredItems.map((item, index) => (
                        
                        <div className="col-lg-4" key={item.posted_product_id}>
                          <div className="sa-list-box">
                          {item.is_active !== 2  && (
                              <div className="sa-list-box__top sa-lst-clk dropdown-parents">
                                <i className="icon-20"></i>
                                <div className="dropdown-sm-menu">
                                  <ul>
                                   {selectedTab !== "Pending" ? <li>
                                      <a
                                        onClick={(e) =>
                                          handleMenuItemClick(e,item)
                                        }
                                      >
                                        <span>
                                          {item.is_active === 1 && "Pause"}
                                        </span>
                                        <span>
                                          {item.is_active === 0 && "Active"}
                                        </span>
                                      </a>
                                    </li> :''}
                                    <li>
                                      <a
                                        className="open-popup"
                                        data-popup-id="AddProduct"
                                        onClick={(e) => editPost(e,item)}
                                      >
                                        <span>Edit SWOP</span>
                                      </a>
                                    </li>
                                    <li className="sa-delete">
                                      <a onClick={(e) => handleDeleteItem(e,item)}>
                                        <span>Delete</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                           <a href={"/detail/"+item.slug+'/product'} >
                            <div className="sa-list-box__image">
                              <img src={item.image} alt="Swop" />
                            </div>
                            <div className="sa-list-box__content">
                              <div className="sa-list-box__content__title">
                              {item.title}
                              </div>
                            </div>
                            </a>
                          </div>
                        </div>
                      ))}

                      {/* <div className="col-lg-4">
                      <div className="sa-list-box">
                        <div className="sa-list-box__top sa-lst-clk dropdown-parents">
                          <i className="icon-20"></i>
                          <div className="dropdown-sm-menu">
                            <ul>
                              <li>
                                <a href="javascript:void(0);">
                                  <span>Pause</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="open-popup"
                                  data-popup-id="AddProduct"
                                >
                                  <span>Edit SWOP</span>
                                </a>
                              </li>
                              <li className="sa-delete">
                                <a href="#">
                                  <span>Delete</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="sa-list-box__image">
                          <img src="../img/list01.png" alt="Swop" />
                        </div>
                        <div className="sa-list-box__content">
                          <div className="sa-list-box__content__title">
                            Xiaomi-Mi-Mix-Black 8GB-128GB
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <><AddProductPage rootCategoryId={1} item={popupProps} edit={productslug} /></>
    </main>
   
  );
};

export default Profile;
