import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import googleplay from "../../img/googleplay.png";
import appstore from "../../img/appstore.png";
import { API_BASE_URL } from "../../utils/config";
import { getDashboard } from "../../slices/homeSlice";

const DownloadSection = () => {
  const dispatch = useDispatch();

  const [footerbannerimg, setFooterbannerimg] = useState('');
  const JoinText = (
    <>
      {/* <b>Join</b> Over 30,000 Happy Users <br /> and get your <b>WOP</b> */}
      <b>Download</b> the SellAnything App <br /> and Win Airtime daily on our App <b>SCRATCH and WIN</b> Promo
    </>
  );

  // const descriptionText = "Choose your native platform & Download Now.";
  const descriptionText = "";
  const dashboard = useSelector((state) => state.home.dashboard);
  const settings = useSelector((state) => state.home.settings);
  
  const platformData = [
    {
      platform: "Google Play",
      imageSrc: googleplay,
      altText: "googleplay.png",
      url: settings.googleapp_link,
    },
    {
      platform: "App Store",
      imageSrc: appstore,
      altText: "appstore.png",
      url: settings.iosapp_link,
    },
  ];

  let bannerimg = ''; // Change const to let

  if (localStorage.getItem("footerbannerimg") === '') { // Use === for comparison
    bannerimg = dashboard.web_home_banner.web_mobile_app_banner.image;
  } else {
    bannerimg = localStorage.getItem("footerbannerimg");
  }

  // console.log('www22222222233333333333', settings.googleapp_link);

  return (
    <div className="upper-footer">
      <div className="container max-container">
        <div className="downrow">
          {bannerimg && (
            <div className="down-phone-img">
              <img
                src={`${API_BASE_URL}${bannerimg}`} // Corrected the image source
                alt="phone-img"
              />
            </div>
          )}
          <div className="contentdownload">
            <h2>{JoinText}</h2>
            <p> {descriptionText}</p>
            <div className="btndownloadapp">
              {platformData.map((platform) => (
                <a
                  key={platform.platform}
                  href={platform.url}
                  target="_blank"
                  className={`btn btn${platform.platform
                    .toLowerCase()
                    .replace(" ", "")}`}
                >
                  <i className="icon">
                    <img
                      className="lozad"
                      src={platform.imageSrc}
                      alt={platform.altText}
                    />
                  </i>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadSection;
