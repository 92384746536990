import React, { useRef, useEffect, useState } from 'react';
import Slider from "react-slick";
import { API_BASE_URL } from "../../utils/config";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import $ from 'jquery';

function SubCategorylist() {
  const parentsubcategorylist = useSelector((state) => state.list.vehicalList.subcategories_total);

  const settings = {
    // infinite: true,
    centerMode: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // Adjust the breakpoint for tablets if needed
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768, // Adjust the breakpoint for smaller tablets if needed
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    setTimeout(() => {
      $('.slick-prev').trigger('click');
    }, 2000);
  }, []);

  return (
    <>
      <ul className="sa-about-slider">
        <Slider {...settings}>
        {parentsubcategorylist &&
              [...parentsubcategorylist]
              .sort((a, b) => a.order_number - b.order_number)
              .map((item, index) => (
                <div className="home-item category-new-item" key={index}>
                <li className="col-sm-4" key={index}
                  onClick={() => {
                    localStorage.setItem("categoryname", item.name);
                  }} 
                >
                <Link
                  to={`/listing/automobile/${item.slug}`}
                  // className={item.className}
                  // activeClassName="active"
                >
                  <i className="iconimg">
                    {item.icon == '' ?
                     <img
                     className="usericon"
                     alt={item.name}
                     data-use-lozad="true"
                     // data-src={category.image}
                     // src={category.icon}
                     src="../../img/no-image-icon-6.png"
                     data-loaded="true"
                     style={{ width: "100%" }}
                   />
                  :  <img
                      className="lozad"
                      alt={item.name}
                      data-use-lozad="true"
                      // data-src={category.image}
                      // src={category.icon}
                      src={`${API_BASE_URL}${item.icon}`}
                      data-loaded="true"
                      style={{ maxWidth: "100%" }}
                    />
                  }
                  </i>
                  <span className="securecontent">
                    <span className="securehead">{item.name}</span>
                    <span className="securetext">{item.product_count} items</span>
                  </span>
                  {/* <div className="go-icon">
                    <i className="icon-12"></i>
                  </div> */}
                </Link>
              </li>
              </div>
            ))}
        </Slider>
      </ul>
    </>
  );
}

export default SubCategorylist;
