import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../slices/signIn";

function ForgotPassword() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(null);

  const resetMsg = useSelector((state) => state.signIn.resetInfo);

  useEffect(() => {
    if (resetMsg && resetMsg.length > 0) {
      alert(resetMsg);
      window.location.reload();
    }
  }, [resetMsg]);

  const handleReset = async () => {
    await dispatch(forgotPassword(email));

    // window.location.reload();
  };

  return (
    <div
      className="comman-model comman-side-popup forgot-password-model "
      id="ForgotPassword"
    >
      <div className="comman-popup-inner">
        <div className="top-model-header">
          <a
            href="javascript:void(0);"
            className="backarrow open-popup"
            data-popup-id="LogIn"
          >
            <i>
              <img src="../img/backarrow.svg" alt="" />
            </i>
          </a>
          <div className="pop-top-title">
            <h2>Forgot Password</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel">
            <i>
              <img src="../../img/close.png" alt="" />
            </i>
          </a>
        </div>
        <div className="popup-model-body">
          <form>
            <div className="forgotimgpt">
              <img src="../img/forgot.png" alt="" />
            </div>
            <div className="form-group">
              <label>Email Address</label>
              <input
                required
                type="email"
                className="form-control"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group btn_row">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleReset()}
              >
                Reset password
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="shadowpt clearfix"></div>
    </div>
  );
}

export default ForgotPassword;
