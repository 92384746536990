import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import { getSignInResponse } from "../utils/authUtil";

export const getNotifications = createAsyncThunk(
  "common/getNotifications",
  async () => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      user_id: userData.id,
    };
    const { data } = await axios.post(
      API_URL +
        `user/my-notifications`,
      body,
      config
    );
    return data;
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState: {
    isLoading: false,
    isError: false,
    notificationDetails:[]
  },
  reducers: {},

  extraReducers: (builder) => {
    
    builder.addCase(getNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notificationDetails = action.payload.data;
      state.isError = false;
    });
    builder.addCase(getNotifications.rejected, (state) => {
      state.isLoading = false;
      state.notificationDetails = [];
      state.isError = true;
    });

  },
});

export default commonSlice.reducer;
