import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Nofound = () => {

  return (
    <main className="sa-main-wrap sa-trems-pages-wrap">
            
    <div className="container">
      <section className="sa-full-content">
      
       <div className="sa-error-recoard">
          <div className="sa-error-img">
             <img src="../img/error404.png" alt="" />
          </div>
          <div className="sa-error-data">
             <h2>Page not found</h2>
             <p>The page you're looking for does not seem to exist</p>
             <Link to="/" className="btn btn-primary">
             Go to home
              </Link>
          </div>
       </div>

      </section>
      </div>
    </main>
  );
};

export default Nofound;
