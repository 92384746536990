import React from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../utils/config";
import { useLocation, useParams } from 'react-router-dom';

function Card({ title, image, kmcontent,price,slug,cardtype, listingType,kms_driven,fuel_type,transmission_type, banner, url, type,type_product ,featured}) {
  const navigate = useNavigate();
 

  const handleCardClick = (productSlug) => {
    // console.log('eeeeeeeeeeeeee22222',cardtype)
    if (cardtype === 'automobile') {
      navigate("/detail/" + productSlug + '/automobile');
      
    } else {
      navigate("/detail/" + productSlug + '/product');
    }
  };
 
  const handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  if (listingType === "google_ads") {
    return (
      <div className="item" onClick={handleScrollUp}>
        <div className="ad-box" style={{ cursor: "pointer" }}>
  
          <a href={`${url}`}>
            <div className="ad-image">
              <img src={`${API_BASE_URL}${banner}`} alt={title} />
            </div>
            {/* <div className="ad-content">
              <div className="ad-title">{title}</div>
              <div className="ad-location">
                <i className="location icon-07"></i> {kmcontent}{" "}
                <i className="chat icon-04"></i>
              </div>
            </div> */}
          </a>
        </div>
      </div>
    );
  } else {
    return (
      <div className="item" onClick={handleScrollUp}>
        <div
          className="ad-box"
          onClick={() => handleCardClick(slug)}
          style={{ cursor: "pointer" }}
        >
        
          <div className="ad-image">
          {cardtype === 'automobile' ? (
    <>
        <img src={API_BASE_URL + image} alt={title} />
        {/* <div className={type === 'Import' ? 'type-label' : 'localtype-label'}>
            {type}
        </div> */}
        {type_product !== null && type_product !== '' && (
            <div className={
              type_product === 'FEATURED' ? 'featuredside' :
              type_product === 'PREMIUM' ? 'premiumside' :
              type_product === 'CAR OF THE DAY' ? 'cardayside' :
              ''
            }>
                {type_product}
            </div>
        )}
 

    </>
) : (
  <>
    <img src={image} alt={title} />
    {featured !== null && featured !== '' && (
      <div className={
        featured == '1' ? 'featuredside' :''
     
      }>
       FEATURED  
      </div>
  )}
  </>
)}

            
          </div>
          <div className="ad-content">
            <div className="ad-title">{title}</div>
            {cardtype == 'automobile' ? 
  <div className="ad-location">
   <div className="locoutermain d-flex">
    {kms_driven ? 
    <span className="locitems"> {kms_driven}{" kms       "}</span> 
    :''} 
    {fuel_type ?
    <span className="locitems"> {fuel_type}{"       "}</span>   
    :''}
    {transmission_type ?
    <span className="locitems">{transmission_type}</span>
    :""}
   </div>
  </div>
: ''}

           {price ?<div className="pricelarge">
            
            {price}{" "} 
           {/* <i className="chat icon-04"></i> */}
         </div>:''}
         {kmcontent !== ''  && kmcontent !== undefined ?
           <div className="ad-location adlocation-bottom">
            
              <i className="location icon-07"></i> {kmcontent}{" "} 
              {/* <i className="chat icon-04"></i> */}
            </div>
            :''}
        
          </div>
          {/* </a> */}
        </div>
      </div>
    );
  }
}

export default Card;
