import React, { useEffect, useState } from "react";
import Profilemenu from "./Profilemenu";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWishlist,removewishlistItem,followaction } from "../../slices/swopSlice";
import Loading from "../include/Loading";
import { getProfile } from "../../slices/profileSlice";
import Swal from "sweetalert2";
const Wishlist = () => {
   const dispatch = useDispatch();
   const wishlistDetails = useSelector((state)=>state.swop.wishlistDetails);
   const isLoading = useSelector((state) => state.swop.isLoading);
   const profileInfo = useSelector((state) => state.profile.profileInfo);
   const [result, setResult] = useState(null);

  


    const handleDeleteWishlistItem = async (item) => {
     
      try {
        
        const result = await dispatch(removewishlistItem({
         productId: item.posted_product_id,
         type: item.type,
           status: "delete",
        }));
        setResult(result);
       
    
      } catch (error) {
        // Handle any errors
        console.log('Error adding item to wishlist:', error);
        alert('An error occurred while adding item to wishlist.');
      }
    };
  
    useEffect(() => {
      if (result && result.payload && result.payload.status === 200) {
       
         dispatch(getWishlist());
         dispatch(getProfile());
      }
    }, [result]);

   
   
      useEffect(() => {
        dispatch(getWishlist());
        dispatch(getProfile());
      }, [])

   
      const follow = async (e, action, following_id) => {
         console.log('Data to dispatch:', { action, following_id });
         try {
           
           const result = await dispatch(followaction({
             action: action,
             following_id: following_id,
           }));
           setResult(result);
           console.log('API Response:', result);
       
           // Check if the API call was successful
           if (result.payload && result.payload.status === 200) { 
                // Show success message
                Swal.fire(result.payload.message);
           } else {
             Swal.fire(result.payload.message);
            
           }
         } catch (error) {
           // Handle any errors
           console.log('Error adding item to wishlist:', error);
           alert('An error occurred while adding item to wishlist.');
         }
       };
      

    
      const  openDetailPage = (e) => {
         e.preventDefault();
         this.props.history.push("/detail/"+e.currentTarget.dataset.posted_product_slug);
     }
  return (
    <main className="sa-main-wrap sa-inner-pages-wrap">
      <Loading loading={isLoading} />
    <section className="sa-profile-wrap">
     <div className="container">
     <div className="row">
        <Profilemenu profileInfo={profileInfo} follow={follow}/>
        <div className="col-lg-9">
                  <div className="sa-inner-right-wrap">
                     <div className="sa-top-heading">
                     <h2>Wishlist</h2>
                      
                  </div>
                  <div className="sa-inner-body sa-list-all-cards">
                     <div className="row">

                     {wishlistDetails && wishlistDetails.list && 
                      wishlistDetails.list.map((item, index) => (

                        <div className="col-lg-4" key={index}>
                           <div className="sa-list-box">
                              <div className="sa-list-box__top sa-lst-clk dropdown-parents">
                                 <i className="icon-20"></i>
                                 <div className="dropdown-sm-menu">
                                    <ul>
                                       {/* <li>
                                          <a href="javascript:void(0);">
                                          
                                          <span>Pause</span>
                                          </a>
                                       </li>
                                       <li>
                                          <a href="javascript:void(0);" className="open-popup" data-popup-id="AddProduct">
                                          
                                          <span>Edit SWOP</span>
                                          </a>
                                       </li> */}
                                       <li className="sa-delete">
                                          <a onClick={() => handleDeleteWishlistItem(item)}> 
                                          <span>Delete</span>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                                 </div>  
                                 <a href={item.type === 'Product' ? "/detail/" + item.slug + "/product" : 
          item.type === 'Vehicle' ? "/detail/" + item.slug + "/automobile" : "#"}>
    <div className="sa-list-box__image">
        <img src={item.image} alt="Swop" />
    </div>
    <div className="sa-list-box__content">
        <div className="sa-list-box__content__title">{item.title}</div>
    </div>
</a>
                           </div>
                        </div>

                        ))}
                        
                     </div>

                  </div>
                 
                  </div>
               </div>
     </div>
  </div>
    </section>
  </main>
  );
};

export default Wishlist;
