import React, { Component } from "react";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../utils/config";
import { Link } from "react-router-dom";

function TrendingCategories() {
  const categories = useSelector((state) => state.header.trendingcategories);
  const TrendingCategories = [
    {
      image: "img/trend-card-1.png",
      trandtitle: "Automobile",
      trandtotal: "36 items",
    },
    {
      image: "img/trend-card-2.png",
      trandtitle: "Fashion",
      trandtotal: "59 items",
    },
    {
      image: "img/trend-card-3.png",
      trandtitle: "Home",
      trandtotal: "47 items",
    },
    {
      image: "img/trend-card-4.png",
      trandtitle: "Office",
      trandtotal: "39 items",
    },
    {
      image: "img/trend-card-5.png",
      trandtitle: "Electronics",
      trandtotal: "36 items",
    },
    {
      image: "img/trend-card-6.png",
      trandtitle: "Kids & Babies",
      trandtotal: "63 items",
    },
  ];

  return (
    <ul className="row sa-trending-card">
      {categories &&
        categories.slice(0, 6).map((category, index) => (
          <li className="col-sm-4" key={index}>
            <Link
              to={`/listing/byCategory/${category.slug}`}
              // className={item.className}
              // activeClassName="active"
            >
              <i className="iconimg">
                <img
                  className="lozad"
                  alt={category.name}
                  data-use-lozad="true"
                  // data-src={category.image}
                  // src={category.icon}
                  src={`${API_BASE_URL}${category.icon}`}
                  data-loaded="true"
                  style={{ maxWidth: "100%" }}
                />
              </i>
              <span className="securecontent">
                <span className="securehead">{category.name}</span>
                <span className="securetext">{category.prod_count} items</span>
              </span>
              <div className="go-icon">
                <i className="icon-12"></i>
              </div>
            </Link>
          </li>
        ))}
    </ul>
  );
}

export default TrendingCategories;
