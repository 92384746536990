import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Following from "../popups/Following";
import ChangePassword from "../popups/ChangePassword";
import { API_BASE_URL } from "../../utils/config";
import { useNavigate } from "react-router-dom";
const Profilemenu = ({ profileInfo,follow }) => {
  const logout = () => {
    localStorage.removeItem("signInResponse");
    window.location.href = "/";
  };

  const [followVal, setFollowVal] = useState("Following");

  const globalLocation = useSelector((state) => state.location.globalLocation);

  return (
    <>
      <div className="col-lg-3">
        <div className="sa-list-address-wrap">
          <div className="sa-media-profile">
            <div className="profiledp">
              {profileInfo &&
              profileInfo.image &&
              profileInfo.image.length > 0 ? (
                <img
                  src={`${API_BASE_URL}${profileInfo.image}`}
                  alt="original"
                />
              ) : (
                <img src="../img/dp.jpg" alt="original" />
              )}
            </div>
            <div className="sa--media-body">
              <h2>
                {profileInfo
                  ? `${profileInfo.first_name} ${profileInfo.last_name}`
                  : "First Name"}
              </h2>
              <p className="sa-locwrap">
                <i className="icon-07"></i> {globalLocation && globalLocation.city}
              </p>
              <div className="sa-socile">
                <ul>
                  <li className="twtter">
                    <a
                      href={
                        profileInfo && profileInfo.facebook
                          ? profileInfo.facebook
                          : "https://www.facebook.com/"
                      }
                      target="_blank"
                    >
                      <img src="../img/facebook1.svg" alt="" />{" "}
                    </a>
                  </li>
                  <li className="instagram">
                    <a
                      href={
                        profileInfo && profileInfo.instagram
                          ? profileInfo.instagram
                          : "https://www.instagram.com/"
                      }
                      target="_blank"
                    >
                      <img src="../img/insta1.svg" alt="" />{" "}
                    </a>
                  </li>
                  <li className="linkedin">
                    <a
                      href={
                        profileInfo && profileInfo.linkedin
                          ? profileInfo.linkedin
                          : "https://www.linkedin.com/"
                      }
                      target="_blank"
                    >
                      <img src="../img/in1.svg" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="sa-follow-row">
            <div
              className="sa-folowers-col active open-popup"
              data-popup-id="following"
              onClick={() => setFollowVal("Followers")}
            >
              <div className="sa-follow-no">
                {profileInfo &&
                profileInfo.followers &&
                profileInfo.followers.length > 0
                  ? profileInfo.followers.length
                  : "0"}
              </div>
              <div className="sa-follow-text">Followers</div>
            </div>

            <div
              className="sa-folowers-col open-popup"
              data-popup-id="following"
              onClick={() => setFollowVal("Following")}
            >
              <div className="sa-follow-no">
                {profileInfo &&
                profileInfo.following &&
                profileInfo.following.length > 0
                  ? profileInfo.following.length
                  : "0"}
              </div>
              <div className="sa-follow-text">Following</div>
            </div>
          </div>
          <div className="sa-aside-menu">
            <ul>
              <li
                className={
                  window.location.pathname == "/update-profile" && "active"
                }
              >
                <Link to="/update-profile">
                  <i>
                    {" "}
                    <img src="../img/profile1.svg" alt="" />{" "}
                  </i>
                  <span>Profile</span>
                </Link>
              </li>
              <li
                className={window.location.pathname == "/profile" && "active"}
              >
                <Link to="/profile">
                  <i>
                    {" "}
                    <img src="../img/alllisting1.svg" alt="" />{" "}
                  </i>
                  <span>All Listing</span>
                </Link>
              </li>
              <li
                className={
                  window.location.pathname == "/swops" ||
                  window.location.pathname == "/completed-swop"
                    ? "active"
                    : ""
                }
              >
                <Link to="/swops">
                  <i>
                    {" "}
                    <img src="../img/swops1.svg" alt="" />{" "}
                  </i>
                  <span>Swops</span>
                </Link>
              </li>
              <li
                className={
                  window.location.pathname == "/chat-history" && "active"
                }
              >
                <Link to="/chat-history">
                  <i>
                    {" "}
                    <img src="../img/chat1.svg" alt="" />{" "}
                  </i>
                  <span>Chat</span>
                </Link>
              </li>
              <li
                className={window.location.pathname == "/wishlist" && "active"}
              >
                <Link to="/wishlist">
                  <i>
                    {" "}
                    <img src="../img/wishlist1.svg" alt="" />{" "}
                  </i>
                  <span>Wishlist</span>
                </Link>
              </li>
              <li
                className={window.location.pathname == "/referral" && "active"}
              >
                <Link to="/referral">
                  <i>
                    {" "}
                    <img src="../img/refferal1.svg" alt="" />{" "}
                  </i>
                  <span>Referral</span>
                </Link>
              </li>
              <li className="dropdown-aside">
                <a href="javascript:void(0);">
                  <i>
                    {" "}
                    <img src="../img/setting1.svg" alt="" />{" "}
                  </i>
                  <span>Settings</span>
                </a>
                <div className="dropdown-web-menu">
                  <ul>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="open-popup"
                        data-popup-id="changepassword"
                      >
                        <span>Change Password</span>
                      </a>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"}>
                        <span>Privacy Policy</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/terms-of-service"}>
                        <span>Terms & Conditions</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link onClick={() => logout()}>
                  <i>
                    {" "}
                    <img src="../img/setting1.svg" alt="" />{" "}
                  </i>
                  <span>Logout</span>
                </Link>
              </li>
             
            </ul>
          </div>
        </div>
      </div>
      <Following profileInfo={profileInfo} popupType={followVal}  follow={follow}/>
      <ChangePassword />
    </>
  );
};

export default Profilemenu;
