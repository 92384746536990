import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import logo from "../../img/logo-splash.png";
import addproduct from "../../img/plus-icon.png";
import bell from "../../img/bell.png";
import chat from "../../img/chat.png";
import flag from "../../img/flag.png";
import searchicon from "../../img/search.png";
import moremenu from "../../img/moremenu.png";
import itemsico from "../../img/items-ico.png";
import skillsico from "../../img/skills-ico.png";
import businessico from "../../img/banner-card-2.png";
import LocationModel from "../popups/LocationModel";
import SignIn from "../SignIn/SignIn";
import SignUp from "../SignIn/SignUp";
import ForgotPassword from "../SignIn/ForgotPassword";
import AddProductPage from "../products/AddProduct";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCategories, getSeach,getTrendingCategories } from "../../slices/headerSlice";
import { API_BASE_URL } from "../../utils/config";
import Loading from "./Loading";
import { isAuthenticated, getSignInResponse } from "../../utils/authUtil";
import OtpVerify from "../SignIn/OTPverify";
import Notifications from "../popups/Notifications";
import Categories from "../popups/Categories";
import UpdateMob from "../profile/UpdateMob";

function Header() {
  const signInResponse = getSignInResponse();
  const loggedIn = isAuthenticated();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getTrendingCategories());
  }, []);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [keyword, setkeyword] = useState('');
  const [cursor, setCursor] = useState(-1);


  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const closeDiv = () => {
    setIsOpen(false);
  };
  const removeClass = () => {
    setMenuOpen(false);
  };

  const menuItems = [
    { label: "Automobile", icon: "../img/catimg01.jpg", link: "#" },
    { label: "Home", icon: "../img/catimg02.jpg", link: "#" },
    { label: "Electronics", icon: "../img/catimg03.jpg", link: "#" },
    { label: "Fashion", icon: "../img/catimg04.jpg", link: "#" },
    { label: "Kids & Babies", icon: "../img/catimg05.jpg", link: "#" },
    { label: "Office", icon: "../img/catimg06.jpg", link: "#" },
    { label: "Art & Designs", icon: "../img/catimg07.jpg", link: "#" },
    { label: "More", icon: moremenu, link: "#", className: "moreicons" },
  ];

  const globalLocation = useSelector((state) => state.location.globalLocation);

  const categories = useSelector((state) => state.header.categories);
  const results = useSelector((state) => state.header.search.data);

  const isLoading = useSelector((state) => state.header.isLoading);

  const [rootCategoryId, setRootCategoryId] = useState(1);
  const [roottype, setRootType] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // State to manage the index of the selected result
  const [selectedResultIndex, setSelectedResultIndex] = useState(-1);

  const openDropdown = () => {
    setIsDropdownOpen(true);
  };
  // Function to handle closing the dropdown
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  // Function to handle selecting a result from the dropdown
  const handleResultSelect = (index) => {
    setSelectedResultIndex(index);
    setIsDropdownOpen(false); // Close the dropdown after selecting a result
    navigateToSelectedResult(index);
  };

  // Function to navigate to the selected result page
  const navigateToSelectedResult = (index) => {
    if (index >= 0 && index < results.length) {
      closeDropdown();
      const { slug, type } = results[index];
      const url = `/detail/${slug}/${encodeURIComponent(type)}`;
      navigate(url);
    }
  };

  const onKeywordChange = (e) => {
    const value = e.target.value;
    setkeyword(value);
    if(value.length >= 3){
      openDropdown();
    dispatch(getSeach(value));
    }
    
    // Reset selected result when keyword changes
  };




  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const location = useLocation(); // Use this hook to get the current location

  useEffect(() => {
    const checkIsMobileDevice = () => {
      setIsMobileDevice(window.innerWidth <= 768);
    };

    checkIsMobileDevice();
    window.addEventListener('resize', checkIsMobileDevice);

    return () => window.removeEventListener('resize', checkIsMobileDevice);
  }, []);

  // Reset dropdown open state on route change
  useEffect(() => {
    setDropdownOpen(false);
  }, [location.pathname]); // Effect runs when location.pathname changes

  const handleMouseEnterDropdown = () => {
    if (!isMobileDevice) {
      setDropdownOpen(true);
    }
  };

  const handleMouseLeaveDropdown = () => {
    if (!isMobileDevice) {
      setDropdownOpen(false);
    }
  };

  const handleClickDropdown = () => {
    if (isMobileDevice) {
      setDropdownOpen(!dropdownOpen);
    }
  };





  const handleKeyDown = (e) => {
    if (results && results.length === 0) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedResultIndex((prevIndex) =>
          prevIndex < results.length - 1 ? prevIndex + 1 : 0
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedResultIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : results.length - 1
        );
        break;
      case "Enter":
        if (selectedResultIndex !== -1) {
          e.preventDefault();
          navigateToSelectedResult(selectedResultIndex);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedResultIndex, results]);

  const logout = () => {
    localStorage.removeItem("signInResponse");
    localStorage.removeItem("user_id");
    window.location.href = "/";
  };

  const handlesearchlist = (e) => {
    const value = String(keyword).trim();
    closeDropdown();
    setkeyword('');
    e.preventDefault();
    if (value.length >= 3) {
     
      const url = `/searchlist/${value}`;  
      navigate(url);  // Navigate without adding any query string
    }
  };
  

  return (
    <>
      <header className="sa-header-container">
        <Loading loading={isLoading} />
        <div className="top-header">
          <div className="menuicon togglemenupt d-none" onClick={toggleMenu}>
            <img src="../../img/menuicon.svg" alt="" />
          </div>
          <Link to="/" className="logo">
            <img className="weblogo" src={logo} alt="logo" />
          </Link>
          <div className="searchwrap">
            <form>
              <div className="search-control">
                <div
                  className={`selectlaungage selectmodelclk ${isOpen ? "open-it" : ""
                    }`}
                  onClick={handleClick}
                >
                  <div className="selectinner">
                    <i className="flagicon">
                      <img src={flag} alt="flag" />
                    </i>
                    <span>{globalLocation && globalLocation.city}</span>
                    <span>En </span>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search....."
                  value={keyword}
                  onChange={onKeywordChange}
                  onClick={(e) => handlesearchlist(e)}
                />
                <button className="btn btn-primary"  onClick={(e) => handlesearchlist(e)} >
                  <i>
                    <img src={searchicon} alt="" />
                  </i>
                </button>
                {isDropdownOpen && (
  <div className="result">
    {results && results.length > 0 ? (
      results.map((item, index) => (
        <p
          key={item.slug}
          className={index === selectedResultIndex ? "selected" : ""}
          onClick={() => handleResultSelect(index)}
        >
          {item.title}
        </p>
      ))
    ) : (
      <p>No data found</p> 
    )}
  </div>
)}

              </div>
            </form>

          </div>

          <div className="login-chat-cart">
            {loggedIn ? (
              <div className="wishlist-cart clarfix">
                <ul>
                  <li className="wishpt">
                    {" "}
                    <a
                      href="javascript:void(0);"
                      className="chatpt open-popup"
                      data-popup-id="Notifications"
                    >
                      <i>
                        <img src={bell} alt="" />
                      </i>
                    </a>
                  </li>
                   <li className="cartpt">
                    {" "}
                    <Link to={"/chat-history"} className="cart">
                      <i>
                        <img src={chat} alt="" />
                      </i>{" "}
                      {/* <span className="count">2</span> */}
                    </Link>{" "}
                  </li> 
                  <li className="businesspt">
                    {" "}
                    <a
                      href="/admin/business/login"
                      target="_blank"
                    >
                      <i>
                        {" "}
                        <img src={businessico} alt="" />{" "}
                      </i>
                      <span>Business Login</span>
                    </a>
                  </li>
                </ul>
              </div>
            ) :
              <div className="wishlist-cart clarfix">
                <ul>

                  <li className="businesspt">
                    {" "}
                    <a
                      href="/admin/business/login"
                      target="_blank"
                    >
                      <i>
                        {" "}
                        <img src={businessico} alt="" />{" "}
                      </i>
                      <span>Business Login</span>
                    </a>
                  </li>
                </ul>
              </div>
            }
            {/* <div className="signreg clarfix">
              <a
                href=""
                className="login-reg sign_in_click"
                title="Login / Register"
              >
                {" "}
                <span>SignIn</span>{" "}
                <i>
                  <img className="usericon" src="../img/user.svg" alt="" />
                </i>
              </a>
            </div> */}
            <div
              className="signreg clarfix dropdown-parents"
              onMouseEnter={handleMouseEnterDropdown}
              onMouseLeave={handleMouseLeaveDropdown}
              onClick={handleClickDropdown}
            >
              {loggedIn ? (
                <Link
                  to={"/profile"}
                  // href="javascript:void(0);"
                  className={"login-reg"}
                  // className="login-reg open-popup"
                  data-popup-id="LogIn"
                  title={"View Profile"}
                // title="Login / Register"
                >
                  {" "}
                  <span className="mobhidept">{signInResponse.first_name}</span>{" "}
                  <i
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    {signInResponse.image && signInResponse.image.length > 0 ? (
                      <img
                        className="usericon"
                        src={`${API_BASE_URL}${signInResponse.image}`}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <img className="usericon" src="../../img/user.svg" alt="" />
                    )}
                  </i>
                </Link>
              ) : (

                <a
                  href="javascript:void(0);"
                  className="login-reg open-popup"
                  data-popup-id="LogIn"
                  title="Login / Register"
                >
                  {" "}
                  <span>{"Sign In"}</span>{" "}
                  <i>
                    <img className="usericon" src="../../img/user.svg" alt="" />
                  </i>
                </a>
              )}
              {loggedIn && (
                <div className={`dropdown-web-menu ${dropdownOpen ? 'open' : ''}`}>
                  <ul>
                    <li>
                      <Link to="/update-profile">

                        <i>
                          {" "}
                          <img src="../../img/profile1.svg" alt="" />{" "}
                        </i>
                        <span>Profile</span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => logout()}>

                        <i>
                          {" "}
                          <img src={itemsico} alt="" />{" "}
                        </i>
                        <span>Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              )}

            </div>
          </div>

        </div>

        {/* <div className="sellonbtn">
          <a href="">
            <img src={addproduct} className="pluseico" alt="Swop" />+ Add
            Product
          </a>
        </div> */}

{ !isMobileDevice ? (
   <div className="sellonbtn dropdown-parents">
   { loggedIn ? (
               <a href="javascript:void(0);" className="open-popup" data-popup-id="AddProduct"  onClick={() => {
                      setRootCategoryId(1);
                      setRootType('home');
                    }}>
               <img src={addproduct} className="pluseico" alt="Swop"/> <span className="mhide"> Post an AD</span></a>
               ) : (
                <a  href="javascript:void(0);"
                className={"login-reg open-popup"}
                // className="login-reg open-popup"
                data-popup-id="LogIn"
                title={"Login / Register"}>
           <img src={addproduct} className="pluseico" alt="Swop"/> <span className="mhide"> Post an AD</span></a>
                ) }
               <div className="dropdown-web-menu d-none">
                  <ul>
                     <li>
                        <a href="javascript:void(0);" className="open-popup" data-popup-id="AddProduct">
                        <i> <img src="img/items-ico.png" alt=""/> </i>
                        <span>Items</span>
                        </a>
                     </li>
                     <li>
                        <a href="#">
                        <i> <img src="img/skills-ico.png" alt=""/> </i>
                        <span>Skills & Services</span>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
) : ''}
   
        <nav className={`sa-menu ${isMenuOpen ? 'open-it' : ''}`}>
          <div className="container">
            <div className="mob-top-model-header">
              <div className="pop-top-title">
                <h2>Categories</h2>
              </div>
              <a href="javascript:void(0);" className="close_panel" onClick={toggleMenu}><i><img src="../../img/close.png" alt="" /></i></a>
            </div>
            <ul>
            {categories &&
  [...categories] // Create a shallow copy to avoid mutating the original array
    .sort((a, b) => a.order_number - b.order_number) // Sort by order_number
    .map((item, index) =>
    <li className={(index > 5) ? "dropdown-parents right-drop-menu" : "dropdown-parents"} key={index} onClick={removeClass}>
      {item.slug === 'dealership' ? (
        <a
          href={`/listing/automobile/${item.slug}`}
          onClick={() => {
            localStorage.setItem("categoryname", item.name);
          }}
        >
          <i className="iconpt">
            <img src={`${API_BASE_URL}${item.icon}`} alt={item.name} />
          </i>
          {item.name}
        </a>
      ) : (
        <a
          href={`/listing/byCategory/${item.slug}`}
          onClick={() => {
            localStorage.setItem("categoryname", item.name);
          }}
        >
          <i className="iconpt">
            <img src={`${API_BASE_URL}${item.icon}`} alt={item.name} />
          </i>
          {item.name}
        </a>
      )}

      {item.subcategories && item.subcategories.length > 0 && (
        <div className="dropdown-web-menu">
          <ul>
            
             {item.subcategories &&
              [...item.subcategories]
            .sort((a, b) => a.order_number - b.order_number)
            .map((sub, subIndex) => (
              <li className="expanded-menu" key={subIndex}>
                {item.slug === 'dealership' ? (
                  <a href={`/listing/automobile/${sub.slug}`}
                  onClick={() => {
                    localStorage.setItem("categoryname", sub.name);
                  }}
                  >
                    {sub.name}
                  </a>
                ) : (
                  <a href={`/listing/byCategory/${sub.slug}`}
                  onClick={() => {
                    localStorage.setItem("categoryname", sub.name);
                  }}
                  >
                    {sub.name}
                  </a>
                )}

                {sub.subcategories && sub.subcategories.length > 0 && (
                  <div className="sub-expanded-menu">
                    <ul>
                      {sub.subcategories && [...sub.subcategories]
                      .sort((a, b) => a.order_number - b.order_number)
                      .map((subSub, subSubIndex) => (
                        <li key={subSubIndex}>
                          {item.slug === 'dealership' ? (
                            <a href={`/listing/automobile/${subSub.slug}`}
                            onClick={() => {
                              localStorage.setItem("categoryname", subSub.name);
                            }}>
                              {subSub.name}
                            </a>
                          ) : (
                            <a href={`/listing/byCategory/${subSub.slug}`}
                            onClick={() => {
                              localStorage.setItem("categoryname", subSub.name);
                            }}>
                              {subSub.name}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="shadowbg"></div>
    </li>
  )}

              {/* <li>
                <a
                  href="javascript:void(0);"
                  className="moreicons open-popup"
                  data-popup-id="Categories"
                >
                  <i className="iconpt">
                    <img src={moremenu} alt="more" />{" "}
                  </i>
                  More
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      </header >
      <div className={`sa-countrymodal ${isOpen ? "open-it" : ""}`}>
        <LocationModel closeDiv={closeDiv} />
        <div className="shadowpt" onClick={closeDiv}></div>
      </div>
      <SignIn />
      <SignUp />
      <ForgotPassword />
      {roottype == 'home' ? <AddProductPage rootCategoryId={rootCategoryId} /> : ''}
      <OtpVerify />
      <Notifications />
      <Categories />
      <UpdateMob />


      <div className="sellouterbox">
      { isMobileDevice ? (
      
      loggedIn ? (
          <a href="javascript:void(0);" className="sellbtnouter open-popup" data-popup-id="AddProduct"  onClick={() => {
                      setRootCategoryId(1);
                      setRootType('home');
                    }}>
                      <svg className="bgsvg" width="104" height="48" viewBox="0 0 104 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.0589 1.059H73.9851C86.6551 1.059 96.9262 11.3301 96.9262 24.0001C96.9262 36.6702 86.6551 46.9412 73.9851 46.9412H29.0589C16.3888 46.9412 6.11775 36.6701 6.11775 24.0001C6.11775 11.33 16.3888 1.059 29.0589 1.059Z" fill="white"/>
          <path d="M28.6088 41.8431C23.5515 41.8431 18.6969 40.2227 14.9397 37.2803L14.9387 37.2794C10.6192 33.897 8.14162 29.0571 8.14162 23.9998C8.14162 21.4329 8.75262 18.9553 9.95918 16.6375C11.5253 13.6288 14.0175 11.0576 17.1675 9.20212L13.8938 4.97831C9.84818 7.36094 6.64662 10.6646 4.63387 14.5311C3.08125 17.5152 2.29381 20.7015 2.29381 23.9998C2.29381 30.5015 5.47893 36.7254 11.0312 41.0732H11.0322C15.8642 44.8567 22.1068 46.9412 28.6085 46.9412H47.3397L48.9108 44.3922L47.3397 41.8433H28.6086L28.6088 41.8431Z" fill="#E82A26"/>
          <path d="M84.3139 2.41225C81.4564 1.51419 78.4544 1.05863 75.3912 1.05863H28.6093C23.3377 1.05863 18.2491 2.41381 13.8942 4.97819L14.0891 8.21106L17.1669 9.20263C20.5519 7.20919 24.5077 6.15644 28.609 6.15644H75.3909C77.7759 6.15644 80.1109 6.51 82.3302 7.20825C85.1371 8.09019 87.7424 9.53356 89.8631 11.3827L93.9983 7.77775C91.2723 5.40213 87.9235 3.54631 84.3135 2.412L84.3139 2.41225Z" fill="#9D4098"/>
          <path d="M93.9987 7.77831L90.5078 8.39769L89.8636 11.3827C93.7288 14.7533 95.8586 19.2337 95.8586 24.0002C95.8586 25.5774 95.6228 27.1414 95.1577 28.6479C92.7609 36.4173 84.6328 41.8434 75.3913 41.8434H47.3406L48.9106 44.3924L47.3406 46.9413H75.3913C87.273 46.9413 97.7228 39.9639 100.805 29.9727C101.403 28.0346 101.706 26.0251 101.706 24.0002C101.706 17.8725 98.9682 12.1118 93.9987 7.77831Z" fill="#2761A0"/>
          </svg>
         <div className="innerbtn">
            <span className="pluseico">
               <svg width="16px" height="16px" viewBox="0 0 1024 1024"><path d="M414.898 123.739v291.218h-291.218l-97.014 97.014 97.014 97.131h291.218v291.16l97.073 97.071 97.073-97.071v-291.16h291.16l97.131-97.131-97.131-97.014h-291.16v-291.218l-97.073-97.073z"></path></svg>
         </span><span className="selltext">Sell</span></div></a>
        ) : (
                <a  href="javascript:void(0);"
                className={"sellbtnouter login-reg open-popup"}
                // className="login-reg open-popup"
                data-popup-id="LogIn"
                title={"Login / Register"}>
                  <svg className="bgsvg" width="104" height="48" viewBox="0 0 104 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.0589 1.059H73.9851C86.6551 1.059 96.9262 11.3301 96.9262 24.0001C96.9262 36.6702 86.6551 46.9412 73.9851 46.9412H29.0589C16.3888 46.9412 6.11775 36.6701 6.11775 24.0001C6.11775 11.33 16.3888 1.059 29.0589 1.059Z" fill="white"/>
          <path d="M28.6088 41.8431C23.5515 41.8431 18.6969 40.2227 14.9397 37.2803L14.9387 37.2794C10.6192 33.897 8.14162 29.0571 8.14162 23.9998C8.14162 21.4329 8.75262 18.9553 9.95918 16.6375C11.5253 13.6288 14.0175 11.0576 17.1675 9.20212L13.8938 4.97831C9.84818 7.36094 6.64662 10.6646 4.63387 14.5311C3.08125 17.5152 2.29381 20.7015 2.29381 23.9998C2.29381 30.5015 5.47893 36.7254 11.0312 41.0732H11.0322C15.8642 44.8567 22.1068 46.9412 28.6085 46.9412H47.3397L48.9108 44.3922L47.3397 41.8433H28.6086L28.6088 41.8431Z" fill="#E82A26"/>
          <path d="M84.3139 2.41225C81.4564 1.51419 78.4544 1.05863 75.3912 1.05863H28.6093C23.3377 1.05863 18.2491 2.41381 13.8942 4.97819L14.0891 8.21106L17.1669 9.20263C20.5519 7.20919 24.5077 6.15644 28.609 6.15644H75.3909C77.7759 6.15644 80.1109 6.51 82.3302 7.20825C85.1371 8.09019 87.7424 9.53356 89.8631 11.3827L93.9983 7.77775C91.2723 5.40213 87.9235 3.54631 84.3135 2.412L84.3139 2.41225Z" fill="#9D4098"/>
          <path d="M93.9987 7.77831L90.5078 8.39769L89.8636 11.3827C93.7288 14.7533 95.8586 19.2337 95.8586 24.0002C95.8586 25.5774 95.6228 27.1414 95.1577 28.6479C92.7609 36.4173 84.6328 41.8434 75.3913 41.8434H47.3406L48.9106 44.3924L47.3406 46.9413H75.3913C87.273 46.9413 97.7228 39.9639 100.805 29.9727C101.403 28.0346 101.706 26.0251 101.706 24.0002C101.706 17.8725 98.9682 12.1118 93.9987 7.77831Z" fill="#2761A0"/>
          </svg>
         <div className="innerbtn">
            <span className="pluseico">
               <svg width="16px" height="16px" viewBox="0 0 1024 1024"><path d="M414.898 123.739v291.218h-291.218l-97.014 97.014 97.014 97.131h291.218v291.16l97.073 97.071 97.073-97.071v-291.16h291.16l97.131-97.131-97.131-97.014h-291.16v-291.218l-97.073-97.073z"></path></svg>
         </span><span className="selltext">Sell</span></div></a>

         )) : ''}          
        
      </div>


    </>
  );
}

export default Header;
