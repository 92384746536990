import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import { getSignInResponse } from "../utils/authUtil";

export const getDashboard = createAsyncThunk(
  "home/getDashboard",
  async (location) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Accept": "application/json",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "device": "web",
        "language": "en",
        "user_id": userData ? userData.id : 0,
        "latitude": location.latitude,
        "longitude": location.longitude,
        "city_id": "1",
        "city_name": location.city,
      },
    };
    let body;

    const { data } = await axios.post(
      API_URL + `web-user/dashboard`,
      body,
      config
    );

    return data;
  }
);

export const getSettings = createAsyncThunk("home/getSettings", async () => {
  const config = {
    headers: {
      "Accept": "application/json",
      "x_rest_username": "admin@restuser",
      "x_rest_password": "admin@Access",
      "x_rest_version": "v1",
    },
  };

  const { data } = await axios.get(`${API_URL}web-user/settings`, config);

  return data;
});

export const getFaqdatas = createAsyncThunk("home/getFaqdatas", async () => {
  const config = {
    headers: {
      "Accept": "application/json",
      "x_rest_username": "admin@restuser",
      "x_rest_password": "admin@Access",
      "x_rest_version": "v1",
    },
  };

  const { data } = await axios.get(`${API_URL}web-user/faq`, config);

  return data;
});

const homeSlice = createSlice({
  name: "home",
  initialState: {
    isLoading: false,
    isError: false,
    dashboard: [],
    settings: [],
    faqDatas: [],
    footerbannerimg:{}, 
  },
  reducers: {},

  extraReducers: (builder) => {
    // builder.addCase(getDashboard.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(getDashboard.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.data = action.payload;
    //   state.isError = false;
    // });
    // builder.addCase(getDashboard.rejected, (state) => {
    //   state.isLoading = false;
    //   state.data = [];
    //   state.isError = true;
    // });
    builder.addCase(getDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashboard = action.payload.data;
      localStorage.setItem("footerbannerimg",action.payload.data.web_home_banner.web_mobile_app_banner.image);
      // state.footerbannerimg = action.payload.data.web_home_banner.web_mobile_app_banner.image;
      state.isError = false;
    });
    builder.addCase(getDashboard.rejected, (state) => {
      state.isLoading = false;
      state.dashboard = [];
      state.isError = true;
    });
    builder.addCase(getSettings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.settings = action.payload.data;
      state.isError = false;
    });
    builder.addCase(getSettings.rejected, (state) => {
      state.isLoading = false;
      state.settings = [];
      state.isError = true;
    });
    builder.addCase(getFaqdatas.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFaqdatas.fulfilled, (state, action) => {
      state.isLoading = false;
      state.faqDatas = action.payload.data; // Just use action.payload here, assuming your API response directly contains the array of faqDatas
      state.isError = false;
    });
    builder.addCase(getFaqdatas.rejected, (state) => {
      state.isLoading = false;
      state.faqDatas = [];
      state.isError = true;
    });
  },
});

export default homeSlice.reducer;
