import React, { useState, useEffect } from "react";

const Privacy = () => {

  useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	});

  return (
    <>
      <main className="sa-main-wrap sa-trems-pages-wrap">
            <div className="container">
            <div className="breadcrumpart">
               <ul>
                  <li>
                     <a href="#">Home</a>
                  </li>
                  <li>
                    Privacy Policy 
                  </li>
               </ul>
            </div>
         </div>
         <div className="container">
           <section className="sa-trems-content">
            
          <p><strong><span dir="LTR"><strong>Privacy Policy and Notice</strong></span></strong></p>

<p><span dir="LTR">This is the privacy notice of www.Swopanything.com (&ldquo;Swopanything&rdquo;) provided to you by Oneminute Technologies Pty Ltd In this document, &ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us&rdquo; refer to .</span></p>

<p><span dir="LTR">We are company number &nbsp;2019 / 294454 / 07, registered in South Africa .</span></p>

<p><span dir="LTR">Our registered office is at </span></p>

<p><span dir="LTR">THE PIAZZA ON CHURCH SQUARE</span></p>

<p><span dir="LTR">UNIT 102 32 PARLIAMENT STREET</span></p>

<p><span dir="LTR">CAPE TOWN</span></p>

<p><span dir="LTR">WESTERN CAPE 8001.</span></p>

<p>&nbsp;</p>

<p><span dir="LTR">This is a statement to tell you our policy about all information that we record about you. It covers both information that could identify you and information that could not.</span></p>

<p><span dir="LTR">We are extremely concerned to protect your privacy and confidentiality. We understand that all users of our website and Mobile application &nbsp;are quite rightly concerned to know that their data will not be used for any purpose unintended by them, and will not accidentally fall into the hands of a third party. Our policy is both specific and strict. It complies with the South African law [and with the laws of all jurisdictions of which we are aware]. If you think our policy falls short of your expectations or that we are failing to abide by our policy, </span><strong><span dir="LTR"><strong>do please tell us.</strong></span></strong></p>

<p><span dir="LTR">We regret that if there are one or more points below with which you are not happy, your only recourse is to leave our website and Mobile application immediately.</span></p>

<p><span dir="LTR">Except as set out below, we do not share, or sell, or disclose to a third party, any personally identifiable information collected at this website and Mobile application.</span></p>

<p><span dir="LTR">Here is a list of the information we collect from you, either through our website and Mobile application or because you give it to us in some other way, and why it is necessary to collect it:</span></p>

<h1><a name="_nflsvy1mlzxd"></a><strong><span dir="LTR"><strong>1.</strong></span></strong>&nbsp;&nbsp; <strong><span dir="LTR"><strong>Business and personal information</strong></span></strong></h1>

<p><span dir="LTR">We may collect, use, store and transfer different types of business or personal information about you. This includes basic identification and contact information, such as your name and contact details and also includes all information given to us in the course of your business and ours, such as information you give us in your capacity as our client.</span></p>

<p><span dir="LTR">We undertake to preserve the confidentiality of the information and of the terms of our relationship. It is not used for any other purpose. We expect you to reciprocate this policy.</span></p>

<p><span dir="LTR">This information is used:</span></p>

<p><span dir="LTR">1.1.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">to provide you with the services which you request;</span></p>

<p><span dir="LTR">1.2.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">for verifying your identity for security purposes;</span></p>

<p><span dir="LTR">1.3.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">for marketing our services and products;</span></p>

<p><span dir="LTR">1.4.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">information which does not identify any individual may be used in a general way by us or third parties, to provide class information, for example relating to demographics or usage of a particular page or service.</span></p>

<p><span dir="LTR">We keep information, which forms part of our business record for a minimum of seven years. That is because we may need it in some way to support a claim or defence in court. That is also the period within which our tax collecting authorities may demand to know it.</span></p>

<h1><a name="_6jplryrj6ye"></a><strong><span dir="LTR"><strong>2.</strong></span></strong>&nbsp;&nbsp; <strong><span dir="LTR"><strong>Market place information</strong></span></strong></h1>

<p><span dir="LTR">When we obtain information from you specifically to enable you to buy a service offered on our website &nbsp;and Mobile application by some other person, we assume that in giving us your information, you are also giving us permission to pass it to the relevant person.</span></p>

<h1><a name="_960gn8sfht2p"></a><strong><span dir="LTR"><strong>3.</strong></span></strong>&nbsp;&nbsp;<strong><span dir="LTR"><strong>Your domain name and e-mail address</strong></span></strong></h1>

<p><span dir="LTR">This information is recognised by our servers and the pages that you visit are recorded. We shall not under any circumstances, divulge your e-mail address to any person who is not an employee or contractor of ours and who does not need to know, either generally or specifically. This information is used:</span></p>

<p><span dir="LTR">3.1.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">to correspond with you or deal with you as you expect;</span></p>

<p><span dir="LTR">3.2.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">in a collective way not referable to any particular individual, for the purpose of quality control and improvement of our website and Mobile application;</span></p>

<p><span dir="LTR">3.3.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">to send you news about the services to which you have signed up;</span></p>

<p><span dir="LTR">3.4.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">to tell you about other of our services or services of sister websites and Mobile application.</span></p>

<h1><a name="_xu67wgis3vzv"></a><strong><span dir="LTR"><strong>4.</strong></span></strong>&nbsp;&nbsp; <strong><span dir="LTR"><strong>Information you post on our website and mobile Application </strong></span></strong></h1>

<p><span dir="LTR">Information you send to us by posting to a forum or blog or in your advertisement, is stored on our servers. We do not specifically use that information except to allow it to be read, but you will see in our terms and conditions that we reserve a right to use it in any way we decide.</span></p>

<h1><a name="_n2ljjjec5zrc"></a><strong><span dir="LTR"><strong>5.</strong></span></strong>&nbsp;&nbsp; <strong><span dir="LTR"><strong>Website and mobile Application usage information</strong></span></strong></h1>

<p><span dir="LTR">We may use software embedded in our website and Mobile application (such as JavaScript) to collect information about pages you view and how you have reached them, what you do when you visit a page, the length of time you remain on the page, and how we perform in providing content to you. [We do not presently associate such information with an identifiable person]</span></p>

<h1><a name="_hihttulsrzb9"></a><strong><span dir="LTR"><strong>6.</strong></span></strong>&nbsp;&nbsp;<strong><span dir="LTR"><strong>Financial information relating to your credit cards</strong></span></strong></h1>

<p><span dir="LTR">This information is never taken by us either through our website and Mobile applicationor otherwise. At the point of payment, you are transferred to a secure page on the website and Mobile application of [WorldPay / SagePay / PayPal / MoneyBookers] or some other reputable payment service provider. That page may be dressed in our &ldquo;livery&rdquo;, but it is not controlled by us. Our staff and contractors never have access to it.</span></p>

<h1><a name="_lr11tubbiu28"></a><strong><span dir="LTR"><strong>7.</strong></span></strong>&nbsp;&nbsp;<strong><span dir="LTR"><strong>Note on padlock symbols and other trust marks</strong></span></strong></h1>

<p><span dir="LTR">Many companies offer certification and an icon or other small graphic to prove to website and Mobile application visitors that the website and Mobile application is safe. Some certify to a high level of safety. Others are more concerned to take our money than to provide a useful service. We do not handle information about your credit card so do not subscribe to any such service.</span></p>

<h1><a name="_34sd9ikmvhv2"></a><strong><span dir="LTR"><strong>8.</strong></span></strong>&nbsp;&nbsp; <strong><span dir="LTR"><strong>Financial information relating to your credit cards</strong></span></strong></h1>

<p><span dir="LTR">We [may] keep your financial information to provide you with a better shopping experience next time you visit us and to prevent fraud.</span></p>

<p><span dir="LTR">We use Secure Sockets Layer (SSL) certificates to verify our identity to your browser, and to encrypt any data you give us when you buy, including financial information such as credit or debit card numbers. Our SSL certificate encryption level is 128-bit/256-bit. Whenever we ask for financial information, you can check that SSL is being used by looking for a closed padlock symbol or other trust mark in your browser URL bar or toolbar.</span></p>

<p><span dir="LTR">We take the following measures to protect your financial information:</span></p>

<p><span dir="LTR">8.1.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">We keep your financial information encrypted on our servers.</span></p>

<p><span dir="LTR">8.2.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">We do not keep all your data, so as to prevent the possibility of our duplicating a transaction without a new instruction from you.</span></p>

<p><span dir="LTR">8.3.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">Access to your information is restricted to authorised staff only.</span></p>

<p><span dir="LTR">8.4.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">We automatically delete your information [after X days OR when the card expires].</span></p>

<p><span dir="LTR">8.5.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">If we ask you questions about your financial information, we shall show partial detail [the first four OR the last four digits of the debit or credit card number], only enough to identify the card(s) to which we refer.</span></p>

<p><span dir="LTR">8.6.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span dir="LTR">[Other...]</span></p>

<p><span dir="LTR">AND/OR</span></p>

<h1><a name="_1zt3sha8vaqz"></a><strong><span dir="LTR"><strong>9.</strong></span></strong>&nbsp;&nbsp; <strong><span dir="LTR"><strong>Financial information about your direct debit</strong></span></strong></h1>

<p><span dir="LTR">When you have agreed to set up a direct debit arrangement, the information you have given to us is passed to our own bank [name of bank] for processing according to our instructions. We [do / do not] keep a copy.</span></p>

<h1><a name="_m530v9prhi8o"></a><strong><span dir="LTR"><strong>10.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Credit reference</strong></span></strong></h1>

<p><span dir="LTR">To assist in combating fraud, we share information with credit reference agencies, so far as it relates to clients or customers who instruct their credit card issuer to cancel payment to us without having first provided an acceptable reason to us and given us the opportunity to refund their money.</span></p>

<h1><a name="_mnjse0rrtmwb"></a><strong><span dir="LTR"><strong>11.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Third party advertising</strong></span></strong></h1>

<p><span dir="LTR">Third parties may advertise on our website and Mobile application. In doing so, those parties, their agents or other companies working for them may use technology that automatically collects your IP address when they send an advertisement that appears on our website and Mobile application to your browser. They may also use other technology such as cookies or JavaScript to personalise the content of, and to measure the performance of their adverts. We do not have control over these technologies or the data that these parties obtain. Accordingly, this privacy notice does not cover the information practices of these third parties.</span></p>

<h1><a name="_oynxglvaby3y"></a><strong><span dir="LTR"><strong>12.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Third party content</strong></span></strong></h1>

<p><span dir="LTR">Our website and Mobile application is a publishing medium in that anyone may register and then publish information about himself or some other person. We do not moderate or control what is posted. If you complain about any of the content on our website and Mobile application, we shall investigate your complaint. If we feel, it may be justified, we shall remove it while we investigate. Free speech is a fundamental right, so we have to make a judgment as to whose right will be obstructed: yours, or that of the person who posted the content which offends you. If we think, your complaint is vexatious or without any basis, we shall not correspond with you about it.</span></p>

<h1><a name="_72vc0shmeesb"></a><strong><span dir="LTR"><strong>13.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Information we obtain from third parties</strong></span></strong></h1>

<p><span dir="LTR">Although we do not disclose your personal information to any third party (except as set out in this notice), we do receive data which is indirectly made up from your personal information, from software services such as Google Analytics and others. No such information is identifiable to you.</span></p>

<h1><a name="_6dzc0v4ilkeg"></a><strong><span dir="LTR"><strong>14.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Content you provide to us with a view to be used by third party</strong></span></strong></h1>

<p><span dir="LTR">If you provide information to us with a view to it being read, copied, downloaded or used by other people, we accept no responsibility for what that third party may do with it. It is up to you to satisfy yourself about the privacy level of every person who might see your information. If it is available to the World, you have no control whatever as to how it is used.</span></p>

<h1><a name="_tgatrboa7ru6"></a><strong><span dir="LTR"><strong>15.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Cookies</strong></span></strong></h1>

<p><span dir="LTR">Cookies are small text files that are placed on your computer&#39;s hard drive through your web browser when you visit any website. They are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the website.</span></p>

<p><span dir="LTR">Like all other users of cookies, we may request the return of information from your computer when your browser requests a web page from our server. Cookies enable our web server to identify you to us, and to track your actions and the pages you visit while you use our website. The cookies we use may last for a single visit to our website (they are deleted from your computer when you close your browser), or may remain on your computer until you delete them or until a defined period of time has passed.</span></p>

<p><span dir="LTR">Although your browser software enables you to disable cookies, we recommend that you allow the use of cookies in order to take advantage of the features of our website that rely on their use. If you prevent their use, you will not be able to use all the functionality of our website. Here are the ways we use cookies:</span></p>

<p><span dir="LTR">15.1.</span>&nbsp; <span dir="LTR">to record whether you have accepted the use of cookies on our website. This is solely to comply with the law. If you have chosen not to accept cookies, we will not use cookies for your visit, but unfortunately, our website will not work well for you.</span></p>

<p><span dir="LTR">15.2.</span>&nbsp; <span dir="LTR">to allow essential parts of our website to operate for you.</span></p>

<p><span dir="LTR">15.3.</span>&nbsp; <span dir="LTR">to operate our content management system.</span></p>

<p><span dir="LTR">15.4.</span>&nbsp; <span dir="LTR">to operate the online notification form - the form that you use to contact us for any reason. This cookie is set on your arrival at our website and deleted when you close your browser.</span></p>

<p><span dir="LTR">15.5.</span>&nbsp; <span dir="LTR">to enhance security on our contact form. It is set for use only through the contact form. This cookie is deleted when you close your browser.</span></p>

<p><span dir="LTR">15.6.</span>&nbsp; <span dir="LTR">to collect information about how visitors use our website. We use the information to improve your experience of our website and enable us to increase sales. This cookie collects information in an anonymous form, including the number of visitors to the website, where visitors have come to the website from, and the pages they visited.</span></p>

<p><span dir="LTR">15.7.</span>&nbsp; <span dir="LTR">to record that a user has viewed a webcast. It collects information in an anonymous form. This cookie expires when you close your browser.</span></p>

<p><span dir="LTR">15.8.</span>&nbsp; <span dir="LTR">to record your activity during a web cast. An example is as to whether you have asked a question or provided an opinion by ticking a box. This information is retained so that we can serve your information to you when you return to the website. This cookie will record an anonymous ID for each user, but it will not use the information for any other purpose. This cookie will last for [three] months, when it will delete automatically.</span></p>

<p><span dir="LTR">15.9.</span>&nbsp; <span dir="LTR">to store your personal information so that you do not have to provide it afresh when you visit the website next time. This cookie will last for [90] days.</span></p>

<p><span dir="LTR">15.10.</span>&nbsp;<span dir="LTR">to enable you to watch videos we have placed on YouTube. YouTube will not store personally identifiable cookie information when you use YouTube&rsquo;s privacy-enhanced mode.</span></p>

<h1><a name="_wkti7gb9spnq"></a><strong><span dir="LTR"><strong>16.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Calling our help line</strong></span></strong></h1>

<p><span dir="LTR">When you call our help line, we collect Calling Line Identification (CLI) information. We use this information to help improve the efficiency and effectiveness of our help line.</span></p>

<h1><a name="_w0b9ggxm2185"></a><strong><span dir="LTR"><strong>17.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Sending a message to our support system</strong></span></strong></h1>

<p><span dir="LTR">When you send a message, we collect the data you have given to us in that message in order to obtain confirmation that you are entitled to receive the information and to provide to you the information you need. We record your request and our reply in order to increase the efficiency of our business / organisation. We do not keep any personally identifiable information associated with your message, such as your name or email address.</span></p>

<h1><a name="_nb4pxdrqppe4"></a><strong><span dir="LTR"><strong>18.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Complaining</strong></span></strong></h1>

<p><span dir="LTR">When we receive a complaint, we record all the information you have given to us. We use that information to resolve your complaint. If your complaint reasonably requires us to contact some other person, we may decide to give to that other person some of the information contained in your complaint. We do this as infrequently as possible, but it is a matter for our sole discretion as to whether we do give information, and, if we do, what that information is.</span></p>

<p><span dir="LTR">We may also compile statistics showing information obtained from this source to assess the level of service we provide, but not in a way that could identify you or any other person.</span></p>

<h1><a name="_2d5iqw3373n4"></a><strong><span dir="LTR"><strong>19.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Job application and employment</strong></span></strong></h1>

<p><span dir="LTR">If you send us information in connection with a job application, we may keep it for up to three years in case we decide to contact you at a later date.</span></p>

<p><span dir="LTR">If we employ you, we collect information about you and your work from time to time throughout the period of your employment. This information will be used only for purposes directly relevant to your employment. After your employment has ended, we will keep your file for seven years before destroying or deleting it.</span></p>

<h1><a name="_mxxcgvd8y1vn"></a><strong><span dir="LTR"><strong>20.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Marketing information</strong></span></strong></h1>

<p><span dir="LTR">With your permission we pass your email address and name to selected associates whom we consider may provide services or products you would find useful. You may opt out of this service at any time by instructing us at [URL]</span></p>

<h1><a name="_ayosp4txwje5"></a><strong><span dir="LTR"><strong>21.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Re-marketing</strong></span></strong></h1>

<p><span dir="LTR">We may use re-marketing from time to time. This involves Google or some other supplier placing a tag or marker on your website and Mobile applicationin order to be able to serve to you an advert for our products / services when you visit some other website and Mobile application.</span></p>

<h1><a name="_wpil5aiu4p5e"></a><strong><span dir="LTR"><strong>22.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Affiliate information</strong></span></strong></h1>

<p><span dir="LTR">This is information given to us by you in your capacity as an affiliate of us or a customer or client of ours. Such information is retained for business use only. We undertake to preserve the confidentiality of the information and of the terms of our relationship. It is not used for any other purpose. We expect any affiliate to agree to reciprocate this policy. As an exception to this, we have the right to disclose your first name and URL of your affiliate connection to other affiliates and to any other person or organisation, on and off website. The reason is solely to enable us to mention winners and others whose performance as an affiliate is in some way outstanding.</span></p>

<h1><a name="_2fqx6o10ne64"></a><strong><span dir="LTR"><strong>23.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Use of website and mobile Application by children</strong></span></strong></h1>

<p><span dir="LTR">We do not market to children, nor do we sell products or services for purchase by children. We do sell products and services for end use by children, but for purchase by adults. If you are under 18, you may use our website &nbsp;and Mobile application only with consent from a parent or guardian.</span></p>

<p><em><span dir="LTR"><em>OR</em></span></em></p>

<h1><a name="_a31wv7k957i0"></a><strong><span dir="LTR"><strong>24.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Use of website mobile Application by children by children</strong></span></strong></h1>

<p><span dir="LTR">Certain areas of our website and Mobile applicationare designed for use by children over [15] years of age. We collect data about all users of and visitors to these areas regardless of age, and we anticipate that some of those users and visitors will be children.</span></p>

<p><span dir="LTR">Such child users and visitors will inevitably visit other parts of the website and will be subject to whatever on-site marketing they find, wherever they visit.</span></p>

<h1><a name="_8h1ic9gx2nni"></a><strong><span dir="LTR"><strong>25.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Disclosure to Government and their agencies</strong></span></strong></h1>

<p><span dir="LTR">We are subject to the law like everyone else. We may be required to give information to legal authorities if they so request or if they have the proper authorisation such as a search warrant or court order.</span></p>

<h1><a name="_wvgjf93agc4m"></a><strong><span dir="LTR"><strong>26.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Compliance with the law</strong></span></strong></h1>

<p><span dir="LTR">This privacy policy has been compiled so as to comply with the law of every jurisdiction in which we aim to do business. If you think it fails to satisfy the law of your country, we should like to hear from you, but ultimately it is your choice as to whether you wish to use our website and Mobile application.</span></p>

<h1><a name="_oi4912t4xl77"></a><strong><span dir="LTR"><strong>27.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Review or update personally identifiable information</strong></span></strong></h1>

<p><span dir="LTR">At any time you may review or update the personally identifiable information that we hold about you, by contacting us at the address below. To better safeguard your information, we will also take reasonable steps to verify your identity before granting access or making corrections to your information.</span></p>

<h1><a name="_udjo05j8vbl1"></a><strong><span dir="LTR"><strong>28.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Removal of your information</strong></span></strong></h1>

<p><span dir="LTR">If you wish us to remove personally identifiable information from our website and Mobile application, you may contact us at swopanything.com. To better safeguard your information, we will also take reasonable steps to verify your identity before granting access or making corrections to your information.</span></p>

<h1><a name="_glin6iceki83"></a><strong><span dir="LTR"><strong>29.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Data may be processed outside South Africa</strong></span></strong></h1>

<p><span dir="LTR">Our websites and Mobile application are hosted in companies based in &nbsp;United States of America &nbsp;or its servers globally. We also use outsourced services in countries outside South Africa from time to time in other aspects of our business. Specifically, our technical centre is based in India . Accordingly data obtained within South Africa may be &ldquo;processed&rdquo; outside South Africa and data obtained in any other country may be processed within or outside that country.</span></p>

<h1><a name="_fqli1um7qf72"></a><strong><span dir="LTR"><strong>30.</strong></span></strong>&nbsp;&nbsp;&nbsp; <strong><span dir="LTR"><strong>Information Officer</strong></span></strong></h1>

<p><span dir="LTR">We have appointed an Information Officer who is responsible for ensuring that our policy is followed.</span></p>

<p><span dir="LTR">If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact our Information Officer, at [info@swopanything.com].</span></p>

<p><em><span dir="LTR"><em>OR</em></span></em></p>

<p><span dir="LTR">If you have any question regarding the privacy policy, please contact us through the contact page on </span><a href="https://swopanything.com/ts-cs/" target="_blank"><span dir="LTR">https://swopanything.com/ts-cs/</span></a><span dir="LTR">.</span></p>


</section>
           </div>
         </main>
    </>
  );
};

export default Privacy;
