import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import Swal from 'sweetalert2'

export const signUp = createAsyncThunk("signUp/signUp", async (user) => {
  //   console.log("🚀 ~ file: signUp.js:6 ~ signUp ~ user:", user);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "x_rest_username": "admin@restuser",
      "x_rest_password": "admin@Access",
      "x_rest_version": "v1",
      // "Latitude": password.latitude,
      // "Longitude": password.longitude,
    },
  };
  let body = {
    // username: user.userName,
    // password: user.password,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    mobile: user.mobileNo,
    password: user.password,
    referral_code: user.referralCode,
    image: user.image,
    mobile_prefix_code: user.mobilePrefixCode,
  };
  const { data } = await axios.post(API_URL + `user/signup-with-otp`, body, config);
  return data;
});

export const verifyOtp = createAsyncThunk("signUp/verifyOtp", async (user) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "x_rest_username": "admin@restuser",
      "x_rest_password": "admin@Access",
      "x_rest_version": "v1",
      // "Latitude": password.latitude,
      // "Longitude": password.longitude,
    },
  };
  let body = {
    // username: user.userName,
    // password: user.password,
    username: user.userName,
    otp: user.otp,
  };
  const { data } = await axios.post(API_URL + `user/verify-otp`, body, config);
  return data;
});

const signUpSlice = createSlice({
  name: "signUp",
  initialState: {
    isLoading: false,
    isError: false,
    signUpInfo: "",
    signUpUserName: "",
    userData: {},
    // productsData: {},
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(signUp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.isLoading = false;
      //   action.payload.status === 200
      //     ? (state.userInfo = action.payload.message)
      //     : alert(action.payload.status);

      if (action.payload.status === 200) {
        state.signUpInfo = action.payload.message;
        state.signUpUserName = action.payload.data.username;
      } else {
        Swal.fire({  
          title: 'Alert',  
          text: action.payload.message,
          icon: 'error'
        }); 
      }

      state.isError = false;
    });
    builder.addCase(signUp.rejected, (state) => {
      state.isLoading = false;
      state.signUpInfo = "";
      state.signUpUserName = "";
      state.isError = true;
    });
    builder.addCase(verifyOtp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.isLoading = false;
      action.payload.status === 200
        ? (state.userData = action.payload.data)
        : Swal.fire({  
          title: 'Alert',  
          text: action.payload.message,
          icon: 'error'
        });
      state.isError = false;
    });
    builder.addCase(verifyOtp.rejected, (state) => {
      state.isLoading = false;
      state.userData = {};
      state.isError = true;
    });
  },
});

export default signUpSlice.reducer;
