import { configureStore } from "@reduxjs/toolkit";
import locationReducer from "../slices/locationSlice.js";
import homeReducer from "../slices/homeSlice.js";
import headerReducer from "../slices/headerSlice.js";
import homeBannerReducer from "../slices/homeBannerSlice.js";
import listReducer from "../slices/listSlice.js";
import productDetailReducer from "../slices/productDetailSlice.js";
import signInReducer from "../slices/signIn.js";
import signUpReducer from "../slices/signUp.js";
import addProductReducer from "../slices/addProductSlice.js";
import swopReducer from "../slices/swopSlice.js";
import profileReducer from "../slices/profileSlice.js";
import chatReducer from "../slices/chatSlice.js";
import changePasswordReducer from "../slices/changePasswordSlice.js";
import updateMobileReducer from "../slices/updateMobSlice.js";
import commonReducer from "../slices/commonSlice.js";

const store = configureStore({
  reducer: {
    location: locationReducer,
    home: homeReducer,
    header: headerReducer,
    homeBanner: homeBannerReducer,
    list: listReducer,
    productDetail: productDetailReducer,
    signIn: signInReducer,
    signUp: signUpReducer,
    addProduct: addProductReducer,
    swop: swopReducer,
    profile: profileReducer,
    chat: chatReducer,
    changePassword: changePasswordReducer,
    updateMobile: updateMobileReducer,
    common:commonReducer
  },
});

export default store;
