import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { contactUs } from "../../slices/contactUsSlice";
import Loading from "../include/Loading";
import Swal from 'sweetalert2';
const ContactUs = () => {

  useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	});
   const dispatch = useDispatch();
   const [result, setResult] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const formSubmit = async (e) => {
    
      // Getting the input values
      const name = document.querySelector('input[name="name"]').value;
      const email = document.querySelector('input[name="email"]').value;
      const description = document.querySelector('textarea[name="description"]').value;

      // Set loading state to true before making the API call
    
      // Check if name is empty
      var nameInput = document.getElementById("nameInput");
      
      // Check if name is empty
      if (!name.trim()) {
         nameInput.classList.add("error");
        
        return false;
      } else {
         nameInput.classList.remove("error");
   
      }

    
    
      // Check if mobile number is empty or not a number
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email.trim() || !emailRegex.test(email)) {
        Swal.fire({
          title: "Alert",
          text: "Please enter a valid Email.",
          icon: "error",
        });
        return false;
      }

      if (!description.trim()) {
         Swal.fire({
           title: "Alert",
           text: "Please enter a valid Description.",
           icon: "error",
         });
         return false;
       }

       var checkbox1 = document.getElementById("chkbx_1");

       // Check if the checkbox is checked or not
       if (!checkbox1.checked) {
           checkbox1.classList.add("error");
           return false;
       } else {
           checkbox1.classList.remove("error");
       }
   
       setIsLoading(true);
      try {
        const result = await dispatch(
         contactUs({
           name: name, // Adding name to the payload
            email: email, // Adding mobile number to the payload
            description :description
          })
        );
        setResult(result);
        console.log('API Response:', result);
    
        // Check if the API call was successful
        if (result.payload && result.payload.status === 200) {
          // Show success message
          Swal.fire("Success!", result.payload.message, "success");
        } else {
          Swal.fire("Alert!", result.payload.message, "error");
        }
      } catch (error) {
        // Handle any errors
        console.log('Error adding item to wishlist:', error);
       // alert('An error occurred while adding item to wishlist.');
      }  finally {
         setIsLoading(false); // Set loading state back to false after API call completes
       }
    };

    useEffect(() => {
      if (result && result.payload && result.payload.status === 200) {
       
  
     // Accessing DOM elements safely
     const nameInput = document.querySelector('input[name="name"]');
     const email = document.querySelector('input[name="eamil"]');
     const description = document.querySelector('textarea[name="description"]');
     
     // Check if the elements exist before setting their values
     if (nameInput && email && description) {
       // Set the input fields to blank
       nameInput.value = '';
       description.value = '';
       email.value = '';
     }
  }
    }, [result]);

  return (
    <>
      <main className="sa-main-wrap sa-trems-pages-wrap">
      <Loading loading={isLoading} />
      <div class="container">
               <div class="breadcrumpart">
                  <ul>
                     <li>
                        <a href="#">Home</a>
                     </li>
                     <li>
                        Contact Us
                     </li>
                  </ul>
               </div>
               <section class="contact-section-wrap">
                  <div class="row detailsrow">
                     <div class="col-lg-6">
                        <div class="contact-image">
                           <img src="img/contact.png" alt="" />
                        </div>
                      
                     </div>
                     <div class="col-lg-6">
                        <div class="get-in-touch-wrap">
                           <div class="top-logo-title"> 
                              <h2> Get in Touch <span>We would love to hear from you. Any feedback is valuable to us.</span></h2>
                           </div>
                           <div class="form-group">
                              <label>Full Name*</label>
                              <input type="text" class="form-control" id="nameInput" name="name" placeholder="Enter Your Name"/>
                           </div>
                           <div class="form-group">
                              <label>Email*</label>
                              <input type="email" class="form-control" name="email" placeholder="Enter Your Email"/>
                           </div>
                           <div class="form-group">
                              <label>Message*</label>
                              <textarea class="form-control" name="description" placeholder="Enter Your Message"></textarea>
                           </div>
                           <div class="form-group d-flex btn_row">     
                              <div class="form-group checkbox-group">
                                 <input className="custom-checkbox" type="checkbox" id="chkbx_1" />
                                 <label for="chkbx_1">
                                 <span></span> I agree to the <a href="/terms-of-service" target="_blank">Terms & Conditions</a> 
                                 </label>
                              </div> 
                              <button class="btn btn-primary" onClick={formSubmit}>Submit</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
              
                
            </div>
         </main>
    </>
  );
};

export default ContactUs;
