import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import { getSignInResponse } from "../utils/authUtil";
import Swal from "sweetalert2";

export const getChatlist = createAsyncThunk(
  "chat/getChatlist",
  async () => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      timezone: 'Africa/Gaborone',
     
    };
    const { data } = await axios.post(
      API_URL +
        `chat/chat-list`,
        body,
      config
    );
    return data;
  }
);

export const getChathistory = createAsyncThunk(
  "chat/getChathistory",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      timezone: 'Africa/Gaborone',
      swop_id :postData.swop_id
     
    };
    const { data } = await axios.post(
      API_URL +
        `chat/get-message-history`,
        body,
      config
    );
    return data;
  }
);

export const getSwpdetail = createAsyncThunk(
  "chat/getSwpdetail",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
     swop_id :postData.swop_id
     
    };
    const { data } = await axios.post(
      API_URL +
        `product/swop-detail`,
        body,
      config
    );
    return data;
  }
);

export const messageReplies = createAsyncThunk(
  "chat/messageReplies",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
     message_id: postData.message_id,
     reply: postData.reply,
    };
    const { data } = await axios.post(
      API_URL +
        `chat/message-reply`,
        body,
      config
    );
    return data;
  }
);

export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    console.log('ssssssssssssss',postData);
    let body;
    if(postData.attachment){
       body = {
        timezone: 'Africa/Gaborone',
        swop_id :postData.swop_id,
        from : userData.id,
        to :  postData.to,
        attachment : postData.attachment,
        message_type :  postData.message_type,
       
      };
    } else {
     body = {
      timezone: 'Africa/Gaborone',
      swop_id :postData.swop_id,
      from : userData.id,
      to :  postData.to,
      message_reply :  postData.message_reply,
      message :  postData.message,
      message_type :  postData.message_type,
     
    };
  }
    const { data } = await axios.post(
      API_URL +
        `chat/send-message`,
        body,
      config
    );
    return data;
  }
);

export const deleteChannels = createAsyncThunk(
  "chat/deleteChannels",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      swop_id: postData.swop_id,
   };
    const { data } = await axios.post(
      API_URL +
        `chat/delete-channel`,
        body,
      config
    );
    return data;
  }
);

export const deleteMessages = createAsyncThunk(
  "chat/deleteMessages",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      message_id: postData.message_id,
   };
    const { data } = await axios.post(
      API_URL +
        `chat/delete-message`,
        body,
      config
    );
    return data;
  }
);

export const getActiveProduct = createAsyncThunk(
  "chat/getActiveProduct",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      profile_user_id: postData.profile_user_id,
   };
    const { data } = await axios.post(
      API_URL +
        `user/active-products`,
        body,
      config
    );
    return data;
  }
);

export const suggestSwopAction = createAsyncThunk(
  "chat/suggestSwopAction",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      swop_id: postData.swop_id,
      to_posted_product_id: postData.to_posted_product_id,
      from_posted_product_id: postData.from_posted_product_id,
   };
    const { data } = await axios.post(
      API_URL +
        `user/suggest-swop`,
        body,
      config
    );
    return data;
  }
);

export const blockUsers = createAsyncThunk(
  "chat/blockUsers",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      to_user_id: postData.to_user_id,
   };
    const { data } = await axios.post(
      API_URL +
        `user/block-user`,
        body,
      config
    );
    return data;
  }
);


export const addReport = createAsyncThunk(
  "chat/addReport",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    
    let body = {
      reason: postData.reason,
      to_user_id: postData.to_user_id,
     
    };
    const { data } = await axios.post(
      API_URL +
        `user/report-user
    `,
      body,
      config
    );
    return data;
  }
);
export const followaction = createAsyncThunk(
  "profile/followaction",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      action: postData.action,
      following_id: postData.following_id,
    };
    const { data } = await axios.post(
      API_URL +
        `user/follow-action
    `,
      body,
      config
    );
    return data;
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    isLoading: false,
    isError: false,
    chatlistInfo: [],
    chathistory: [],
    chatUserinfo: [],
    SwopDetail: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChatlist.pending, (state) => {
      state.isLoading = true;
      state.isError = false; // Reset error state
    });
    builder.addCase(getChatlist.fulfilled, (state, action) => {
      state.isLoading = false;
      state.chatlistInfo = action.payload.data; // Assign payload directly
      state.isError = false;
    });
    builder.addCase(getChatlist.rejected, (state) => {
      state.isLoading = false;
      state.chatlistInfo = []; // Set to empty array
      state.isError = true;
    });

    builder.addCase(getChathistory.pending, (state) => {
      state.isLoading = true;
      state.isError = false; // Reset error state
    });
    builder.addCase(getChathistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.chathistory = action.payload.data; // Assign payload directly
      state.chatUserinfo =  action.payload.to_user_info;
      state.isError = false;
    });
    builder.addCase(getChathistory.rejected, (state) => {
      state.isLoading = false;
      state.chathistory = []; // Set to empty array
      state.chatUserinfo =[];
      state.isError = true;
    });

    builder.addCase(getSwpdetail.pending, (state) => {
      state.isLoading = true;
      state.isError = false; // Reset error state
    });
    builder.addCase(getSwpdetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.SwopDetail = action.payload.data; // Assign payload directly
      state.isError = false;
    });
    builder.addCase(getSwpdetail.rejected, (state) => {
      state.isLoading = false;
      state.SwopDetail = []; // Set to empty array
     state.isError = true;
    });
  },
});

export default chatSlice.reducer;
