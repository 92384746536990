import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import Swal from 'sweetalert2'

export const getSignIn = createAsyncThunk("signIn/getSignIn", async (user) => {
  //   console.log("🚀 ~ file: signIn.js:6 ~ getSignIn ~ user:", user);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "x_rest_username": "admin@restuser",
      "x_rest_password": "admin@Access",
      "x_rest_version": "v1",
      // "Latitude": password.latitude,
      // "Longitude": password.longitude,
    },
  };
  let body = {
    username: user.userName,
    password: user.password,
  };

  const { data } = await axios.post(API_URL + `user/check-login`, body, config);
  return data;
});

export const forgotPassword = createAsyncThunk(
  "signIn/forgotPassword",
  async (user) => {
    //   console.log("🚀 ~ file: signIn.js:6 ~ getSignIn ~ user:", user);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
      },
    };
    let body = {
      username: user,
    };

    const { data } = await axios.post(
      API_URL + `user/forgot-password`,
      body,
      config
    );
    return data;
  }
);

const signInSlice = createSlice({
  name: "signIn",
  initialState: {
    isLoading: false,
    isError: false,
    userInfo: {},
    resetInfo: "",
    signUpUserName:"",
    // productsData: {},
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getSignIn.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSignIn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userInfo = action.payload;
      // state.productsData = action.payload.data.products;
      if(action.payload.status === 200){
        state.signUpUserName = action.payload.login_username;
      }
      
      // action.payload.status === 200
      //   ? (state.signUpUserName = action.payload.data.username)
      //   : Swal.fire({  
      //     title: 'Alert',  
      //     text: action.payload.message,
      //     icon: 'error'
      //   }); 

      state.isError = false;
    });
    builder.addCase(getSignIn.rejected, (state) => {
      state.isLoading = false;
      state.userInfo = {};
      state.isError = true;
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.resetInfo = action.payload.message;
      state.isError = false;
    });
    builder.addCase(forgotPassword.rejected, (state) => {
      state.isLoading = false;
      state.resetInfo = "";
      state.isError = true;
    });
  },
});

export default signInSlice.reducer;
