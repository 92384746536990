import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import { setGlobalLocation } from "./locationSlice";
import { getSignInResponse } from "../utils/authUtil";

export const getProductsList = createAsyncThunk(
  "list/getProductsList",
  async ({ currentPage, location, sortBy, filter_type, category_slug,rating,distance,offer_type,min_price,max_price }) => {
    let userData = getSignInResponse();
    //console.log('teena22222',userData)
    const config = {
      
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Latitude": location.latitude,
        "Longitude": location.longitude,
         "user_id": userData ? userData.id : 0,
        // "Access_token" : "qL246uaG8_9E4sh4-NcPeAwXxum7NXFZ",
        // "Authorization" :"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJhdWQiOiIiLCJpYXQiOjE3MTA4NTEyNDUsIm5iZiI6MTcxMDg1MTI0NSwic3ViIjoyNTEsImVtYWlsIjoicHJhbWl0LmNoYXR1cnZlZGlAZ2lybmFyc29mdC5jby5pbiIsImFjY2Vzc190b2tlbiI6InFMMjQ2dWFHOF85RTRzaDQtTmNQZUF3WHh1bTdOWEZaIiwidHlwZSI6IlVzZXIifQ.bUhhgS_fbGbYdsoYZ8zlfYKU5gIzPzBdOUQic0R2F8k"
     
      },
    };
    let body = {
      category_id: category_slug ? category_slug : "",
      filter_type: filter_type ? filter_type : "byCategory",
      subcategory_id: 0,
      sort_by: sortBy || "FEATURED",
      rating: rating,
      distance: distance || 0,
      offer_type:offer_type || 0,
     // user_id : userData.id ||0,
      //   rating,
      page: currentPage,
     min_price: min_price || -1,
      max_price: max_price || -1,
    };

    const { data } = await axios.post(
      API_URL + `web-user/product-listing`,
      body,
      config
    );

    return data;
  }
);

export const getCategoryBanner = createAsyncThunk(
  "list/getCategoryBanner",
  
  async ({category_slug}) => {
    let userData = getSignInResponse(); 
    //console.log('teena22222',userData)
    
    const config = {
      
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "user_id": userData ? userData.id : 0,
        // "Access_token" : "qL246uaG8_9E4sh4-NcPeAwXxum7NXFZ",
        // "Authorization" :"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJhdWQiOiIiLCJpYXQiOjE3MTA4NTEyNDUsIm5iZiI6MTcxMDg1MTI0NSwic3ViIjoyNTEsImVtYWlsIjoicHJhbWl0LmNoYXR1cnZlZGlAZ2lybmFyc29mdC5jby5pbiIsImFjY2Vzc190b2tlbiI6InFMMjQ2dWFHOF85RTRzaDQtTmNQZUF3WHh1bTdOWEZaIiwidHlwZSI6IlVzZXIifQ.bUhhgS_fbGbYdsoYZ8zlfYKU5gIzPzBdOUQic0R2F8k"
     
      },
    };
    let body = {
      category_id: category_slug ? category_slug : "",
     
    };

    const { data } = await axios.post(
      API_URL + `web-user/getcategoriesbanner`,
      body,
      config
    );

    return data;
  }
);

export const getVehicalsList = createAsyncThunk(
  "list/getVehicalsList",
  async ({ currentPage, brand,model,city,year,userid,min_price,max_price,sort,type,category_slug,category,dealers }) => {
    let userData = getSignInResponse();
    //console.log('teena22222',userData)
    const config = {
      
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": location.latitude,
        // "Longitude": location.longitude,
         "user_id": userData ? userData.id : 0,
        // "Access_token" : "qL246uaG8_9E4sh4-NcPeAwXxum7NXFZ",
        // "Authorization" :"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJhdWQiOiIiLCJpYXQiOjE3MTA4NTEyNDUsIm5iZiI6MTcxMDg1MTI0NSwic3ViIjoyNTEsImVtYWlsIjoicHJhbWl0LmNoYXR1cnZlZGlAZ2lybmFyc29mdC5jby5pbiIsImFjY2Vzc190b2tlbiI6InFMMjQ2dWFHOF85RTRzaDQtTmNQZUF3WHh1bTdOWEZaIiwidHlwZSI6IlVzZXIifQ.bUhhgS_fbGbYdsoYZ8zlfYKU5gIzPzBdOUQic0R2F8k"
     
      },
    };
    let body = {
      brand : brand,
      model : model,
      category_slug : category_slug,
      city : city,
      dealers : dealers,
      year : year,
      userid:userid,
      page: currentPage,
      sort : sort,
      type : type,
      category:category,
     min_price: min_price || -1,
      max_price: max_price || -1,
    };

    const { data } = await axios.post(
      API_URL + `web-user/vehicle-listing`,
      body,
      config
    );

    return data;
  }
);

export const getUserVehicalsList = createAsyncThunk(
  "list/getUserVehicalsList",
 async ({ currentPage, location, sortBy, filter_type, category_slug,rating,distance,offer_type,min_price,max_price,userid }) => {
    //alert(userid)
    //let userData = getSignInResponse();
    //console.log('teena22222',userData)
    const config = {
      
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": location.latitude,
        // "Longitude": location.longitude,
       //  "user_id": userData ? userData.id : 0,
        // "Access_token" : "qL246uaG8_9E4sh4-NcPeAwXxum7NXFZ",
        // "Authorization" :"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJhdWQiOiIiLCJpYXQiOjE3MTA4NTEyNDUsIm5iZiI6MTcxMDg1MTI0NSwic3ViIjoyNTEsImVtYWlsIjoicHJhbWl0LmNoYXR1cnZlZGlAZ2lybmFyc29mdC5jby5pbiIsImFjY2Vzc190b2tlbiI6InFMMjQ2dWFHOF85RTRzaDQtTmNQZUF3WHh1bTdOWEZaIiwidHlwZSI6IlVzZXIifQ.bUhhgS_fbGbYdsoYZ8zlfYKU5gIzPzBdOUQic0R2F8k"
     
      },
    };

    let body = {
      userid:userid,
      category_id: category_slug ? category_slug : "",
      filter_type: filter_type ? filter_type : "byCategory",
      subcategory_id: 0,
      sort_by: sortBy || "FEATURED",
      rating: rating,
      distance: distance || 0,
      offer_type:offer_type || 0,
     // user_id : userData.id ||0,
      //   rating,
      page: currentPage,
     min_price: min_price || -1,
      max_price: max_price || -1,
    };

    const { data } = await axios.post(
      API_URL + `web-user/user-product-listing`,
      body,
      config
    );

    return data;
  }
);

export const getBrandbymodel = createAsyncThunk(
  "list/getBrandbymodel",
  async ({ brand_id }) => {
    let userData = getSignInResponse();
    //console.log('teena22222',userData)
    const config = {
      
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
      
        // "Access_token" : "qL246uaG8_9E4sh4-NcPeAwXxum7NXFZ",
        // "Authorization" :"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJhdWQiOiIiLCJpYXQiOjE3MTA4NTEyNDUsIm5iZiI6MTcxMDg1MTI0NSwic3ViIjoyNTEsImVtYWlsIjoicHJhbWl0LmNoYXR1cnZlZGlAZ2lybmFyc29mdC5jby5pbiIsImFjY2Vzc190b2tlbiI6InFMMjQ2dWFHOF85RTRzaDQtTmNQZUF3WHh1bTdOWEZaIiwidHlwZSI6IlVzZXIifQ.bUhhgS_fbGbYdsoYZ8zlfYKU5gIzPzBdOUQic0R2F8k"
     
      },
    };
    let body = {
      brand: brand_id,
     };

    const { data } = await axios.post(
      API_URL + `web-user/getmodel`,
      body,
      config
    );

    return data;
  }
);

export const getSubCategories = createAsyncThunk(
  "addProduct/getSubCategories",
  async (rootCategoryId) => {
   
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
      },
    };
    
      var isbusiness = 1;
    
    let body = { category_id: rootCategoryId, only_one_level: 1,isbusiness:isbusiness };

    const { data } = await axios.post(
      API_URL + `web-user/sub-categories`,
      body,
      config
    );

    return data;
  }
);

export const getSubChildCategories = createAsyncThunk(
  "addProduct/getSubChildCategories",
  async (rootCategoryId) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
      },
    };
    let body = { category_id: rootCategoryId, only_one_level: 1 };

    const { data } = await axios.post(
      API_URL + `web-user/sub-categories`,
      body,
      config
    );

    return data;
  }
);

const listSlice = createSlice({
  name: "list",
  initialState: {
    isLoading: false,
    isError: false,
    productsList: {},
    vehicalList: {},
    modellist:{},
    CategoryBanner:{},
    userproductsList: {},
    getSubCategories:[],
    subChildCategories:[]
    // productsData: {},
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getProductsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductsList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.productsList = action.payload.data;
      // state.productsData = action.payload.data.products;
      state.isError = false;
    });
    builder.addCase(getProductsList.rejected, (state) => {
      state.isLoading = false;
      state.productsList = {};
      state.isError = true;
    });

    builder.addCase(getSubCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subCategories = action.payload.data.list;
      state.isError = false;
    });
    builder.addCase(getSubCategories.rejected, (state) => {
      state.isLoading = false;
      state.subCategories = [];
      state.isError = true;
    });

    builder.addCase(getUserVehicalsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserVehicalsList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userproductsList = action.payload.data;
      // state.productsData = action.payload.data.products;
      state.isError = false;
    });
    builder.addCase(getUserVehicalsList.rejected, (state) => {
      state.isLoading = false;
      state.userproductsList = {};
      state.isError = true;
    });

    builder.addCase(getSubChildCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubChildCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subChildCategories = action.payload.data.list;
      state.isError = false;
    });
    builder.addCase(getSubChildCategories.rejected, (state) => {
      state.isLoading = false;
      state.subChildCategories = [];
      state.isError = true;
    });

    builder.addCase(getCategoryBanner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCategoryBanner.fulfilled, (state, action) => {
      state.isLoading = false;
      state.CategoryBanner = action.payload.data[0];
      // state.productsData = action.payload.data.products;
      state.isError = false;
    });
    builder.addCase(getCategoryBanner.rejected, (state) => {
      state.isLoading = false;
      state.CategoryBanner = {};
      state.isError = true;
    });

    builder.addCase(getVehicalsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVehicalsList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vehicalList = action.payload.data;
      // state.productsData = action.payload.data.products;
      state.isError = false;
    });
    builder.addCase(getVehicalsList.rejected, (state) => {
      state.isLoading = false;
      state.vehicalList = {};
      state.isError = true;
    });

    builder.addCase(getBrandbymodel.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBrandbymodel.fulfilled, (state, action) => {
      state.isLoading = false;
      state.modellist = action.payload.data;
      // state.productsData = action.payload.data.products;
      state.isError = false;
    });
    builder.addCase(getBrandbymodel.rejected, (state) => {
      state.isLoading = false;
      state.modellist = {};
      state.isError = true;
    });
  },
});

export default listSlice.reducer;
