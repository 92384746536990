import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { changePassword } from "../../slices/changePasswordSlice";
import { useNavigate } from "react-router";
import Loading from "../include/Loading";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "oldPassword") {
      setOldPassword(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if old password, new password, and confirm new password are filled
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      setError("Please fill in all fields.");
      Swal.fire({
        title: "Alert",
        text: "Please fill in all fields.",
        icon: "error",
      });
      return;
    }

    // Check if new password is at least 6 characters long
    if (newPassword.length < 6) {
      setError("New password must be at least 6 characters long.");
      Swal.fire({
        title: "Alert",
        text: "New password must be at least 6 characters long.",
        icon: "error",
      });
      return;
    }

    // Check if new password and confirm new password match
    if (newPassword !== confirmNewPassword) {
      setError("New password and confirm new password do not match.");
      Swal.fire({
        title: "Alert",
        text: "New password and confirm new password do not match.",
        icon: "error",
      });
      return;
    }

    dispatch(changePassword({ oldPassword, newPassword }));

    // Add your password change logic here
    // You can make an API request to change the password or update it in your state

    // Clear the form and any previous errors
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setError(null);
  };

  const changePass = useSelector((state) => state.changePassword);
  const isLoading = useSelector((state) => state.changePassword.isLoading);

  useEffect(() => {
    if (changePass.status === 200) {
      window.location.reload();
    } else if (changePass.status === 400) {
      Swal.fire({
        title: "Alert",
        text: changePass.message,
        icon: "error",
      });
    }
  }, [changePass.status]);

  return (
    <div
      className="comman-model comman-side-popup log-in-model"
      id="changepassword"
    >
      <Loading loading={isLoading} />

      <div className="comman-popup-inner">
        <div className="top-model-header">
          <div className="pop-top-title">
            <h2>Change Password</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel">
            <i>
              <img src="../img/close.png" alt="" />
            </i>
          </a>
        </div>
        <div className="popup-model-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Old Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Old Password"
                name="oldPassword"
                value={oldPassword}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>New Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter New Password"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Confirm New Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Confirm Password"
                name="confirmNewPassword"
                value={confirmNewPassword}
                onChange={handleChange}
              />
            </div>

            <div className="form-group btn_row">
              <button className="btn btn-primary" type="submit">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="shadowpt clearfix"></div>
    </div>
  );
};

export default ChangePassword;
