import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import { getSignInResponse } from "../utils/authUtil";
import { useSelector } from "react-redux";

export const getPendingSwop = createAsyncThunk(
  "swop/getPendingSwop",
  async () => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      user_id: userData.id,
    };
    const { data } = await axios.post(
      API_URL +
        `product/my-running-swops`,
      body,
      config
    );
    return data;
  }
);

export const getCompletedSwop = createAsyncThunk(
  "swop/getCompletedSwop",
  async () => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      user_id: userData.id,
    };
    const { data } = await axios.post(
      API_URL +
        `user/my-completed-swops`,
      body,
      config
    );
    return data;
  }
);

export const removewishlistItem = createAsyncThunk(
  "swop/removewishlistItem",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      action: 0,
      posted_product_id: postData.productId,
      type: postData.type,
    };
    const { data } = await axios.post(
      API_URL +
        `user/wishlist-action
    `,
      body,
      config
    );
    return data;
  }
);

export const followaction = createAsyncThunk(
  "profile/followaction",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      action: postData.action,
      following_id: postData.following_id,
    };
    const { data } = await axios.post(
      API_URL +
        `user/follow-action
    `,
      body,
      config
    );
    return data;
  }
);

export const getWishlist = createAsyncThunk(
  "swop/getWishlist",
  async () => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      user_id: userData.id,
    };
    const { data } = await axios.post(
      API_URL +
        `user/wishlist`,
      body,
      config
    );
    return data;
  }
);

const swopSlice = createSlice({
  name: "swop",
  initialState: {
    isLoading: false,
    isError: false,
    pendingSwopDetails:{},
    completedSwopDetails:{},
    wishlistDetails:{}
  },
  reducers: {},

  extraReducers: (builder) => {
    
    builder.addCase(getPendingSwop.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPendingSwop.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pendingSwopDetails = action.payload.data;
      state.isError = false;
    });
    builder.addCase(getPendingSwop.rejected, (state) => {
      state.isLoading = false;
      state.pendingSwopDetails = {};
      state.isError = true;
    });

    builder.addCase(getCompletedSwop.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCompletedSwop.fulfilled, (state, action) => {
      state.isLoading = false;
      state.completedSwopDetails = action.payload.data;
      state.isError = false;
    });
    builder.addCase(getCompletedSwop.rejected, (state) => {
      state.isLoading = false;
      state.completedSwopDetails = {};
      state.isError = true;
    });

    builder.addCase(getWishlist.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWishlist.fulfilled, (state, action) => {
      state.isLoading = false;
      state.wishlistDetails = action.payload.data;
      state.isError = false;
    });
    builder.addCase(getWishlist.rejected, (state) => {
      state.isLoading = false;
      state.wishlistDetails = {};
      state.isError = true;
    });


    builder.addCase(removewishlistItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removewishlistItem.fulfilled, (state, action) => {
      state.isLoading = false;
      state.wishlistDetails = action.payload.data;
      state.isError = false;
    });
    builder.addCase(removewishlistItem.rejected, (state) => {
      state.isLoading = false;
      state.wishlistDetails = {};
      state.isError = true;
    });

  },
});

export default swopSlice.reducer;
