import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";

export const getBannerFeatures = createAsyncThunk(
  "homeBanner/getBannerFeatures",
  async () => {
    const config = {
      headers: {
        "Accept": "application/json",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
      },
    };
    let body;

    const { data } = await axios.post(
      `${API_URL}web-user/static-content`,
      body,
      config
    );

    return data;
  }
);

const homeBannerSlice = createSlice({
  name: "homeBanner",
  initialState: {
    isLoading: false,
    isError: false,
    features: [],
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getBannerFeatures.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBannerFeatures.fulfilled, (state, action) => {
      state.isLoading = false;
      state.features = action.payload.data;
      state.isError = false;
    });
    builder.addCase(getBannerFeatures.rejected, (state) => {
      state.isLoading = false;
      state.features = [];
      state.isError = true;
    });
  },
});

export default homeBannerSlice.reducer;
