import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import { getSignInResponse } from "../utils/authUtil";
import Swal from "sweetalert2";

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (slug, thunkAPI) => {
  // async (postData) => {
    let userData = getSignInResponse();
    
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        // "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData ? userData.id : 0,
      },

    };
    let body = {
      //   city_id: 1,
      //   root_category_id: postData.rootCategoryId,
      //   category_id: postData.categoryId,
      //   subcategory_id: postData.subCategoryId,
      //   swop_by_cash: postData.swopByCash,
      //   desired_swops: postData.desiredSwops,
      //   offer_type: postData.offerType,

      //   ...postData,
      profile_user_id: slug,
    };
    const { data } = await axios.post(
      API_URL +
        `web-user/get-profile`,
      body,
      config
    );
    return data;
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      first_name: postData.firstName,
      last_name: postData.lastName,
      email: postData.email,
      mobile: postData.mobile,
      mobile_prefix_code: postData.mobilePrefixCode,
      image: postData.image,
      about_me: postData.bio,
      facebook: postData.facebook,
      instagram: postData.instagram,
      linkedin: postData.linkedin,
    };
    const { data } = await axios.post(
      API_URL +
        `user/update-profile
    `,
      body,
      config
    );
    return data;
  }
);

export const updateProductStatus = createAsyncThunk(
  "profile/updateProductStatus",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      action: postData.status,
      posted_product_id: postData.productId,
    };
    const { data } = await axios.post(
      API_URL +
        `user/product-status-action
    `,
      body,
      config
    );
    return data;
  }
);

export const followaction = createAsyncThunk(
  "profile/followaction",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      action: postData.action,
      following_id: postData.following_id,
    };
    const { data } = await axios.post(
      API_URL +
        `user/follow-action
    `,
      body,
      config
    );
    return data;
  }
);

const userprofileSlice = createSlice({
  name: "userprofile",
  initialState: {
    isLoading: false,
    isError: false,
    profileInfo: {},
    updateProfileData: {},
    productActionMsg: "",
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.profileInfo = action.payload.data;
      state.isError = false;
    });
    builder.addCase(getProfile.rejected, (state) => {
      state.isLoading = false;
      state.profileInfo = {};
      state.isError = true;
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      action.payload.status === 200
        ? (state.updateProfileData = action.payload.data)
        : Swal.fire({
            title: "Alert",
            text: action.payload.message,
            icon: "error",
          });
      state.isError = false;
    });
    builder.addCase(updateProfile.rejected, (state) => {
      state.isLoading = false;
      state.updateProfileData = {};
      state.isError = true;
    });

    builder.addCase(updateProductStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProductStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      action.payload.status === 200
        ? (state.productActionMsg = "Success")
        : (state.productActionMsg = "");
      state.isError = false;
    });
    builder.addCase(updateProductStatus.rejected, (state) => {
      state.isLoading = false;
      state.productActionMsg = "";
      // state.updateProfileData = {};
      state.isError = true;
    });
  },
});

export default userprofileSlice.reducer;
