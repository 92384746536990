import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { verifyOtp } from "../../slices/signUp";
import $ from "jquery";
import Loading from "../include/Loading";

function OtpVerify() {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();

  const userName = useSelector((state) => state.signUp.signUpUserName);

  const userNameSignin = useSelector((state) => state.signIn.signUpUserName);

  const handleVerify = (params) => {
    console.log("userName", userName);
    console.log("userNameSignin", userNameSignin);
    let currentUsername = (userName) ? userName : userNameSignin;
    dispatch(verifyOtp({ userName: currentUsername, otp: otp }));
  };

  const userData = useSelector((state) => state.signUp.userData);

  useEffect(() => {
    if (userData && userData.access_token && userData.access_token.length > 0) {
      // $("#CreateAccount").removeClass("open-it");
      $("#OtpVerify").removeClass("open-it");
      localStorage.setItem("user_id", userData.id);
      localStorage.setItem("signInResponse", JSON.stringify(userData));
      setOtp("");
      window.location.reload();

      // $("body").addClass("hidden-scroll");
    }
  }, [userData]);
  const isLoading = useSelector((state) => state.signUp.isLoading);

  return (
    <div
      className="comman-model comman-side-popup forgot-password-model "
      id="OtpVerify"
    >
      <Loading loading={isLoading} />

      <div className="comman-popup-inner">
        <div className="top-model-header">
          <a
            href="javascript:void(0);"
            className="backarrow open-popup"
            data-popup-id="LogIn"
          >
            <i>
              <img src="../img/backarrow.svg" alt="" />
            </i>
          </a>
          <div className="pop-top-title">
            <h2>OTP Verification</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel">
            <i>
              <img src="../../img/close.png" alt="" />
            </i>
          </a>
        </div>
        <div className="popup-model-body">
          <form>
            {/* <div className="forgotimgpt">
              <img src="../../img/forgot.png" alt="" />
            </div> */}
            <div className="form-group">
              <label>Enter your OTP code here</label>
              <OtpInput
                containerStyle={{ justifyContent: "space-evenly" }}
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{ width: "5rem", height: "5rem" }}
              />
            </div>
            <div className="form-group btn_row">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleVerify()}
              >
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="shadowpt clearfix"></div>
    </div>
  );
}

export default OtpVerify;
