import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../utils/config";
import { followaction } from "../../slices/userprofileSlice";
import { useDispatch, useSelector } from "react-redux";

const Following = ({ profileInfo, popupType,follow }) => {
  const dispatch = useDispatch();
  const [followersList, setFollowersList] = useState([]);
  const [result, setResult] = useState(null);
 

  useEffect(() => {
    if (profileInfo) {
      // Check if profileInfo.followers or profileInfo.following is defined
      const followers =
        profileInfo[popupType === "Followers" ? "followers" : "following"];

      // Set the followersList state based on the selected type (Followers/Following)
      if (followers) {
        setFollowersList(followers);
      } else {
        setFollowersList([]); // Set an empty array if followers is not defined
      }
    }
  }, [profileInfo, popupType]);

 

  const handleFollowClick = async (e, action, following_id) => {
      // Call the follow function
      if(localStorage.getItem("signInResponse") != null){
      await follow(e, action, following_id);
      }
      // Additional logic if needed
    };
  

  return (
    <div
      className="comman-model comman-side-popup choose-any-model"
      id="following"
    >
      <div className="comman-popup-inner">
        <div className="top-model-header">
          <div className="pop-top-title">
            <h2>{popupType === "Followers" ? "Followers" : "Following"}</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel">
            <i>
              <img src="../img/close.png" alt="" />
            </i>
          </a>
        </div>
        <div className="popup-model-body">
          <ul className="following-panel">
            {followersList &&
              followersList.length > 0 &&
              followersList.map((user, index) => (
                <li key={popupType === "Followers"? user.follower_id :user.following_id}>
                  <div className="follow-box-panel d-flex">
                    <a href={user.user_slug} target="_blank">
                    <div className="../imgbxpt">
                    {user.image ?
                      <img src={`${API_BASE_URL}${user.image}`} alt="swop" />
                      :<img src="../../img/dp.jpg" alt="swop" style={{height:'20px'}} />}
                    </div>
                    <div className="follow-content">
                      <h2>{user.name}</h2>
                      <p>{user.listing_count} Listing</p>
                    </div>
                    </a>
                    <div className="follow-right-btn">
                     <a href="#" className="btn btn-primary">
                     {user.is_following ?
                      popupType === "Followers"? <a href="#" onClick={(e) => handleFollowClick(e, 'unfollow', user.follower_id)} className="btn btn-primary" >UnFollow</a>
                      : <a href="#" onClick={(e) => handleFollowClick(e, 'unfollow', user.following_id)} className="btn btn-primary" >UnFollow</a>
                      :
                      popupType === "Followers"? <a href="#" onClick={(e) => handleFollowClick(e, 'follow', user.follower_id)} className="btn btn-primary" >Follow</a>
                      : <a href="#" onClick={(e) => handleFollowClick(e, 'follow', user.following_id)} className="btn btn-primary" >Follow</a>
                    }
                      </a> 
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          {/* <ul className="following-panel">
            <li>
              <div className="follow-box-panel d-flex">
                <div className="../imgbxpt">
                  <img src="../img/follow01.png" alt="swop" />
                </div>
                <div className="follow-content">
                  <h2>Vipin Sharma</h2>
                  <p> 10+ Listing</p>
                </div>
                <div className="follow-right-btn">
                  <a href="#" className="btn btn-primary">
                    Follow
                  </a>
                </div>
              </div>
            </li>

            <li>
              <div className="follow-box-panel d-flex">
                <div className="../imgbxpt">
                  <img src="../img/follow03.png" alt="swop" />
                </div>
                <div className="follow-content">
                  <h2>Riya Choudhary</h2>
                  <p> 10+ Listing</p>
                </div>
                <div className="follow-right-btn">
                  <a href="#" className="btn btn-primary">
                    Follow
                  </a>
                </div>
              </div>
            </li>

            <li>
              <div className="follow-box-panel d-flex">
                <div className="../imgbxpt">
                  <img src="../img/follow02.png" alt="swop" />
                </div>
                <div className="follow-content">
                  <h2>Samuel Romero</h2>
                  <p> 10+ Listing</p>
                </div>
                <div className="follow-right-btn">
                  <a href="#" className="btn btn-primary">
                    Follow
                  </a>
                </div>
              </div>
            </li>

            <li>
              <div className="follow-box-panel d-flex">
                <div className="../imgbxpt">
                  <img src="../img/follow06.png" alt="swop" />
                </div>
                <div className="follow-content">
                  <h2>Alexandre Milevski</h2>
                  <p> 10+ Listing</p>
                </div>
                <div className="follow-right-btn">
                  <a href="#" className="btn btn-primary">
                    Follow
                  </a>
                </div>
              </div>
            </li>

            <li>
              <div className="follow-box-panel d-flex">
                <div className="../imgbxpt">
                  <img src="../img/follow07.png" alt="swop" />
                </div>
                <div className="follow-content">
                  <h2>George Brooks</h2>
                  <p> 10+ Listing</p>
                </div>
                <div className="follow-right-btn">
                  <a href="#" className="btn btn-primary">
                    Follow
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div className="follow-box-panel d-flex">
                <div className="../imgbxpt">
                  <img src="../img/follow08.png" alt="swop" />
                </div>
                <div className="follow-content">
                  <h2>Yasmine Horvat</h2>
                  <p> 10+ Listing</p>
                </div>
                <div className="follow-right-btn">
                  <a href="#" className="btn btn-primary">
                    Follow
                  </a>
                </div>
              </div>
            </li>
          </ul> */}
        </div>
      </div>
      <div className="shadowpt clearfix"></div>
    </div>
  );
};

export default Following;
