import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import { getSignInResponse } from "../utils/authUtil";
import Swal from "sweetalert2";

export const changePassword = createAsyncThunk(
  "changePass/changePassword",
  async (postData) => {
    // console.log("🚀 ~ file: changePasswordSlice.js:9 ~ postData:", postData);
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      old_password: postData.oldPassword,
      new_password: postData.newPassword,
    };
    const { data } = await axios.post(
      API_URL +
        `user/change-password
    `,
      body,
      config
    );
    return data;
  }
);

const changePasswordSlice = createSlice({
  name: "changePass",
  initialState: {
    isLoading: false,
    isError: false,
    message: "",
    status: 0,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(changePassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;

      state.isError = false;
    });
    builder.addCase(changePassword.rejected, (state) => {
      state.isLoading = false;
      state.message = "";
      state.isError = true;
    });
  },
});

export default changePasswordSlice.reducer;
