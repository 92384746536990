import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFaqdatas } from "../../slices/homeSlice";

const Faq = () => {
  // State to manage the active accordion item
  const [activeAccordion, setActiveAccordion] = useState(0); // Set initial state to 0
  const dispatch = useDispatch();

  // Function to toggle the active accordion item
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    dispatch(getFaqdatas());
  }, []);

  const faqDatas = useSelector((state) => state.home.faqDatas);
  
  return (
    <main className="sa-main-wrap sa-trems-pages-wrap">
      <div className="container">
        <div className="breadcrumpart">
          <ul>
            <li>
              <a href="#">Home</a>
            </li>
            <li>Faq</li>
          </ul>
        </div>
        <section className="faq-section-wrap">
          <div className="row faqsrow">
            <div className="col-lg-12">
              <h2 className="title"> Frequently Asked Questions</h2>
              {faqDatas.length === 0 ? (
                <div>Loading...</div>
              ) : (
                <div id="accordion" className="accordion-container">
                  {faqDatas.map((item, index) => (
                    <div className="outer-accordion-box" key={index}>
                      <h4
                        className={`accordion-title js-accordion-title ${
                          activeAccordion === index ? "active" : ""
                        }`}
                        onClick={() => toggleAccordion(index)}
                      >
                        {item.question}
                      </h4>
                      <div
                        className="accordion-content"
                        style={{
                          display:
                            activeAccordion === index ? "block" : "none",
                        }}
                      >
                        <p>{item.answer}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Faq;
