import React from "react";
import "../../css/Loading.css";

const Loading = ({ loading }) => {
  return loading ? (
    <div className="loader_all_pages" id="loaderAllPages">
                 {" "}
      <div id="circularG">
                        <div id="circularG_1" className="circularG"></div>     
                  <div id="circularG_2" className="circularG"></div>           
            <div id="circularG_3" className="circularG"></div>               {" "}
        <div id="circularG_4" className="circularG"></div>               {" "}
        <div id="circularG_5" className="circularG"></div>               {" "}
        <div id="circularG_6" className="circularG"></div>               {" "}
        <div id="circularG_7" className="circularG"></div>               {" "}
        <div id="circularG_8" className="circularG"></div>           {" "}
      </div>
             {" "}
    </div>
  ) : null;
};

export default Loading;
