import { Outlet, Navigate } from "react-router-dom";
import { isAuthenticated } from "../utils/authUtil";

const PrivateRoutes = () => {
  const loggedIn = isAuthenticated();

  return loggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
