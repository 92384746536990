import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSignIn } from "../../slices/signIn";
import Loading from "../include/Loading";
import Swal from 'sweetalert2';
import $ from "jquery";

function SignIn() {
  const dispatch = useDispatch();

  const [user, setUser] = useState({
    userName: "",
    password: "",
    userError:""
  });

  const signIn = useSelector((state) => state.signIn.userInfo);

  useEffect(() => {
    if(Object.keys(signIn).length){
      if(signIn && signIn.data && signIn.data.id){
        // localStorage.setItem("user_id",signIn.data.id);
        // localStorage.setItem("signInResponse", JSON.stringify(signIn.data));
        // window.location.reload();
        $("#LogIn").removeClass("open-it");
        $("#OtpVerify").addClass("open-it");
      } else {
        console.log('rammm===>2222222', signIn.is_login);
        // setUser({ userName: user.userName, password: user.password, userError:signIn.message });
        if(signIn.is_login === 'No'){
          $("#LogIn").removeClass("open-it");
          $("#CreateAccount").addClass("open-it");
        } else {
        Swal.fire({  
          title: 'Alert',  
          text: signIn.message,
          icon: 'error'
        }); 
      }
    }
  }
    // if (signIn && signIn.access_token) {
    //   // console.log("🚀 ~ file: SignIn.js:13 ~ SignIn ~ signIn:", signIn);
    //   localStorage.setItem("signInResponse", JSON.stringify(signIn));
    // }
  }, [signIn]);

  const handleSubmit = async () => {
    if (user.userName.length > 0) {
      await dispatch(getSignIn(user));
      // console.log("push");
      // setUser({ userName: "", password: "", userError:"" });
      // window.location.reload();
    } 
    else {
      Swal.fire({  
        title: 'Alert',  
        text: "Email and password are required!",
        icon: 'error'
      });
        // setUser({ userName: user.userName, password: user.password, userError:"Email and password are required!" });
    }
  };

  const isLoading = useSelector((state) => state.signIn.isLoading);
  const resetstate =() =>{
 setUser({ userName: ''});
  }
  return (
    <div className="comman-model comman-side-popup log-in-model" id="LogIn">
      <Loading loading={isLoading} />

      <div className="comman-popup-inner">
        <div className="top-model-header">
          <a href="javascript:void(0);" className="close_panel">
            <i>
              <img src="../../img/close.png" alt=""  onClick={() => resetstate()} />
            </i>
          </a>
        </div>
        <div className="popup-model-body">
          <div className="top-logo-title">
            <div className="side-model-logo">
              <img src="../../img/logo-splash.png" alt="logo" />
            </div>
            <h2>Login/Signup</h2>
            {/* <span className="startselling">Start Selling</span> */}
          </div>
          {user.userError && (
            <div className="alert alert-danger" role="alert">
            {user.userError}
          </div>
          )}
          
          <form>
            <div className="form-group">
              {/* <label>Email Address Or Mobile Number</label> */}
              <input
                required
                type="email"
                className="form-control"
                placeholder="Enter Your Mobile or Email "
                value={user.userName}
                onChange={(e) =>
                  setUser((prevState) => ({
                    ...prevState,
                    userName: e.target.value,
                  }))
                }
              />
            </div>
            {/* <div className="form-group forgot_pass_group">
              <label>Password</label>
              <div className="passwordpart">
                <input
                  required
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={user.password}
                  onChange={(e) =>
                    setUser((prevState) => ({
                      ...prevState,
                      password: e.target.value,
                    }))
                  }
                />
                <a
                  // href="#"
                  className="forgot_pass open-popup"
                  data-popup-id="ForgotPassword"
                  id="forgotpasswordIdbtn"
                >
                  Forgot ?
                </a>
              </div>
            </div> */}
            <div className="form-group btn_row">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </div>
            {/* <span className="register-web">
              Create on Account ?{" "}
              <a
                // href="javascript:void(0);"
                className="open-popup"
                data-popup-id="CreateAccount"
              >
                Register
              </a>
            </span> */}
            {/* <div className="web-social-icon">
              <span className="continue">Or Continue With</span>
              <ul>
                <li>
                  <a href="javascript:void(0);" className="fbicon">
                    <img alt="" src="../img/fb2.png" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" className="gpicon">
                    <img alt="" src="../img/googlepls.png" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" className="appleico">
                    <img alt="" src="../img/apple.png" />
                  </a>
                </li>
              </ul>
            </div> */}
          </form>
        </div>
      </div>
      <div className="shadowpt clearfix"></div>
    </div>
  );
}

export default SignIn;
