import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getpage } from "../../slices/contactUsSlice";

const Content = () => {
  const dispatch = useDispatch();
  const { page_type } = useParams();
  const [pageContent, setPageContent] = useState(null); 
  const [pageTitle, setPageTitle] = useState(null);
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page_type]); 

  useEffect(() => {
    dispatch(getpage(page_type))
      .then((response) => {
        setPageContent(response.payload.data.page_description);
        setPageTitle(response.payload.data.page_title);
      })
      .catch((error) => {
        // Handle error if needed
      });
  }, [dispatch, page_type]);

  return (
    <>
      <main className="sa-main-wrap sa-trems-pages-wrap">
        <div className="container">
          <div className="breadcrumpart">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>{pageTitle}</li>
            </ul>
          </div>
        </div>
        <div className="container">
          <section className="sa-trems-content">
            {pageContent && (
              <>
                <p>
                  <strong>
                    <span dir="LTR">
                      <strong>{pageTitle}</strong>
                    </span>
                  </strong>
                </p>
                <div dangerouslySetInnerHTML={{ __html: pageContent }} /> 
              </>
            )}
          </section>
        </div>
      </main>
    </>
  );
};

export default Content;
