import React from "react";

const Chooseanyone = () => {
  
  return (
    <div className="comman-model comman-side-popup choose-any-model" id="chooseanyone">
    <div className="comman-popup-inner">
       <div className="top-model-header">
          <div className="pop-top-title">
             <h2>Choose Any one</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel"><i><img src="../img/close.png" alt=""/></i></a>
       </div>
       <div className="popup-model-body"> 
<ul className="choose-any-cards">
<li><div className="sa-list-box">  

    <div className="sa-list-box__image">
       <img src="../img/list01.png" alt="Swop" />
       
    </div>
    <div className="sa-list-box__content">
       <div className="sa-list-box__content__title">Xiaomi-Mi-Mix-Black 8GB-128GB</div>
    </div> 
</div></li>
<li><div className="sa-list-box">  

 <div className="sa-list-box__image">
    <img src="../img/list02.png" alt="Swop" />
    
 </div>
 <div className="sa-list-box__content">
    <div className="sa-list-box__content__title">Xiaomi-Mi-Mix-Black 8GB-128GB</div>
 </div> 
</div></li>
<li><div className="sa-list-box">  

<div className="sa-list-box__image">
 <img src="../img/list03.png" alt="Swop" />
 
</div>
<div className="sa-list-box__content">
 <div className="sa-list-box__content__title">Xiaomi-Mi-Mix-Black 8GB-128GB</div>
</div> 
</div></li>
</ul>
           
       </div>
       <div className="model-footer-panel"> <button className="btn btn-primary">Suggest this swop</button></div>
    </div>
    <div className="shadowpt clearfix"></div>
 </div>
  );
};

export default Chooseanyone;
