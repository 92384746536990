import React, { useRef, useEffect, useState } from 'react';
import Slider from "react-slick";
import { API_BASE_URL } from "../../utils/config";
import { useSelector } from "react-redux";
import $ from 'jquery';

function HowWorksSld() {
  const swop_ideas = useSelector((state) => state.home.dashboard.swop_ideas);

  const settings = {
    // infinite: true,
    centerMode: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // Adjust the breakpoint for tablets if needed
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768, // Adjust the breakpoint for smaller tablets if needed
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    setTimeout(() => {
      $('.slick-prev').trigger('click');
    }, 2000);
  }, []);

  return (
    <>
      <ul className="sa-about-slider">
        <Slider {...settings}>
          {swop_ideas &&
            swop_ideas.map((about, index) => (
              <div className="home-item" key={index}>
                <li>
                  <div className="about-item-inner">
                    <img
                      className="lozad"
                      alt="swop"
                      data-use-lozad="true"
                      data-src={about.image}
                      src={`${API_BASE_URL}${about.image}`}
                      data-loaded="true"
                    />
                  </div>
                </li>
              </div>
            ))}
        </Slider>
      </ul>
    </>
  );
}

export default HowWorksSld;
