import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import { getSignInResponse } from "../utils/authUtil";
import Swal from "sweetalert2";

export const contactUs = createAsyncThunk(
  "contactUs/contactUs",
  async (postData) => {
    // console.log("🚀 ~ file: changePasswordSlice.js:9 ~ postData:", postData);
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
   
      },
    };
    let body = {
      name: postData.name,
      email: postData.email,
      description : postData.description,
    };
    const { data } = await axios.post(
      API_URL +
        `web-user/add-feedback`,
      body,
      config
    );
    return data;
  }
);

export const getpage = createAsyncThunk(
  "contactUs/getpage",
  async (postData) => {
    console.log("ile: changePasswordSlice.js:9 ~ postData:", postData);
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
   
      },
    };
    let body = {
      page: postData,
     
    };
    const { data } = await axios.post(
      API_URL +
        `web-user/get-page`,
      body,
      config
    );
    return data;
  }
);

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState: {
    isLoading: false,
    isError: false,
    message: "",
    status: 0,
    pagesinfo :{}
  },
  
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(contactUs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(contactUs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;

      state.isError = false;
    });
    builder.addCase(contactUs.rejected, (state) => {
      state.isLoading = false;
      state.message = "";
      state.isError = true;
    });

    builder.addCase(getpage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getpage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pagesinfo = action.payload.data;

      state.isError = false;
    });
    builder.addCase(getpage.rejected, (state) => {
      state.isLoading = false;
      state.pagesinfo = {};

      state.isError = true;
    });
  },
});

export default contactUsSlice.reducer;
