import React, { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import Profilemenu from "./Profilemenu";
import { getChathistory,sendMessage,getSwpdetail,deleteChannels,blockUsers,addReport,deleteMessages,getActiveProduct,suggestSwopAction,followaction } from "../../slices/chatSlice";
import Chooseanyone from "../popups/Chooseanyone";
import Loading from "../include/Loading";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../../slices/profileSlice";
import Swal from 'sweetalert2';
import { API_BASE_URL } from "../../utils/config";
import { SocketProvider } from "socket.io-react";
import io from "socket.io-client";
import { getSignInResponse } from "../../utils/authUtil";

const ChatConversion = () => {
  const socket = io("https://sellanything247.com/");
  const { user_id } = localStorage.getItem('user_id'); // Assuming you store user_id in auth state
  const profileInfo = useSelector((state) => state.profile.profileInfo);
  const { chatUserinfo, chathistory,SwopDetail,messageReplies } = useSelector((state) => state.chat);
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chatStart, setChatStart] = useState(false);
  const [offer_type, setOfferType] = useState(null);
  const [left_item, setLeftItem] = useState(null);
const [right_item, setRightItem] = useState(null);
const [toUserDeatils, settoUserDeatils ] = useState([]);
const [selected_report_reason, setSelectedreportreason] = useState('');
const [reportModalOpened,setreportModalOpened] = useState(false);
const [suggestProducts,setsuggestProducts] = useState([]);
const [Swopdeatilid,setSwopdeatilid] = useState('');
const [productlist, setProductList] = useState([]);
const [suggestModalProductId, setsuggestModalProductId] = useState(0);
const [swopresult, setSwopResult] = useState(null);
const [blockresult, setBlockResult] = useState(null);
const [followresult, setFollowResult] = useState(null);

   

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
 
  // useEffect(() => {
  //   dispatch(getChathistory({ swop_id: id }));
  //   dispatch(getSwpdetail({ swop_id: id }));
  // }, [dispatch]);

  const userData = getSignInResponse();
  useEffect(() => {
    // Connect to the socket server when the component mounts
    socket.on('connect', () => {
      console.log('Socket connected');
      let data = { userId: userData.id };
      
      // Join the room or emit necessary events
      socket.emit('join', data);

      // Handle incoming messages
      socket.on('get-message', (dataObj) => {
        if (typeof displayMessage === 'function') {
         // setMessages((prevMessages) => [...prevMessages, dataObj]);
          // Optionally play a notification sound or handle the message display
          // playNotificationSound();
          // displayMessage(dataObj, 0);
        }
      });

      // Handle disconnect event
      socket.on('disconnect', () => {
        socket.emit('leave', data);
        console.log('Socket disconnected');
      });
    });

    // Handle connection errors
    socket.on('connect_error', (err) => {
      console.log('Socket connect_error');
      console.error(err);
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.off('connect');
      socket.off('get-message');
      socket.off('disconnect');
      socket.off('connect_error');
      socket.disconnect();
    };
  }, [socket, userData]);


  useEffect(() => {
    
    dispatch(getChathistory({ swop_id: id }));
    dispatch(getSwpdetail({ swop_id: id }));
    dispatch(getProfile());
    // Setup interval to fetch chat history every 10 seconds
    const intervalId = setInterval(() => {
      dispatch(getChathistory({ swop_id: id }));
    }, 10000);

    // Clean up function
    return () => clearInterval(intervalId);
  }, [dispatch, id]);

  useEffect(() => {
    
    if(chathistory){
      settoUserDeatils(chatUserinfo);
    }
 }, [chathistory]);

  useEffect(() => {
    if(SwopDetail &&SwopDetail.to_item){
    setOfferType(SwopDetail.to_item.offer_type);
    setSwopdeatilid(SwopDetail.to_item.posted_product_id);
    }
 }, [SwopDetail]);

  const addDefaultSrc = (ev) => {
    ev.target.src = '/img/profile-img.png';
  };

  const sendMessageByClick = (e) => {
   e.preventDefault();
   sendMessageAction();
 };

 const sendMessageAction = (type = "TEXT", displayLocally = 1) => {
   const message = document.querySelector('textarea[name="message"]').value;
   
   if (type != "TEXT") {
     message = type;
   }

   if (message) {
     sendMessageProcess(message, type, displayLocally);
   }
 };



 const sendMessageProcess = async (message, custom_type = "TEXT", displayLocally = 1) => {
   setIsLoading(true);
   try {
       const result = await dispatch(
           sendMessage({
               swop_id: id,  // Check if 'id' is correctly defined
               from: user_id,  // Check if 'user_id' is correctly defined
               to: chatUserinfo.userid,  // Check if 'chatUserinfo' is correctly defined and contains 'userid'
               message_reply: "",
               message: message,
               message_type: custom_type,
               timezone: 'Africa/Gaborone',
           })
       );
       setResult(result);
       console.log('API Response:', result);

       // Check if the API call was successful
       if (result.payload && result.payload.status === 200) {
           // Show success message
           //Swal.fire("Success!", result.payload.message, "success");
       } else {
           // Show error message
          // Swal.fire("Alert!", result.payload.message, "error");
       }
   } catch (error) {
       // Handle any errors
       console.log('Error adding item to wishlist:', error);
       // Alert user about the error
      
   } finally {
       setIsLoading(false); // Set loading state back to false after API call completes
   }
};

const messageReply = async (message_id, reply = "1") => {
  setIsLoading(true);
  try {
    const result = await dispatch(
      messageReplies({
        message_id: message_id,
        reply: reply,
        })
    );
    setResult(result);
    console.log('API Response:', result);

    // Check if the API call was successful
    if (result.payload && result.payload.status === 200) {
        // Show success message
        //Swal.fire("Success!", result.payload.message, "success");
    } else {
        // Show error message
       // Swal.fire("Alert!", result.payload.message, "error");
    }
} catch (error) {
    // Handle any errors
    console.log('Error adding item to wishlist:', error);
    // Alert user about the error
   
} finally {
    setIsLoading(false); // Set loading state back to false after API call completes
}
}


const deleteChannel = async () => {
  setIsLoading(true);
  try {
    const result = await dispatch(
      deleteChannels({
        swop_id: SwopDetail.swop_id,
       })
    );
   // setResult(result);
    console.log('API Response:', result);

    // Check if the API call was successful
    if (result.payload && result.payload.status === 200) {
        // Show success message
        Swal.fire("Success!", result.payload.message, "success");
        navigate("/chat-history");
    } else {
        // Show error message
        Swal.fire("Alert!", result.payload.message, "error");
    }
} catch (error) {
    // Handle any errors
    console.log('Error adding item to wishlist:', error);
    // Alert user about the error
   
} finally {
    setIsLoading(false); // Set loading state back to false after API call completes
}
}


const deleteUsermsg = async (message_id) => {
  setIsLoading(true);
  try {
    const result = await dispatch(
      deleteMessages({
        message_id: message_id,
       })
    );
    setResult(result);
   

    // Check if the API call was successful
    if (result.payload && result.payload.status === 200) {
        // Show success message
       // Swal.fire("Success!", result.payload.message, "success");
       // navigate("/chat-history");
    } else {
        // Show error message
       // Swal.fire("Alert!", result.payload.message, "error");
    }
} catch (error) {
    // Handle any errors
    console.log('Error adding item to wishlist:', error);
    // Alert user about the error
   
} finally {
    setIsLoading(false); // Set loading state back to false after API call completes
}
}

const blockUser = async () => {

  try {
    const result = await dispatch(
      blockUsers({
        to_user_id: chatUserinfo.userid,
       })
    );
    setBlockResult(result);
    console.log('API Response:', result);

    // Check if the API call was successful
    if (result.payload && result.payload.status === 200) {
        // Show success message
        Swal.fire("Success!", result.payload.message, "success");
    } else {
        // Show error message
        Swal.fire("Alert!", result.payload.message, "error");
    }
} catch (error) {
    // Handle any errors
    console.log('Error adding item to wishlist:', error);
    // Alert user about the error
   
} finally {
    setIsLoading(false); // Set loading state back to false after API call completes
}
}

const getActiveProducts = async (type = "my") => {
 let profile_user_id;
  if (type != "my"){
     profile_user_id = toUserDeatils.userid;
  } else {
      profile_user_id = localStorage.getItem('user_id');
  }
  

  try {
    const result = await dispatch(
      getActiveProduct({
        profile_user_id: profile_user_id,
       })
    );
    
    console.log('API Response:', result);

    // Check if the API call was successful
    if (result.payload && result.payload.status === 200) {
        // Show success message
        setsuggestProducts(result.payload.data);
       // Swal.fire("Success!", result.payload.message, "success");
       // navigate("/chat-history");
    } else {
        // Show error message
       // Swal.fire("Alert!", result.payload.message, "error");
    }
} catch (error) {
    // Handle any errors
    console.log('Error adding item to wishlist:', error);
    // Alert user about the error
   
} finally {
    setIsLoading(false); // Set loading state back to false after API call completes
}
}

const openSuggestModal = (e, type = "my") => {
  e.preventDefault();
 
   getActiveProducts(type);
 
};


const suggestSwop = async (e) => {
  try {


  if (!suggestModalProductId) {
  
    Swal.fire({
      title: "Alert",
      text: "Please select an item to swop.",
      icon: "error",
    });
    return;
  }

    const result = await dispatch(suggestSwopAction({
      swop_id: id,
      to_posted_product_id: Swopdeatilid,
      from_posted_product_id: suggestModalProductId ? suggestModalProductId : "",
    }));
    
    
    setSwopResult(result);
    // Check if the API call was successful
    if (result.payload && result.payload.status === 200) { 
          sendMessageProcess(
        "",
        "SUGGEST",
        result.payload.data.swop_id,
        result.payload.data.from_user_id,
        result.payload.data.to_user_id
      );
      document.getElementById('chooseanyone').classList.remove('open-it');
      document.body.classList.remove('hidden-scroll');
         // Show success message
       // Swal.fire("Success!", result.payload.message, "success");
    } else {
     // Swal.fire("Alert!", result.payload.message, "error");
     
    }
  } catch (error) {
    // Handle any errors
    console.log('ddddddddd:', error);
    //alert('An error occurred while adding item to wishlist.');
  }

 
};

// Function to handle pressing Enter key
const handleKeyPress = (e) => {
   if (e.key === 'Enter') {
       e.preventDefault();
       sendMessageAction();
       document.querySelector('textarea[name="message"]').value = ''; // Clear the textarea after sending
   }
};

const fileInputRef = useRef(null);


const sendFile = async (e) => {
  try {
    // Access files from the event
    const files = e.target.files;

    // Check if there are selected files
    if (files.length === 0) {
      console.log('No files selected');
      return; // Exit the function if no files are selected
    }

   
    let postData = {
      swop_id: id,
      from: localStorage.getItem('user_id'),
      to: chatUserinfo.userid,
      // message: message,
      message_type: "FILE",
      timezone: 'Africa/Gaborone',
    };
    // const promises = [...e.target.files].map((file, i) => {
    //   postData["attachment"] = file;
    // });
    // Append each file with a unique key
    for (let i = 0; i < files.length; i++) {
      postData["attachment"] = files[i];
    }

    // Log selected files
    console.log('Selected files:', files);

    // Log FormData before sending
    console.log('FormData:', postData);

    const result = await dispatch(sendMessage(postData));

    console.log('API Response:', result);
    setResult(result);

    // Check if the API call was successful
    if (result.payload && result.payload.status === 200) {
   
    } else {
      // Show error message
      // Swal.fire("Alert!", result.payload.message, "error");
    }
  } catch (error) {
    // Handle any errors
    console.log('Error sending file:', error);
    // Alert user about the error
  } finally {
    setIsLoading(false); // Set loading state back to false after API call completes
  }
};


useEffect(() => {
  if (blockresult && blockresult.payload && blockresult.payload.status === 200) {
     dispatch(getChathistory({ swop_id: id }));
  }
}, [blockresult]);


useEffect(() => {
   if (result && result.payload && result.payload.status === 200) {
      document.querySelector('textarea[name="message"]').value = '';
      dispatch(getChathistory({ swop_id: id }));
   }
 }, [result]);

 useEffect(() => {
  if (swopresult && swopresult.payload && swopresult.payload.status === 200) {
    
     dispatch(getSwpdetail({ swop_id: id }));
  }
}, [swopresult]);

 useEffect(() => {
  if (SwopDetail && Object.keys(SwopDetail).length > 0) {
    let left = SwopDetail.to_item;
    let right = SwopDetail.from_item;

    if (SwopDetail.my_key === "to_item") {
      left = SwopDetail.from_item;
      right = SwopDetail.to_item;
    }

    setLeftItem(left);
    setRightItem(right);
  }
}, [SwopDetail]);
 
const getImageUrl =(img = "")=> {
  return img.startsWith("http://") || img.startsWith("https://")
    ? img
    : API_BASE_URL + img;
}

const openReportModal = () => {
  //e.preventDefault();

  setSelectedreportreason('');
 
  
   
};

const reportSwop = async (e) => {
  if (selected_report_reason == "") {
    Swal.fire({
      title: "Alert",
      text: "Please select a reason.",
      icon: "error",
    });
   return false;
  }


  setreportModalOpened(false );
  try {
    
    const result = await dispatch(addReport({
      reason: selected_report_reason,
      to_user_id: chatUserinfo.userid,
  
    }));
   // setResult(result);
    // console.log('API Response:', result);

    // Check if the API call was successful
    if (result.payload && result.payload.status === 200) { 
         // Show success message
        Swal.fire("Success!", result.payload.message, "success");
       
        document.getElementById('chooseanyonereport').classList.remove('open-it');
        document.body.classList.remove('hidden-scroll');
    } else {
      Swal.fire("Alert!", result.payload.message, "error");
     
    }
  } catch (error) {
    // Handle any errors
    // console.log('Error adding item to wishlist:', error);
    alert('An error occurred while adding item to wishlist.');
  }
};

useEffect(() => {
  // Check if there's an accepted message in the chat history
  const hasAcceptedMessage = chathistory.some(
    (chatItem) => chatItem.message_type === "ACCEPTED"
  );

  if (hasAcceptedMessage) {
    // If an accepted message is found, set chatStart to true
    setChatStart(true);
  }
}, [chathistory]); // R

const follow = async (e, action, following_id) => {
  console.log('Data to dispatch:', { action, following_id });
  try {
    
    const result = await dispatch(followaction({
      action: action,
      following_id: following_id,
    }));
    setFollowResult(result);
    console.log('API Response:', result);

    // Check if the API call was successful
    if (result.payload && result.payload.status === 200) { 
         // Show success message
         Swal.fire(result.payload.message);
    } else {
      Swal.fire(result.payload.message);
     
    }
  } catch (error) {
    // Handle any errors
    console.log('Error adding item to wishlist:', error);
    alert('An error occurred while adding item to wishlist.');
  }
};

useEffect(() => {
  if (followresult && followresult.payload && followresult.payload.status === 200) {
     
     dispatch(getChathistory({ swop_id: id }));
     dispatch(getProfile());
  }
}, [followresult]);

  return (
   
    <>
    <SocketProvider socket={socket}>
      <main className="sa-main-wrap sa-inner-pages-wrap">
        <section className="sa-profile-wrap">
        <Loading loading={!chathistory || !SwopDetail} />
          <div className="container">
            <div className="row">
            <Profilemenu profileInfo={profileInfo} follow={follow}/>
              <div className="col-lg-9">
                <div className="sa-inner-right-wrap chatboxpanel">
                  <div className="sa-top-heading">
                    <div className="backflow">
                      <Link to={'/chat-history'} className="backbtnhead"><span className="icon-02"></span></Link>
                      <Link to={'/profile/'+chatUserinfo.slug} target="_blank">
                        <div className="chatimg">
                        <img
                         
                          onError={addDefaultSrc}
                          src={chatUserinfo.image}
                          alt={chatUserinfo.name}
                        />
                        <h2>{chatUserinfo.name ? chatUserinfo.name :''}</h2>
                       
                      </div>
                      </Link>
                      <div className="chatmenuspt sa-lst-clk dropdown-parents ml-auto">
                        <i className="icon-20"></i>
                        <div className="dropdown-sm-menu">
                          <ul>
                            <li
                              onClick={() => {
                                if (window.confirm("Are you sure?")) {
                                  deleteChannel();
                                }
                              }}
                            >
                              <a href="javascript:void(0);">
                                <span>Delete Channel</span>
                              </a>
                            </li>
                            <li
                             onClick={() => {
                              if (window.confirm("Are you sure?")) {
                                blockUser();
                              }
                            }} 
                            >
                              <a href="javascript:void(0);">
                                <span>Block User</span>
                              </a>
                            </li>
                            <li 
                            className="open-popup"
                            data-popup-id="chooseanyonereport" onClick={openReportModal} 
                            >
                              <a href="#">
                                <span>Report User</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {offer_type !== 2 && ( 
                  <div className="addsigneaturebar">
                    <div className="chatlistrow">
                    {left_item && left_item.posted_product_id !== undefined ? (
                     <> <div className="chatting-left">
                        <div className="chatimg">
                        <img src={left_item.image} />
                        </div>
                        <div className="chatcontent">
                          <h2 className="chattitle">{left_item.title}</h2>
                        </div>
                      </div>
                    
                      <div className="compare_icon">
                        <img src="../img/compare_icon.png" alt="" />
                      </div></>
                         ) : (
                      <div className="chatting-left">
                        <div className="addsuggest" >
                          <span>+ Add your<br/> suggestion here</span>
                        </div> *
                        <div className="compare_icon">
                        <img src="../img/compare_icon.png" alt="" />
                      </div>
                      </div>
                      )}
                        {right_item != undefined &&
                  right_item.posted_product_id != undefined ? (
                     <> <div className="chatting-right">
                        <div className="chatimg">
                          <img src={right_item.image} alt=""  style={{height:'30%',width:'30%'}} />
                        </div>
                        <div className="chatcontent">
                          <h2 className="chattitle"> {right_item.title}   </h2>
                        </div>
                      </div>
{/*                     
                      <div className="compare_icon">
                        <img src="../img/compare_icon.png" alt="" />
                      </div> */}
                      </>
                         ) : (
                      <div className="chatting-right">
                        <div className="addsuggest open-popup" data-popup-id="chooseanyone" onClick={(e) => openSuggestModal(e, "my")}>
                          <span>+ Add your<br/> suggestion here</span>
                        </div> *
                      
                      </div>
                      )}
                    </div>
                  </div>
                  )}
                  <div className="sa-inner-body">
                    <div className="chat-history-panel">
                    <ul>
  {Array.isArray(chathistory) && chathistory.map((chatItem, index) => {
    const isMine = chatItem.from_user_id === localStorage.getItem('user_id');

    if (chatItem.message_type === "TEXT") {
      return (
        isMine ? (
          <li
            className="rightside"
            key={index}
            onClick={(e)=>deleteUsermsg(chatItem.message_id)}
          >
            <div className="chatmsgdatetime">
              <span className="sa-data-time">{chatItem.created_at_locale}</span>
            </div>
            <div className="chatothermsg">
              {chatItem.message}
            </div>
          </li>
        ) : (
          <li className="chatothermsg" key={index}>
            <div className="chatmsgdatetime">
              <span className="sa-data-time">{chatItem.created_at_locale}</span>
            </div>
            <div className="chatothermsg">
              {chatItem.message}
            </div>
          </li>
        )
      );
      
    } else if (chatItem.message_type === "FILE" || chatItem.message_type === "IMAGE") {
      return (
        isMine ? (
          <li
            className="rightside"
            key={index}
            onClick={(e)=>deleteUsermsg(chatItem.message_id)}
          >
            <div className="chatmsgdatetime">
              <span className="sa-data-time">{chatItem.created_at_locale}</span>
            </div>
            <div className="chatothermsg">
            <img src={getImageUrl(chatItem.attachment_path)} width="50" height="50" />
            </div>
          </li>
        ) : (
          <li className="chatothermsg" key={index}>
            <div className="chatmsgdatetime">
              <span className="sa-data-time">{chatItem.created_at_locale}</span>
            </div>
            <div className="chatothermsg">
            <img src={getImageUrl(chatItem.attachment_path)} width="50" height="50" />
            </div>
          </li>
        )
      );
  
    } else if (chatItem.message_type === "SUGGEST") {
      return (
        <li className={isMine ? "rightside" : "chatothermsg"} key={index}>
          <div className="chatmsgdatetime">
            <span className="sa-data-time">{chatItem.created_at_locale}</span>
          </div>
          <div className="chatothermsg">
            {isMine
              ? `You Suggested a Swop to ${toUserDeatils.name}`
              : `${toUserDeatils && toUserDeatils.name} has sent you a Swop Suggestion`}
          </div>
          {chatItem.message_reply === "" && !isMine && (
            <div className="chat_boxbtn">
              <a href="#" className="btn btn-block btn-primary no-thankbtn" onClick={(e) => {
                sendMessageAction("REJECTED", 0);
                messageReply(chatItem.message_id, "0");
              }}>
                No Thanks
              </a>
              <a href="#" className="btn btn-block btn-primary" onClick={(e) => {
                sendMessageAction("ACCEPTED", 0);
                messageReply(chatItem.message_id, "1");
              }}>
                Let's Chat
              </a>
            </div>
          )}
        </li>
      );
    } else if (chatItem.message_type === "ACCEPTED") {
    
      return (
        <li className={isMine ? "rightside" : "chatothermsg"} key={index}>
          <div className="chatmsgdatetime">
            <span className="sa-data-time">{chatItem.created_at_locale}</span>
          </div>
          <div className="chatothermsg">
            {isMine ? "Let's Chat" : `${toUserDeatils && toUserDeatils.name} has accepted your suggestion - Start Chat`}
          </div>
        </li>
      );
    } else if (chatItem.message_type == "REJECTED") {
      if (isMine) {
        return (
          <div key={index}>
            <div
              className="chat-list"
              style={{ backgroundColor: "#fff", marginTop: "15px" }}
            >
              Rejected Chat
            </div>
          </div>
        );
      } else {
        return (
          <div key={index}>
            <div
              className="chat-list"
              style={{ backgroundColor: "#fff", marginTop: "15px" }}
            >
              {toUserDeatils.name} has rejected your suggestion
            </div>
          </div>
        );
      }
    } else if (chatItem.message_type == "CLOSE_REQUEST") {
      if (isMine) {
        return (
          <div key={index}>
            {/* <div className="chat-list" style={{marginTop:"15px", border:"none",color:"#939393"}}>
                              {message.created_at_locale}
                              </div> */}
            <div
              className="chat-list"
              style={{
                width: "100%",
                margin: "auto",
                padding: "0 12px",
                backgroundColor: "#fff",
                marginTop: "15px",
              }}
            >
              <div
                className="imagelisting_content"
                style={{
                  padding: "10px 10px",
                  textAlign: "center",
                  color: "#3d3d3d",
                  fontSize: "12px",
                }}
              >
                You requested to Close the Swop
              </div>
              <div
                className="fixed_imagelisting"
                style={{ padding: "10px 0px" }}
              >
                <ul
                  style={{
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-around",
                    margin: "0",
                    alignItems: "center",
                    padding: "0",
                  }}
                >
                  <li>
                    <a href="#">
                      <img
                        src={
                          left_item != undefined ? left_item.image : "#"
                        }
                        width="80"
                        height="80"
                        style={{ borderRadius: "10px", maxWidth: "100%" }}
                      />
                    </a>
                  </li>
                  <li style={{ width: "25px", margin: "0 10px" }}>
                    <img
                      src="../img/compare_icon.png"
                      style={{ maxWidth: "100%" }}
                    />
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={
                          right_item != undefined ? right_item.image : "#"
                        }
                        width="80"
                        height="80"
                        style={{ borderRadius: "10px", maxWidth: "100%" }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="chat-list"
              style={{ backgroundColor: "#fff", marginTop: "15px" }}
            >
              A confirmation request has been sent <br /> to{" "}
              {toUserDeatils.name}
            </div>
          </div>
        );
      } else {
        return (
          <div key={index}>
            {/* <div className="chat-list" style={{marginTop:"15px", border:"none",color:"#939393"}}>
                              {message.created_at_locale}
                              </div> */}
            {chatItem.message_reply == "" ? (
              <div
                className="chat-list chat-box"
                style={{ backgroundColor: "#fff", marginTop: "15px" }}
              >
                {/* <p>{this.state.toUserDeatils.name} want to complete the Swop Please Confirm?</p> */}
                <p>
                  {toUserDeatils.name} wants to close the Swop.
                  Please confirm the Swop has been completed.
                </p>
                <div className="chat_boxbtn">
                  <a
                    href="#"
                    className="btn btn-block btn-primary no-thankbtn"
                    onClick={(e) => {
                      this.sendMessage("CLOSE_REQUEST_REJECTED", 0);
                      this.messageReply(chatItem.message_id, "0");
                    }}
                  >
                    No{" "}
                  </a>
                  <a
                    href="#"
                    className="btn btn-block btn-primary"
                    onClick={(e) => {
                      this.sendMessage("COMPLETED", 0);
                      this.messageReply(chatItem.message_id, "1");
                    }}
                  >
                    Yes
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      }
    } else if (chatItem.message_type == "CLOSE_REQUEST_REJECTED") {
      //this.setState({hideCloseSwopBtn: true})

      if (isMine) {
        return (
          <div key={index}>
            <div
              className="chat-list"
              style={{ backgroundColor: "#fff", marginTop: "15px" }}
            >
              You have declined the Swop
            </div>
          </div>
        );
      } else {
        return (
          <div key={index}>
            <div
              className="chat-list"
              style={{ backgroundColor: "#fff", marginTop: "15px" }}
            >
              {this.state.toUserDeatils.name} declined the Swop
            </div>
          </div>
        );
      }
    } else if (chatItem.message_type == "COMPLETED") {
      //this.setState({hideCloseSwopBtn: true})

      return (
        <div key={index}>
          {isMine ? (
            <div>
              <div
                className="chat-list"
                style={{ backgroundColor: "#fff", marginTop: "15px" }}
              >
                {toUserDeatils.name} wants to complete the Swop
              </div>
              <div
                className="chat-list"
                style={{ backgroundColor: "#fff", marginTop: "15px" }}
              >
                You have confirmed to complete the Swop
              </div>
              <div
                className="chat-list"
                style={{
                  width: "100%",
                  margin: "auto",
                  padding: "0 12px",
                  backgroundColor: "#fff",
                  marginTop: "15px",
                }}
              >
                <div
                  className="imagelisting_content"
                  style={{
                    padding: "10px 10px",
                    textAlign: "center",
                    color: "#3d3d3d",
                    fontSize: "12px",
                  }}
                >
                  You completed the Swop
                </div>
                {/* ------------------------------------ */}
                <div
                  className="fixed_imagelisting"
                  style={{ padding: "10px 0px" }}
                >
                  <ul
                    style={{
                      listStyle: "none",
                      display: "flex",
                      justifyContent: "space-around",
                      margin: "0",
                      alignItems: "center",
                      padding: "0",
                    }}
                  >
                    <li>
                      <a href="#">
                        <img
                          src={
                            left_item != undefined ? left_item.image : "#"
                          }
                          width="80"
                          height="80"
                          style={{ borderRadius: "10px;max-width: 100%" }}
                        />
                      </a>
                    </li>
                    <li style={{ width: "25px", margin: "0 10px" }}>
                      <img
                        src="../img/compare_icon.png"
                        style={{ maxWidth: "100%" }}
                      />
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={
                            right_item != undefined
                              ? right_item.image
                              : "#"
                          }
                          width="80"
                          height="80"
                          style={{ borderRadius: "10px;max-width: 100%" }}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                className="chat-list"
                style={{ backgroundColor: "#fff", marginTop: "15px" }}
              >
                {toUserDeatils.name} has accepted to complete
                the Swop
              </div>
            </div>
          )}
         
        </div>
      );
    }
    return null; // Add this to handle other message types or invalid cases
  })}
</ul>

                    </div>
                  </div>
                 
                  {(chatStart || SwopDetail.start_chat) &&
        !SwopDetail.is_to_user_blocked &&
        !SwopDetail.from_user_deleted &&
        !SwopDetail.to_user_deleted  ?
                 ( <div className="chatbotmsgfooter">
                    <div className="attachment">
                  <input
                  type="file"
                  id="file-input"
                  ref={fileInputRef}
                  onChange={(e) => sendFile(e)}
                  accept="image/*"
                  />

                      <span className="attachfile"><img src="../img/attachment.svg" alt=""/></span>
                    </div>
                    <textarea className="form-control" id="autoHeightTextarea" name="message" placeholder="Type something here" rows="3" onKeyPress={handleKeyPress}></textarea>
                    <button type="button" className="sendbutton" onClick={sendMessageByClick}>
                      <img src="../img/sendicon.svg"  alt=""/>
                    </button>
                  </div>
                   ) :''}
                </div>
              </div>
            </div>
          </div>
        {/*------------ Report popup--------------- */}
        <div className="comman-model comman-side-popup choose-any-model" id="chooseanyonereport">
       <div className="comman-popup-inner">
       <div className="top-model-header">
          <div className="pop-top-title">
             <h2>Report</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel"><i><img src="../img/close.png" alt=""/></i></a>
       </div>
      
       <div className="popup-model-body"> 
    
                <div className="input-box form-group">
                   <label>Write Reason</label>
                  <input
                    type="text"
                    value={selected_report_reason}
                    onChange={(event) =>
                      setSelectedreportreason(event.target.value,
                      )}
                    className="input-field form-control"
                  />
                </div>
            
          
           
       </div>
       <div className="model-footer-panel"> <button className="btn btn-primary" onClick={reportSwop}>Report</button></div>
    </div>
        <div className="shadowpt" ></div>
      </div>

{/*------------ Report popup--------------- */}


<div className="comman-model comman-side-popup choose-any-model" id="chooseanyone">
      <div className="comman-popup-inner">
         <div className="top-model-header">
            <div className="pop-top-title">
               <h2>Choose Any one</h2>
            </div>
            <a href="javascript:void(0);" className="close_panel"><i><img src="../img/close.png" alt=""/></i></a>
         </div>
         <div className="popup-model-body"> 
  <ul className="choose-any-cards">
  {suggestProducts.map((item, index) => {
                      return (
  <li key={index} onClick={(e) =>
                            setsuggestModalProductId(
                              item.posted_product_id,
                            )
                          }><div className="sa-list-box" >  
  
      <div className="sa-list-box__image" >
         <img src={item.image} alt="Swop" />
         
      </div>
      <div className="sa-list-box__content">
         <div className="sa-list-box__content__title">{item.title}</div>
      </div> 
  </div></li>
                      )}
  )}
  </ul>
             
         </div>
         <div className="model-footer-panel"> <button className="btn btn-primary" onClick={(e) => suggestSwop(e)}>Suggest this swop</button></div>
      </div>
      <div className="shadowpt clearfix"></div>
   </div>
        </section>
      </main>
      </SocketProvider>
    </>
  );
};

export default ChatConversion;
